<template>
  <div class="success">
    <el-result icon="success" title="填写成功" subTitle="如需修改请点击返回">
      <template slot="extra">
        <el-button type="primary" size="medium" @click="goback">返回</el-button>
      </template>
    </el-result>
  </div>
</template>

<script>
export default {
methods:{
    goback(){
        this.$router.go(-1)
    }
}
}
</script>

<style scoped>
.success{
height: 100%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}

</style>