import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import draggable from 'vuedraggable'
import axios from 'axios'
import hasRole from './directive/role'
import Vue2TouchEvents from 'vue2-touch-events'
import pptxgen from 'pptxgenjs';
import * as echarts from 'echarts';
import "./assets/albb/iconfont.css"

// import COS from 'cos-js-sdk-v5' // 引入 cos-js-sdk-v5 包
Vue.directive('hasrole', hasRole)

Vue.use(Vue2TouchEvents);
Vue.component('draggable', draggable)
ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(ElementUI)
// axios.defaults.baseURL="http:///192.168.1.85:3000/api/"
axios.interceptors.request.use(req=>{
  req.headers.Authorization=window.sessionStorage.getItem('token')
  return req
})
axios.defaults.baseURL='https://seats.kunbocs.cn/api/'
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.prototype.$pptxgen = pptxgen;
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
