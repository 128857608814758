<template>
  <div class="qrcodejs">
    <div class="goback">
      <goback></goback>
    </div>
    <div class="lr">
      <el-select v-model="currentmeeting"   placeholder="选择会议" @change="currentmeetingselect"  style="width:420px;">
        <el-option
          v-for="item in options"
          :key="item.meeting"
          :label="item.meeting"
          :value="item.meeting">
        </el-option>
      </el-select>
      <el-autocomplete
      style="width:420px;margin-top: 15px;"
        v-model="state"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入内容"
        @select="handleSelect"
      >
      <template slot="prepend">当前厅</template>
    </el-autocomplete>
      <el-button :disabled="state==''" type="success" @click="erwe"  style="width:420px;margin-top: 15px;">
        生成二维码
      </el-button>
    </div>
    <div class="lr">
      <div ref="qrcode">
    </div>
    <el-button :disabled="erweima==''" type="primary" @click="dowmlowqrcode">点击下载</el-button>
  </div>
    
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import goback from './goback.vue'
export default {
    data(){
        return{
          // pathurl:'https://seats.kunbocs.cn/#/checkseat',//线上环境使用
          pathurl:'http://192.168.1.85:8080/#/checkseat',//开发环境使用
          erweima:'',
          meeting:'',
          options: [],
      currentmeeting:'',
      restaurants:[],
      state:''
        }
    },
    watch:{
      
    },
    components:{
      goback
    },
    created(){
          let accname = this.$route.query.meetingname ? this.$route.query.meetingname:''
          let meeting = this.$route.query.meeting ? this.$route.query.meeting:''
          this.currentmeeting = meeting
          this.state = accname
          // console.log(this.currentmeeting,this.meetingname)
    },
    mounted() {
      this.getcurrentmeetingname()
      this.loadAll(this.currentmeeting)
  },
  methods:{
    async erwe(){
      // ?meeting=测试测试&meetingname=新一轮会议测试厅
      this.erweima=''
      this.$refs.qrcode.innerHTML=''
      let pathtext = this.pathurl + '?meeting='+this.currentmeeting+'&meetingname='+this.state
      const confirm = await this.$confirm('创建《'+this.currentmeeting+'》会议，《'+this.state+'》厅座位二维码？',{
        title: '创建座位二维码',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).catch(e=>{

      })
      if(confirm=='confirm'){
        this.erweima= new QRCode(this.$refs.qrcode, {
      text: pathtext,
      width: 400,
      height: 400,
      colorDark : '#000000',
      colorLight : '#ffffff',
      correctLevel : QRCode.CorrectLevel.H
    });
      }
   
    },
    dowmlowqrcode(){
      	//获取二维码中格式为imag的元素
        const nodeList = Array.prototype.slice.call(this.erweima._el.children)
        const img = nodeList.find((item) => item.nodeName.toUpperCase() === 'IMG')	// 选出图片类型
        // 构建画布
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);
        // 构造url
        let url = canvas.toDataURL('image/png');
        const a = document.createElement("a");
        a.href = url;
        a.download =this.state+ `二维码.png`;
        // 触发a链接点击事件，浏览器开始下载文件
        a.click();
    },
       // 查询当前的会议名称
       async  getcurrentmeetingname(){
        const {data:res} = await this.$http.get('users/tocheckmeet')
        if(res.flag==true){
          let map = new Map();
            for (let obj of res.data) {
              map.set(obj.meeting, obj); //以meeting的值为键，以对象为值
            }
            this.options = Array.from(map.values())
        }
        // console.log(res,'www')
        // this.options = res.data
        },
      // 下拉选择会议
      currentmeetingselect(value){
          this.restaurants=[]
          this.state=''
          // this.allgroup=0
          this.loadAll(value)
        },
    //https://blog.csdn.net/m0_46440313/article/details/131347660
    async loadAll(value) {
      const {data:res} = await this.$http.get('users/getmeetingname',{
        params:{
          "name":value
        }
      })
      if(res.flag==true){
        for(let i=0;i<res.data.length;i++){
          let obj={"value":''}
          obj.value=res.data[i].meetingname
          this.restaurants.push(obj)
        }
      }
      },
      querySearchAsync(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
     async handleSelect(item) {
       
      },
  }
}
</script>

<style scoped>
.qrcodejs{
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lr{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}
.lr:nth-child(1){
  border-right: 1px solid #f3f4f6;
}
.goback{
  position: absolute;
  z-index: 999;
  top: 15px;
  left: 30px;
}
</style>