<template>
    <div class="image">
        <el-image :src="src"></el-image>
        {{  }}
    </div>
</template>

<script>
export default {
    data(){
        return{
            src:'',
            id:'',
            meetingid:''
        }
    },
    created(){
        this.id=this.$route.query.id
        this.meetingid=this.$route.query.meetingid
    },
    mounted(){
      this.getimg()
        // console.log(this.$route.query.id)
    },
     methods:{
        async  getimg(){
            
            const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getpic',{
                params:{
                    id:this.id,
                    meetingid:this.meetingid
                }
            })
            // console.log(res)
            if(res.flag==true){
                let splvalue =res.data[0].img.split('?') 
                // console.log(splvalue)
                this.src = splvalue[0]
            }
        }
    }
}
</script>

<style scoped>
.image{
    height: 100%;
    width: 100%;
}
</style>