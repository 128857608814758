
<template>
    <div class="enca">
      <div class="chart1 charts"  ref="pieChart"  id="chart1"></div>
      <div class="chart2 charts" ref="bingChart" id="chart2">
      </div>
    </div>
</template>

<script>
import store from '@/store';
export default {
    data(){
        return{
         num1:100,
         num2:200,
        }
    },
    mounted(){
    },
  methods: {
    async getnum(){
      let value = this.$store.state.meetingid
      const {data:res} = await this.$http.get('users/echartsnum',{
      // const {data:res} = await this.$http.get('http://192.168.1.87:3000/api/users/echartsnum',{
        params:{
          id:value
        }
      })
      if(res.flag==true){
        this.num1 = res.data[0].c2
        this.num2 = res.data[0].c1
        this.echarts1()
      this.echarts2()
      
      }
    },
   echarts1(){
    let chart = {
            xAxis: {
              type: 'category',
              data: ['已收集', '未收集']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: [
                  {
                    value:this.num1,
                    itemStyle:{color:'#93c23a'}
                  },
                  {
                    value:this.num2,
                    itemStyle:{color:'red'}
                  },
                ],
                type: 'bar'
              }
            ]
          };
    let chartDom = this.$refs.pieChart;
    let myChart =this.$echarts.init(chartDom);
    myChart.setOption(chart);
   },
   echarts2(){
      let bing = {
  title: {
    text: '简介收集占比',
    // subtext: 'Fake Data',
    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      label: {
        show: true, // 显示标签
        formatter: '{b}: {c}个 ({d}%)', // 标签内容格式化器，{b}是数据项名称，{c}是数值，{d}是百分比
        position: 'outside' // 标签位置
      },
      name: '简介收集情况',
      type: 'pie',
      radius: '50%',
      data: [
                   
        { value: this.num1, name: '已收集', itemStyle:{color:'#93c23a'} },
        { value: this.num2, name: '未收集', itemStyle:{color:'red'} },
       
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
    let chartDom = this.$refs.bingChart;
      let myChart =this.$echarts.init(chartDom);
      myChart.setOption(bing);
   }
  },
};
</script>
<style scoped>
.enca{
  display: flex;
  height: 100%;
  width: 100%;
}
.charts{
  flex:1;
  height:100%
}
</style>
