<template>
  <div class="pptx">
    <el-drawer
      title="有更新的串场"
      :visible.sync="drawer"
      size="50%"
      :before-close="handleClose"
      direction="ltr"
      >
      <div class="update">
        <el-table
      :data="updatelist"
      height="100%"
      stripe
       :header-cell-style="{ textAlign: 'center'}"
      :cell-style="{ textAlign: 'center' }"
      style="width: 100%">
      <el-table-column
      sortable  
        prop="modification2"
        label="更新时间"
        width="160">
        <template slot-scope="scope">
          {{ scope.row.modifytime | filtime }}
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="80">
      </el-table-column>
      <el-table-column
        prop="meeting"
        label="会议">
      </el-table-column>
      <el-table-column
        prop="dw"
        width="160"
        label="单位">
      </el-table-column>
      <el-table-column
      prop="Readingstatus"
        sortable
        label="状态">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.Readingstatus==0">未读</el-tag>
          <el-tag type="success" v-else>已读</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作">
        <template slot-scope="scope">
          <!-- <el-button type="danger" @click="check(scope.row.id)" size="small">已读</el-button> -->
          <el-button type="primary" @click="check({userid:scope.row.userid,listid:scope.row.listid})" size="small">查看最新简历</el-button>
        </template>
      </el-table-column>
    </el-table>
        <!-- {{  }} -->
        <el-drawer
          title="详细信息"
          direction="ltr"
          :append-to-body="true"
          size="30%"
          :visible.sync="innerDrawer">
          <div class="infomation">
            <div class="linkting">更新关联会场：<label v-for="item in lingmeeting" :key="item.ting">{{ item.ting }}</label></div>
            <el-input v-model="name">
              <template slot="prepend">姓名</template>
            </el-input>
            <el-input v-model="dw">
              <template slot="prepend">单位</template>
            </el-input>
            <el-input
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 10}"
              placeholder="请输入内容"
              v-model="textarea2">
            </el-input>
            <el-image 
            :src="src"
            
            ></el-image>

          </div>
        </el-drawer>
      </div>
    </el-drawer>
  <el-container class="container1">
    <el-header>
      <div>会议:{{ $store.state.meeting }}</div>
        <div>
        <el-badge :value="$store.state.num" class="item">
          <el-button size="small"  type="primary" icon="el-icon-message-solid" @click="updatevalue">更新</el-button>
        </el-badge>
        &nbsp;
          <el-button @click="goback" type="warning" icon=" el-icon-s-home" plain circle size="small"></el-button>
        </div>
    </el-header>
  <el-container class="container2">
    <el-aside width="150px">
        <el-row class="tac">
  <el-col :span="24">
    <el-menu
      :default-active="activepath"
      class="el-menu-vertical-demo"
      router
      >
      <el-menu-item @click="savepath('/link')" :index="`/link`" >
        <i class="el-icon-document"></i>
        <span slot="title">人员管理</span>
      </el-menu-item>
      <el-menu-item @click="savepath('/checkppt')" index="/checkppt">
        <i class="el-icon-menu"></i>
        <span slot="title">串场管理</span>
      </el-menu-item>
      <el-menu-item @click="savepath('/screen')" index="/screen">
        <i class="iconfont icon-sousuoshaicha"></i>&nbsp;
        <span slot="title">筛选</span>
      </el-menu-item>
       <el-menu-item @click="savepath('/meetingperson')" index="/meetingperson" v-show="false">
        <i class="iconfont icon-xinzengyonghu"></i>
        <span slot="title">新加会议人员</span>
      </el-menu-item>
      <el-menu-item @click="savepath('/addppt')" index="/addppt" v-show="false">
        <i class="iconfont icon-xinzengyonghu"></i>
        <span slot="title">串场列表</span>
      </el-menu-item>
      <el-menu-item @click="savepath('/read')" :index="`/read`" v-show="false">
        <i class="el-icon-document"></i>
        <span slot="title">读取</span>
      </el-menu-item>
    </el-menu>
  </el-col>
  </el-row>
    </el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </el-container>
  </el-container>
  </div>
</template>

<script>
import store from '@/store';
export default {
    data(){
        return{
            personid:'',
            pptxvalue:[],
            pptvalue:[],
            id:'778899',
            updatenumber:0,
            drawer: false,
            innerDrawer:false,
            updatelist:[],
            name:'',
            dw:'',
            textarea2:'',
            src: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            lingmeeting:[],
            fullscreenLoading:false,
            activepath:'/link'
        }
    },
    mounted(){
      // this.getupdateinfo()
      store.dispatch('getnum',this.$store.state.meetingid)
    },
    created(){
      this.activepath = window.sessionStorage.getItem('activepath')
    },
    filters:{
      filtime(value){
        let date = new Date(value);
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
        let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
        let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
        return Y+M+D+h+m
      }
    },
    methods:{ 
      savepath(value){
        window.sessionStorage.setItem('activepath',value)
      },
     async updatevalue(){
        const {data:res} = await this.$http.get('users/getupadtevalue',{
          params:{
            meeting:this.$store.state.meetingid
          }
        })
        // console.log(res)
        if(res.flag==true){
          this.updatelist=res.data
        }
        this.drawer = true
      },
     async check(value){
        this.personid = value.userid
        this.fullscreenLoading = this.$loading({
          lock: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        this.lingmeeting=[]
        const {data:res} = await this.$http.get('users/getinfo',{
            params:{
              id:value.userid
            }
          })
          // console.log(res.data)
          if(res.flag==true){
        this.innerDrawer = true
        this.fullscreenLoading.close()
          // this.dialogVisible = true
            this.name = res.data[0].name
            this.dw = res.data[0].dw
            this.textarea2 = res.data[0].text
            this.src=res.data[0].img
            this.getting(value.userid)
            this.updatereadStatue(value.listid)
          }else{

          }
      },
     async getting(value){
      const {data:res} = await this.$http.get('users/getting',{
            params:{
              id:value
            }
          })
          if(res.flag==true){
          this.lingmeeting = res.data
          }else{
          this.lingmeeting.push({ting:'暂无'})
          }
      // getting
      },
    async  updatereadStatue(value){
              const {data:res} = await this.$http.put('users/updatereadStatue',{
                id:value
              })
              if(res.flag == true){
                // this.getupdateinfo()
      store.dispatch('getnum',this.$store.state.meetingid)
                this.updatevalue()
              }
      },
     async getupdateinfo(){
        const {data:res} = await this.$http.get('users/Checkforupdates',{
          params:{
            meeting:this.$store.state.meeting
          }
        })
        // console.log(res)
        if(res.flag==true){
          this.updatenumber = res.data[0].length
        }
      },
      goback(){
        window.sessionStorage.setItem('activepath','/link')
        this.$router.push('/PPThomepage')
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
            // this.src=[]
          })
          .catch(_ => {});
      }
    },
    components:{
    
    }
}
</script>

<style scoped>
.el-container{
    height:100% ;
}
.container2{
  height:calc(100% - 60px);

}
.el-header{
   box-sizing: border-box;
  display: flex;
  justify-content: space-between;
align-items: center;
padding: 0px 60px;
    /* border: 1px solid red; */
    border-bottom: 1px solid #ccc;
}
.el-aside{
 border-right: 1px solid #ccc;
}
.el-main{
  position: relative;
  padding: 0 0px !important;
}
.goback{
  right: 0;
  top: 0;
  position: absolute;
  z-index: 999;
}
.pptx{
    height: 100%;
}
.el-submenu .el-menu-item{
  min-width: 0 !important;
}
.el-menu{
border-right: 0;
}
.infomation{
  box-sizing: border-box;
  padding:0 20px;
}
.infomation .el-input{
margin-bottom: 10px;
}
.el-image{
  margin-top: 10px;
}
.linkting{
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  font-family: '微软雅黑';
}
.linkting label{
  margin-right: 5px;
}
.update{
  height: 100%;
}
</style>