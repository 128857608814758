<template>
  <div class="phpTemplate">
    <el-form ref="form" :model="form" label-width="90px" >
    <el-form-item label="模版选择：">
    <el-radio v-model="form.radiovalue" label='固定模版'>固定模版</el-radio>
    <!-- <el-radio v-model="form.radiovalue" label="自选模版" @change="getFile">自选模版</el-radio> -->
  </el-form-item>
  <el-form-item label="字号选择：" v-show="form.radiovalue=='固定模版'">
    <el-select v-model="form.name" multiple >
    <el-option
      v-for="item in numbers"
      :key="item"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
  <label>字号分别对应:姓名、单位、介绍</label>
  </el-form-item>
  <el-form-item label="模版选择："  v-show="form.radiovalue=='自选模版'">
    <el-select v-model="form.filename" >
    <el-option
      v-for="item in filevalue"
      :key="item"
      :label="item"
      :value="item">
    </el-option>
  </el-select>
  &nbsp;
  &nbsp;
  <el-upload
	 action="https://resume.kunbocs.cn/saveFile.php"
	 :on-success="handleSuccess"
	 :before-upload="beforeUploadAndSubmit">
	 <el-button slot="trigger" size="small" type="primary">上传模版</el-button>
	 </el-upload>
  </el-form-item>
  <el-form-item label="颜色选择：" v-show="form.radiovalue=='固定模版'">
    <el-color-picker v-model="form.color" size="small"></el-color-picker>
  </el-form-item>
  <el-form-item label="操作："  v-show="form.radiovalue=='固定模版'">
    <el-button size="small" type="success" @click="Export">导出</el-button>
    <el-button size="small" type="danger" @click="Texport">测试导出</el-button>
  </el-form-item>
  <el-form-item label="操作："  v-show="form.radiovalue=='自选模版'">
    <el-button size="small" type="success" @click="Customize">导出</el-button>
    <el-button size="small" type="danger" @click="Tcustomize">测试导出</el-button>
  </el-form-item>
  </el-form>
  </div>
</template>
<script>
import FileSaver from 'file-saver';

export default {
    props:['ting'],
    data(){
        return{
            numbers: Array.from({ length: 21 }, (v, k) => k + 12), // 生成一个从28到38的数组
            form:{
                radiovalue:'固定模版',
                name:[],
                color:'#409EFF',
                filename:''
            },
           filevalue:[]
        }
    },
    methods:{
        Tcustomize(){

        },
       async Export(){
        if(this.form.name.length ==3){
            const response = await this.$http.post('https://resume.kunbocs.cn/export.php',{
                id:this.$store.state.meetingid,
                ting:this.ting,
                color:this.form.color,
                size:this.form.name
            } , { responseType: 'blob' });
            // , { responseType: 'blob' }
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
            FileSaver.saveAs(blob, this.ting+'.pptx');
            console.log(response)
            if(response){
                console.log(response)
            }
        }else{
            this.$message.error('请选择三个字体大小')
        }
        },
        Texport(){
        },
        async getFile(){
            const {data:res} = await this.$http.get('https://resume.kunbocs.cn/getFile.php');
                this.filevalue = res
        },
        async Customize(){
            if(this.form.filename !=''){
            const response = await this.$http.post('https://resume.kunbocs.cn/pptTemplate.php',{
                id:this.$store.state.meetingid,
                ting:this.ting,
                filename:this.form.filename
            }, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    FileSaver.saveAs(blob, this.ting+'.pptx');
            // console.log(response)
            if(response){
                console.log(response)
            }
        }else{
            this.$message.error('请选择模版')
        }
        },
        handleSuccess(response, file, fileList) {
							      console.log(response);
                                  this.getFile()
							    },
							    beforeUploadAndSubmit(file) {
									// console.log(file.name)
									this.form.filename = file.name
							      const isPPT = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
							      if (!isPPT) {
							        this.$message.error('上传文件只能是 PPT 格式!');
									// this.$refs.upload.submit();
							      }
							      return isPPT;
							    },
    }
}
</script>

<style>
.phpTemplate{
    height: 100%;
    width: 100%;
}
.phpTemplate .el-form-item{
    margin-bottom: 10px;
}
.phpTemplate .el-form-item__content {
    display: flex;
    align-items: center;
    height: 40px;
}
</style>