<template>
    <div class="meetingperson">
      <el-drawer
        :title="$store.state.meeting"
        :visible.sync="drawer"
        size="35%"
        direction="ltr"
       >
        <div style="height: 100%;">
          <el-table
          :data="ralatemeeting"
          stripe
          height="100%"
          :header-cell-style="{ textAlign: 'center'}"
          :cell-style="{ textAlign: 'center' }"
          style="width: 100%">
          <el-table-column
            prop="meeting"
            label="关联会议">
          </el-table-column>
           <el-table-column
            prop="name"
            label="姓名"
            width="80">
          </el-table-column>
          <el-table-column
          sortable  
            prop="modification2"
            label="更新时间"
            width="160">
            <template slot-scope="scope">
              {{ scope.row.modification2 | filtime }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="warning" @click="synchronous(scope.row.id)" size="small">同步简历</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>
      </el-drawer>
      <el-dialog
        title="新增人员"
        :visible.sync="addDialog"
        width="30%"
        @opened="toaddopened"
        >
        <div class="adddiv">
         <addperson ref="addperson" @todialog=getperson></addperson>
        </div>
      </el-dialog>
      <el-dialog
        title="修改简介"
        :visible.sync="dialogVisible"
        width="35%">
        <div class="bigdialog">
        <!-- <div class="left">
        <read :name="name" ref="readref"></read>
        </div> -->
        <div class="rig">
        <div class="topupdatediv">
          <el-input v-model="id" disabled>
            <template slot="prepend">会议ID</template>
          </el-input>
           <el-input v-model="globalid" disabled>
            <template slot="prepend">全局ID</template>
          </el-input>
          </div>
        <div class="updatediv">
          <el-input v-model="name">
            <template slot="prepend">姓名</template>
          </el-input>
          <el-input v-model="dw" >
            <template slot="prepend">单位</template>
          </el-input>
          <el-input v-model="myphone" >
            <template slot="prepend">号码</template>
          </el-input>
          <textarea class="el-textarea__inner" v-model.lazy="textarea" rows="10" placeholder="请输入您的介绍"></textarea> 
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="upload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" ></i>
          </el-upload>
        </div>
      </div>
    </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="checkImage" type="success" :disabled="imageUrl==''">查看原图</el-button>
          
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="tosubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="查看最新简历"
        :visible.sync="dialogVisible2"
        width="40%"
        :before-close="handleClose"
        >
        <div class="updatediv">
          <div class="linkting">更新关联会场：<label v-for="item in lingmeeting" :key="item.ting">{{ item.ting }}</label></div>
          <el-input v-model="updatename">
            <template slot="prepend">姓名</template>
          </el-input>
          <el-input v-model="updatedw" >
            <template slot="prepend">单位</template>
          </el-input>
          <textarea class="el-textarea__inner" v-model.lazy="updatetext" rows="10" placeholder="code"></textarea> 
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="upload"
          >
            <img v-if="updateimg" :src="updateimg" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" ></i>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closedialog2">确定</el-button>
        </span>
      </el-dialog>
      <div class='toopdiv' v-show="isshow">{{ link }}</div>
      <div class="flex2">
        <div class="search">
          <div class="collecttype">
          简介
          <label>已收集：<strong>{{ Collected }}</strong>&nbsp;个</label> 
          &nbsp;
          <label>未收集：<strong>{{ Notcollected }}</strong>&nbsp;个</label> 
         </div>
         &nbsp;
         <div class="btndongtu"  @click="btndongtu">
          <el-avatar size="small" :src="circleUrl"></el-avatar>
        </div>
          &nbsp;
          <el-input v-model="searchvalue" style="width: 600px;">
            <template slot="prepend">请输入姓名或电话号码</template>
          </el-input>
          &nbsp;
          &nbsp;
          <el-button :disabled="empty" type="success" @click="exportlink">批量导出链接</el-button>
          &nbsp;
          <el-button :disabled="empty" type="success" @click="exportworld">导出头像</el-button>
          &nbsp;
          <el-button type="primary" @click="addperson" v-show="matevalue.length >0">新增</el-button>
        </div>
        <div class="result" ref="resultref" >
          <transition name="fade">
          <div class="dongtu" v-show="dongtu">
            <encapsulation ref="encap"></encapsulation>
            <!-- <el-button @click="closedongtu">关闭</el-button> -->
          </div>
          </transition>
              <el-table
              :data="matevalue"
               style="width: 100%"
               height="100%"
             
               :header-cell-style="{ textAlign: 'center' }"
               :cell-style="{ textAlign: 'center' }"
               v-loading="loading"
              element-loading-text="加载中..."
              v-if="displaydata"
               >
             <el-table-column type="index" label="序号"  width="60">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="160">
            </el-table-column>
            <el-table-column prop="phone" label="电话" width="160">
            </el-table-column>
            <el-table-column prop="dw" label="单位" width="280">
            </el-table-column>
            <el-table-column label="简介收集状态" width="160" prop="text" sortable="">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.text !=null " type="success">已收集</el-tag>
                <el-tag v-else type="danger">未收集</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="往期会议" width="150">
              <template slot-scope="scope">
                <el-button size="small" type="success"  @click="relatedmeetings({globalid:scope.row.globalid,id:scope.row.id})">历史{{ scope.row.num }}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="更新状态" >
              <template slot-scope="scope">
                <div  style="cursor: pointer;"  @click="checkupdate(scope.row.id)">
                   <el-badge :is-dot="showupdate({t1:scope.row.t1,t2:scope.row.t2})" class="item" >
                  {{scope.row.t1 | upstatus(scope.row.t2)}}
                </el-badge>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="280"  fixed="right">
              <template slot-scope="scope">
                <el-button  type="primary" @click="getlink(scope.row.id)" size="small">链接</el-button>
                <el-button  type="warning" @click="update(scope.row.id)" size="small">修改</el-button>
                <el-button  type="danger" @click="del({id:scope.row.id,name:scope.row.name})" size="small">删除</el-button>
              </template>
            </el-table-column>
            </el-table>
            <div v-else class="piliang">
              暂无数据，去&nbsp;<el-button type="primary" size="small" @click="Batchimport">批量导入</el-button>
            </div>
        </div>
      </div>
    </div>
  </template>
  <script>
    import excel from '@/libs/excel'
    import {changetoarr4} from '../../libs/utlis'
    import {debounce} from '../../libs/utlis'
    import {jsfilter} from '../../libs/utlis'
    import COS from 'cos-js-sdk-v5' // 引入 cos-js-sdk-v5 包
    import store from '@/store'
    import addperson from './addperson.vue'
    import pic from "../../../public/pic/ts.png"
    import encapsulation from './encapsulation.vue'
    import read from './read.vue'
    import FileSaver from 'file-saver';

  export default {
      data(){
          return{
            id:'',
              name:'',
              dw:'',
              textarea:'',
              imageUrl:'',
              list:"",
              matevalue:[],
              value:'',
              option:'',
              link:'https://seats.kunbocs.cn/#/inppt?id=',//线上环境使用
              // link:'http://192.168.1.135:8080/#/inppt?id=',
              isshow:false,
              searchvalue:'',
              dialogVisible:false,
              meetingid:'',
              empty:true,
              drawer:false,
              ralatemeeting:[],
              loading: false,
              loading2: false,
              Collected:0,
              Notcollected:0,
              cos: null, // 用于存储 COS 实例
              dialogVisible2:false,
              modification2:'',
              updatename:'',
              updatedw:'',
              updatetext:'',
              updateimg:'',
              fullscreenLoading:false,
              lingmeeting:[],
              addDialog:false,
              personid:'',
              circleUrl:pic,
              dongtu:false,
              displaydata:true,
              myphone:'',
              globalid:'',
              exportlist:[]
          }
      },
      filters:{
        filtime(value){
          // console.log(value)
          if(value==null){
          return '暂无'
          }else{
          let date = new Date(value);
          let Y = date.getFullYear() + '-';
          let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
          let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
          let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
          let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
          return Y+M+D+h+m
          }
        },
        upstatus(value1,value2){
          if(Number(value2) > value1){
            let date = new Date(value2);
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' ';
            let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
            let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
            // console.log(Number(value2))
            return Y+M+D+h+m+`更新`
          }else{
            return '暂无更新'
          }
        }
      },
      components:{
        addperson,encapsulation,read
      },
      mounted(){
        console.log(window.innerWidth)
        this.value = this.$store.state.meetingid
        let getvalue = this.$store.state.meetingid
        
        this.getoption(getvalue)
      },
      watch:{

        myphone(newvalue,oldvalue){
          if(oldvalue=='')return
          let zhengze = /^1[3-9]\d{9}$/
          if(zhengze.test(newvalue)){
            this.useglobalid(newvalue)
          }
        },
        searchvalue:debounce(function(newvalue,oldvalue){
          this.mate()
        },700),
        dongtu(newvalue,old){
            if(newvalue){
              this.$refs.encap.getnum()
            }
        }
      },
      methods:{
        async useglobalid(value){
        const {data:res} = await this.$http.get('/users/checkglobalid', {
                    params: { phone: value }
                    });
                    if(res.flag==true){
                    // console.log(res)
                     this.globalid = res.data[0].globalid
                    }else{
                     this.makeglobalid(4).then(globalid=>{
                     this.globalid =  globalid
                     })
                    }
            // console.log(value)
        },
        async makeglobalid(length) {
            // let timestamp = Date.now().toString();
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }

            let exists = false;
            do {
                // 检查ID是否存在
                const {data:res} = await this.$http.get('/users/createglobalid', {
                params: { id: result }
                });
                exists = res.flag; // 假设返回的数据中的flag字段表示ID是否存在
                if (exists) {
                // 如果存在，重新生成ID
                result = '';
                counter = 0;
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    counter += 1;
                }
                }
            } while (exists); // 只要存在，就继续生成新的ID
            return  result
            },
        checkImage(){
          const url = 'https://seats.kunbocs.cn/#/Originalimage';
          // const url = 'http://192.168.1.87:8080/#/Originalimage';
          const params = `?id=${this.id}&meetingid=${this.$store.state.meetingid}`; // 假设param1和param2是你要传递的参数
          window.open(url + params, '_blank');
        },
        // 批量导入
        Batchimport(){
          this.$router.push('/meetingperson')
        },
        btndongtu(){
          // this.dongtu=true
          this.dongtu=!this.dongtu 
        },
        getperson(value){
          this.addDialog=value
          this.getoption(this.value)
        },
        toaddopened(){
          this.$refs.addperson.createid(6)
        },
        addperson(){
          this.addDialog=true
      
        },
       async readrecord(){
        const {data:res} = await this.$http.put('users/readrecord',{
            id:this.id
          })
          if(res.flag==true){
            store.dispatch('getnum',this.$store.state.meetingid)
          }
        },
        closedialog2(){
          this.readrecord()
          this.dialogVisible2 = false
        },
      async  checkupdate(value){
          this.lingmeeting=[]
          this.id = value
          const {data:res} = await this.$http.get('users/getinfo',{
            params:{
              id:value
            }
          })
          if(res.flag==true){
          this.dialogVisible2 = true
            this.updatename = res.data[0].name
            this.updatedw = res.data[0].dw
            this.updatetext = res.data[0].text
            this.updateimg = res.data[0].img
            this.modification2 = res.data[0].modification2 ==null ? res.data[0].modification1:res.data[0].modification2
            this.getting(value)
            this.synchronize()
          }
       
        },
        async getting(value){
          // console.log(value)
      const {data:res} = await this.$http.get('users/getting',{
            params:{
              id:value
            }
          })
          if(res.flag==true){
          this.lingmeeting = res.data
          }else{
          this.lingmeeting.push({ting:'暂无'})
          }
      // getting
      },
        showupdate(value){
          if(Number(value.t2) > value.t1){
            return true
          }else{
            return false
          }
        },
       async synchronize(){
          const {data:res} = await this.$http.put('users/synchronize',{
            t2:this.modification2,
            id:this.id
          })
          if(res.flag == true){
            this.getoption(this.value)
          }
        },
        handleClose(done){
          this.readrecord()
          done();
     
        },
        async  upload(file){
        let houzui=file.file.type.split('/')
        // const {data:res} = await this.$http.get('http://192.168.1.123:3000/getTempKeys')
        const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getTempKeys')
        // console.log(res)
          this.cos = new COS({
            getAuthorization: (options, callback) => {
              callback({
                TmpSecretId: res.credentials.tmpSecretId,
                TmpSecretKey: res.credentials.tmpSecretKey,
                XCosSecurityToken: res.credentials.sessionToken,
                ExpiredTime: res.expiredTime,
              });
            },
          }); 
          let timeout = Date.now()
          let key = "/speakerImage/" +this.$store.state.meeting+'/'+ this.name+timeout+'.'+houzui[1];
          // let key = "/art/" + file.file.name;
          // console.log(key,'key')
          this.cos.putObject({
            Bucket: "file-1257652773", // 存储桶名称
              Region: "ap-guangzhou", // 存储桶所在地域，必须字段
              Key: key, // 图片名称
              StorageClass: "STANDARD",
              Body: file.file, // 上传文件对象
              onHashProgress: (progressData) => {
                console.log("校验中", JSON.stringify(progressData));
              },
              onProgress: (progressData) => {
                const percent = parseInt(progressData.percent * 10000) / 100;
                const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
              },
          },(err,data)=>{
            // console.log(data,'=-=')
            if (data && data.statusCode == 200) {
                // 如果上传成功，拼接图片链接地址，并赋值给 imageUrl
                let fileUrl ='https://'+data.Location+'?imageMogr2/scrop/420x540'
                this.imageUrl = fileUrl;
                // console.log(fileUrl);
              } else {
                console.log(err,'1');
                // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户上传失败
              }
          }).catch((err) => {
          console.log(err,'2');
          // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户获取密钥失败
        });
       
        },
       async synchronous(value){
        this.id=value
          const {data:res} = await this.$http.get('users/getinfo',{
          // const {data:res} = await this.$http.get('http://192.168.1.85:3000/getinfo',{
            params:{
              id:value
            }
          })
          // console.log(res.data)
          if(res.flag==true){
          this.dialogVisible = true
          this.name = res.data[0].name
            this.dw = res.data[0].dw
            this.myphone = res.data[0].phone
            this.textarea = res.data[0].text
            this.imageUrl = res.data[0].img
            this.globalid = res.data[0].globalid
          }
        },
       async relatedmeetings(value){
          this.drawer = true
          this.id = value.id
          const {data:res} = await this.$http.get('users/relatedmeetings',{
            params:{
              globalid:value.globalid,
              meetingid:this.$store.state.meetingid
            }
          })
          if(res.flag==true){
            this.ralatemeeting = res.data
          }
        // console.log(res)
      },
        goback(){
        this.$router.push('/PPThomepage')
      },
       async del(value){
        const confirm = await this.$confirm('是否删除->'+value.name,{
          title:'提示',
          type:'warning',
          confirmButtonText:'确定',
          cancelButtonClass:'取消'
        }).catch(e=>{
            // this.$message.error('')
        })
        if(confirm == 'confirm'){
          const {data:res} = await this.$http.delete('users/deluser',{
          params:{
            'id':value.id
          }
        })
        if(res.flag==true){
        this.$message.success(res.msg)
        this.getoption(this.meetingid)
        }else{
        this.$message.error(res.msg)

        }
        }
        },
        async  update(value){
          this.id = value
          const {data:res} = await this.$http.get('users/getinfo',{
            params:{
              id:value
            }
          })
          if(res.flag==true){
          this.dialogVisible = true
            this.name = res.data[0].name
            this.dw = res.data[0].dw
            this.myphone = res.data[0].phone
            this.textarea = res.data[0].text
            this.imageUrl = res.data[0].img
            this.globalid = res.data[0].globalid
            
          }
        },
      async mate(){
        this.matevalue= jsfilter(this.searchvalue,this.list)
        },
       async getlink(value){
          this.link = 'https://resume.kunbocs.cn/fillinput.html?id='+value
          // this.link = 'http://192.168.1.87:8080/#/inppt?id='+value
              await navigator.clipboard.writeText(this.link).then(() => {
                this.$message.success('专属链接复制成功')
            }).catch(e=>{
              console.log(e)
              this.$message.error('专属链接复制失败')
            })
        },
      async  getmeeting(){
            const {data:res} = await this.$http.get('users/getmeeting')
           if(res.flag==true){
            let arr = res.data
            let map = new Map()
            arr.forEach(item => {
                map.set(item.meeting,item)
            });
          this.option= [...map.values()]
           }
        },
      async  getoption(value){
        this.meetingid = value
        this.loading = true; // 开始加载
            const {data:res} = await this.$http.get('users/getlinkname',{
                params:{
                    "meetingid":value
                }
            })
            // console.log(res.data)
            if(res.flag==true){
                this.empty = false
                this.list = res.data
                this.mate()
                this.filterres(res.data)
                this.loading = false; // 开始加载
            }else{
              this.displaydata=false
              this.loading = false
            }
        },
        // changetoarr(value){
        //   let valen = value.length,status='';this.exportlist=[]
        //   for(let i=0;i<valen;i++){
        //     let link='https://seats.kunbocs.cn/#/inppt?id='
        //     let obj={id:'', name: "Apple",status:''}
        //     status = value[i].text == null ? '未收集':'已收集'
        //   obj.id = link + value[i].id
        //   obj.name = value[i].name
        //   obj.status = status
        //   console.log(obj)
        //   this.exportlist.push(obj)
        //   }
        //   console.log(this.exportlist)
        // },
      async  exportlink(){
            const confirm = await this.$confirm('是否批量导出链接？',{
              title:"提示",
              type:'warning',
              confirmButtonText:'确定',
              cancelButtonText:'取消'
            }).catch(err=>{

            })
            if(confirm =='confirm'){
            let arr =await changetoarr4(this.list)
              let params = {
                title: ["链接", "姓名",'状态'],
                key: ["id", "name","status"],
                data: arr, // 数据源
                autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
                filename:this.value.toString(),
              };
             await excel.exportArrayToExcel(params);
            }
         
        },
      async exportworld(){
        try {
						        const response = await this.$http.get('https://resume.kunbocs.cn/exportWorld.php', {
						            params: {
						                id: this.$store.state.meetingid
                            // id:'1'
						            },
						            responseType: 'blob' // 设置响应类型为 blob
						        });
						// console.log(response)
						        // // 创建一个 Blob 对象
						        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
						
						        // 使用 FileSaver.js 提供的 saveAs 函数保存 Blob 对象为文件
						        saveAs(blob, this.$store.state.meeting+'.docx');
						    } catch (error) {
						        console.error('下载文件时出错:', error);
						    }
      },
    handleAvatarSuccess(res, file) {
      console.log(res)
      },
      beforeAvatarUpload(file) {
        const isImage = /\.(jpeg|jpg|png|gif)$/i.test(file.name);
        const isLt2M = file.size / 1024 / 1024 < 8;//  这一行的作用是判断上传的图片文件的大小是否小于 2MB。file.size 是文件的字节数，除以 1024 得到 KB，再除以 1024 得到 MB。
        if(!isImage){
          this.$message.error('只能上传图片且后缀名不能为webp!');
        }else{
          if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 8MB!');
        }
        }
       
        return  isLt2M && isImage;
      },
      async tosubmit(){
      const {data:res} = await this.$http.put('users/Updateinformation',{
        globalid:this.globalid,
        name:this.name,
        phone:this.myphone,
        id:this.id,
        dw:this.dw,
        content:this.textarea,
        img:this.imageUrl
      })
      if(res.flag==true){
        // this.$refs.readref.clearBoth()
        this.getoption(this.meetingid)
        this.$notify({
                    title: '提交成功',
                    message: res.msg,
                    type: 'success'
                    });
        this.dialogVisible = false
        store.dispatch('getnum',this.$store.state.meetingid)
        this.Synchronizedata()
      }else{
        this.$notify({
                    title: '失败',
                    message: '请联系管理员',
                    type: 'error'
                    });
      }
      },
      async Synchronizedata(){
        const {data:res} = await this.$http.put('/users/Synchronizedata',{
          id:this.id,
        })
        if(res.flag==true){
          console.log(res)
        }
      },
      filterres(value){
        this.Notcollected=0
        this.Collected=0
        for(let i=0;i<value.length;i++){
          // console.log(value[i].text)
          let con = value[i].text
          if(con == null){
            this.Notcollected ++
          }else{
            this.Collected++
          }
        }
      }
      }
  }
  </script>
  <style scoped>
  .meetingperson{

    position: relative;
      display: flex;
      box-sizing: border-box;
      height: 100%;
  }
  .meetingperson .el-avatar{
    background: #ffffff;
    cursor: pointer;
  }
  .toopdiv{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 25%;
    top: -10px;
    height: 60px;
    width: 600px;
    /* border: 1px dashed black; */
    border-radius: 20px;
    z-index: 999;
    background-color: #ffffff;
  }
  .flex1{
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex: 1;
      border-right: 1px dashed gray;
  }
  .el-select{
     margin-bottom: 15px;
     margin-top: 15px;
  }
  .flex2{
    /* background-color: #f3f4f6; */
      box-sizing: border-box;
      padding: 15px;
      flex: 3;
      height: 100%;
      
      overflow: auto;
  }
  .list {
      list-style: none;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
    .list li {
      display: flex;
      align-items: center;
      justify-content: space-around;
      /* width: 100%; */
      box-sizing: border-box;
      height: 60px;
      border-radius: 10px;
  
      line-height: 40px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      background: #fff;
      /* cursor: move; */
    }
    .listflex1{
      flex: 1;
    }
    .listflex2{
      flex: 2;
    }
    .listfbtm{
      flex: 1.5;

    }
    .search{
      border-bottom: 1px solid #f3f4f6;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 45px;
      width: 100%;
      /* border: 1px solid #f3f4f6; */
      border-radius: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      line-height: 45px;
    }
    .result{
      height: calc(100% - 60px);
      width: 100%;
      overflow: auto;
      position: relative;
    }
    @media screen and (min-width:1900px){
      .result{
        width: 85%;
      }
      .flex2{
        display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      }
      .search{
        justify-content: center;
      }
    }
    .dongtu{
      position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
      background: #ffffff;
      z-index: 999;
    }
    .updatediv{
      width: 100%;
    }
    .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed gray;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .collecttype{
    font-size: 17px;
    font-family: '微软雅黑';
  }
  .item {
  margin-top: 10px;
}
.linkting{
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  font-family: '微软雅黑';
}
.linkting label{
  margin-right: 5px;
}
.adddiv .el-input{
 margin-bottom: 15px;
}
.btndongtu{
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 定义进入和离开的过渡状态 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active 在2.1.8版本及以上 */ {
  opacity: 0;
}
.piliang{
  height: 100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topupdatediv{
  display: flex;
}
.bigdialog{
  display: flex;
  height: 100%;
  width: 100%;
}

.left, .right{
 width: 50%;
}
.left{
  border: 1px solid black;
}
  </style>