<template>
  <div >
    <el-form ref="personref" :rules="personrules" :model="person" label-width="80px">
        <el-form-item label="会议ID" prop="id">
            <el-input v-model="person.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="全局ID" prop="globalid">
            <el-input v-model="person.globalid" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
            <el-input v-model="person.name"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
            <el-input v-model="person.phone"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="dw">
            <el-input v-model="person.dw"></el-input>
        </el-form-item>

    </el-form>
    <div class="personvue">
        <el-button @click="cancle">取消</el-button>
        <el-button @click="batch" type="warning">批量新增</el-button>
        <el-button @click="tosubmit" type="primary">确定</el-button>
    </div>
   
  </div>
</template>

<script>
let that
// 使用箭头函数定义 isvalidPhone 函数
const isvalidPhone = (rule, value, callback) => {
  // 校验手机号码的正则表达式
  var reg = /^1[3456789]\d{9}$/

  // 如果输入为空，直接返回
  if (!value) {
    callback()
  } else {
    // 如果输入不为空，检查是否符合正则表达式
    if (reg.test(value)) {
      // 如果符合，返回成功
      that.globalid(value)

      callback()
      // console.log(value)
    } else {
      // 如果不符合，返回错误信息
      callback(new Error('请输入正确的手机号码'))
    }
  }
}

export default {
   
    data(){
        return{
            person:{
                id:'',
                name:'',
                dw:'',
                phone:'',
                globalid:''
            },
            dialog:false,
            personrules:{
                id:[{required:true,message: "ID不能为空", trigger: "blur"}],
                globalid:[{required:true,message: "ID不能为空", trigger: "blur"}],
                name:[{required:true,message: "姓名不能为空", trigger: "blur"}],
                dw:[{required:true,message: "单位不能为空", trigger: "blur"}],
                phone:[{required:true,message: "电话不能为空", trigger: "blur"},
                { validator: isvalidPhone, trigger: 'blur' } // 使用自定义的验证规则
            ]
            }
        }
    },
    mounted(){
        // this.createid(6)
        // console.log(2)
    },
    created() {
    that = this
  },
    methods:{
        batch(){
            this.$router.push('/meetingperson')
        },
        cancle(){
            this.$emit('todialog',this.dialog)
            this.$refs.personref.resetFields();
        },
        tosubmit(){
            let {id,name,phone,dw,globalid} = this.person
            let arr = [id,name,dw,this.$store.state.meeting,this.$store.state.meetingid,phone,globalid,Date.now()],arr2=[]
        this.$refs.personref.validate(async v=>{
            if(!v)return
            // this.$refs.personref.resetFields();
            arr2.push(arr)
            const {data:res} = await this.$http.put('users/meetperson',{
                personvalue:arr2
            })
            if(res.flag==true){
            this.$emit('todialog',this.dialog)
            this.$refs.personref.resetFields();
            this.$message.success(res.msg)

            }else{
                this.$message.error(res.msg)
            }
        })
        },
       async globalid(value){
        const {data:res} = await this.$http.get('/users/checkglobalid', {
                    params: { phone: value }
                    });
                    if(res.flag==true){
                    // console.log(res)
                     this.person.globalid = res.data[0].globalid
                    }else{
                     this.makeglobalid(4).then(globalid=>{
                     this.person.globalid =  globalid
                     })
                    }
            // console.log(value)
        },
      async makeglobalid(length) {
            // let timestamp = Date.now().toString();
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }

            let exists = false;
            do {
                // 检查ID是否存在
                const {data:res} = await this.$http.get('/users/createglobalid', {
                params: { id: result }
                });
                exists = res.flag; // 假设返回的数据中的flag字段表示ID是否存在
                if (exists) {
                // 如果存在，重新生成ID
                result = '';
                counter = 0;
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    counter += 1;
                }
                }
            } while (exists); // 只要存在，就继续生成新的ID
            return  result
            },
       async createid(length){
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                counter += 1;
            }

            let exists = false;
            do {
                // 检查ID是否存在
                const {data:res} = await this.$http.get('/users/checkididexist', {
                params: { id: result }
                });
                exists = res.flag; // 假设返回的数据中的flag字段表示ID是否存在
                if (exists) {
                // 如果存在，重新生成ID
                result = '';
                counter = 0;
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                    counter += 1;
                }
                }
            } while (exists); // 只要存在，就继续生成新的ID
          return this.person.id = result // 最终返回生成的ID，这个ID在数据库中是唯一的
                    }
                }
}
</script>

<style>
.personvue{
display: flex;
align-items: flex-end;
justify-content: right;
    /* justify-content: flex-end; */
}
</style>