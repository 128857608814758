<template>
    <div class="addpptcontainer">
      
<el-drawer
  :title="pptname"
  :visible.sync="leridrawer"
  size="35%"
  direction="ltr"
  >
  <phpTemplateVue :ting='pptname'></phpTemplateVue>
</el-drawer>
<el-dialog
  title="替换"
  :visible.sync="replacedialog"
  width="60%">
  <div class="replaceDialogDiv">
    <replace :Beconfirm="confirmvalue" @closeReplaceDialog="closeDialog"></replace>
  </div>

</el-dialog>
      <el-dialog
        title="修改会场名称"
        :visible.sync="modifyhall"
        width="40%">
        <modifyhallVue :prename="ting" ref="modifyhallVueref" @checkhall="getvalue"></modifyhallVue>
        <span slot="footer" class="dialog-footer">
          <el-button @click="modifyhall = false">取 消</el-button>
          <el-button type="primary" @click="modifyhalldialog">确 定</el-button>
        </span>
        </el-dialog>
      <el-dialog
        title="修改简历"
        :visible.sync="modifydialog"
        width="40%">
        <div class="updatediv">
          <el-input v-model="name" disabled>
            <template slot="prepend">姓名</template>
          </el-input>
          <el-input v-model="dw" >
            <template slot="prepend">单位</template>
          </el-input>
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入内容"
            v-model="textarea">
          </el-input>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="upload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" ></i>
          </el-upload>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="modifydialog = false">取 消</el-button>
          <el-button type="primary" @click="tosubmit">确 定</el-button>
        </span>
      </el-dialog>
        <div class="flex1">
          <el-dialog
            title="输入串场名称"
            :visible.sync="dialogVisible"
            width="30%"
          >
            <el-input v-model="pptname">
              <template slot="prepend">串场名称</template>
          </el-input> 
          <el-input v-model="color">
              <template slot="prepend">字体颜色</template>
              <template slot="append">
                <el-color-picker v-model="color" size="small"></el-color-picker>
              </template>
          </el-input> 
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="createppt">确 定</el-button>
            </span>
          </el-dialog>
           <div class="toselect">
            <div :class="['tingdiv',selindex ==index ? 'issel':'']" v-for="(item,index) in tingoption" :key="index" type="success" @click="getmeetingperson({name:item.ting,ind:index})">{{item.ting}}</div>
            &nbsp;
            <el-radio-group v-model="radiovalue" @change="getradiovalue" v-show="ting !=''">
              <el-radio :label="0">全部</el-radio>
              <el-radio :label="1">已收集</el-radio>
              <el-radio :label="2">未收集</el-radio>
            </el-radio-group>
            &nbsp;
            &nbsp;
            <el-button @click="pptdialog" :disabled="ting==''" type="primary" size="small">导出{{ ting }}</el-button>
            <el-button type="danger" size="small" v-show="ting !=''" @click="modifyhall=true">修改会场名称</el-button>
            <!-- <el-button type="danger" size="small" v-show="ting !=''" @click="test">测试</el-button> -->
           </div>
        </div>
        <div class="flex2">
            <div class="title">
            <ul class="titlelist">
                <li>
                  <div class="listflex1 ">
                       序号
                    </div>
                    <div class="listflex1 ">
                       姓名
                    </div>
                    <div class=" listflex2 ">
                       单位
                    </div>
                    <div  class="listflex1 ">
                       任务
                    </div>
                    <div  class=" listflex2 ">
                        会议
                    </div>
                    <div  class="listflex1 ">
                       厅
                    </div>
                    <div  class="listflex1 ">
                       简介收集状态
                    </div>
                    <div  class="listflex2 ">
                       操作
                    </div>
                </li>
            </ul>
          </div>
          <div class="container">
            <ul class="list" v-if="tingoption.length>0">
                <li
                    v-for="(item, index) in list"
                    :key="item.id"
                    :class="{ dragging: item.id === draggingId }"
                    draggable="true"
                    @dragstart="dragStart(index)"
                    @dragend="dragEnd"
                    @dragover="dragOver(index)"
                   
                    >
                    <div class="listflex1 ">
                       {{ index+1 }}
                    </div>
                    <div class="listflex1 ">
                       {{ item.name }}
                    </div>
                    <div class=" listflex2 "> 
                       {{ item.dw }}
                    </div>
                    <div  class="listflex1 ">
                      <el-tag v-if="item.task =='主持' " >{{ item.task }}</el-tag>
                      <el-tag v-else type="info">{{ item.task }}</el-tag>
                    </div>
                    <div  class=" listflex2 ">
                        {{ item.meeting }}
                    </div>
                    <div  class="listflex1 ">
                       {{ item.ting }}
                    </div>
                    <div  class="listflex1 ">
                      <el-tag v-if="item.text !=null " type="success">已收集</el-tag>
                      <el-tag v-else type="danger">未收集</el-tag>
                    </div>
                    <div  class=" listflex2 ">
                      <el-button type="primary"  size="small"  @click="update(item.uniqueid)">修改</el-button>
                      <el-button type="danger"  size="small"  @click="del({id:item.id,name:item.name})">删除</el-button>
                      <el-button type="warning"  size="small"  @click="replacemethod(item)">替换</el-button>
                    </div>
                </li>
            </ul>
            <div v-else class="noshuju">
              暂无串场列表数据，去&nbsp;<el-button type="primary" size="small" @click="Batchimport">批量导入</el-button>

            </div>
          </div>
        </div>
    </div>
  </template>
  <script>
import store from '@/store';
import COS from 'cos-js-sdk-v5'
import modifyhallVue from './modifyhall.vue';
import FileSaver from 'file-saver';
import phpTemplateVue from './phpTemplate.vue';
import replace from './replace.vue';
  export default {
    data() {
      return {
        id:'',
        modifydialog:false,
        name:'',
        dw:'',
        textarea:'',
        list: [],
        draggingId: null,
        draggingIndex: null,
        pptxvalue:[],
        pptvalue:[],
        imageUrl:'',
        dialogVisible: false,
        pptname:'简介',
        meetingid:'',
        option:'',
        ting:'',
        tingoption:'',
        empty:true,
        loading:false,
        cos: null, 
        color:'#409EFF',
        modifyhall:false,
        radiovalue:0,
        selindex:-1,
        leridrawer:false,
        replacedialog:false,
        confirmvalue:[]
      };
    },
    created(){
    },
    mounted(){
        let meetingid = this.$store.state.meetingid
        this.meetingid = meetingid
        this.getoption(meetingid)
    },
    filters:{
      newoffice(value1,value2){
        if(value2==null){
          return '无更新'
        }else{
          if(value2>value1){
            return '有更新'
          }else{
          return '无更新'

          }
        }
      }
    },
    components:{
      modifyhallVue,
      phpTemplateVue,
      replace
    },
    methods: {
      replacemethod(value){
        this.confirmvalue=[]
        this.confirmvalue.push(value.id)
        this.confirmvalue.push(value.name)
        this.confirmvalue.push(value.dw)
        this.replacedialog=true
      },
    async  getradiovalue(value){
        this.list=[]
        const {data:res} = await this.$http.get('users/getmeetingperson',{
                params:{
                    "meetingid":this.meetingid,
                    "ting":this.ting,
                    "radio":value
                }
            })
            if(res.flag == true){
            this.list = res.data
            //  console.log('1')
            }else{
              console.log('暂无数据')
            }
            this.loading.close()
            this.pptvalue = res.data
      },
      getvalue(value){
        // console.log(value,'个体value')
        this.modifyhall=value
        this.getoption(this.meetingid)
        this.list = []
      },
      modifyhalldialog(){
        this.$refs.modifyhallVueref.modify()
      },
         // 批量导入
         Batchimport(){
          this.$router.push('/addppt')
        },
      relatedmeetings(value){
        console.log(value)
      },
      checkforUpdate(value){
        if(value.t2 ==null){
          return false
        }else{
          if(value.t2>value.t1){
            return true
          }else{
          return false

          }
        }
      },
     async del(value){
      const confirm= await this.$confirm('是否删除->'+value.name,{
        type:'warning',
        title:'提示',
        confirmButtonClass:'确定',
        cancelButtonClass:'取消'
      }).catch(e=>{})
      if(confirm == 'confirm'){
        const {data:res} = await this.$http.delete('users/Crossdressingpersonnel',{
         params:{
           id:value.id
         }
        })
        if(res.flag==true){
          this.$message.success(res.msg)
          this.getmeetingperson({name:this.ting,ind:this.selindex})
        }else{
          this.$message.error(res.msg)
        }
      }
      },
      goback(){
        this.$router.push('/PPThomepage')
      },
      async tosubmit(){
      const {data:res} = await this.$http.put('https://seats.kunbocs.cn/updateoneselfinformation',{
        id:this.id,
        dw:this.dw,
        content:this.textarea,
        img:this.imageUrl
      })
      if(res.flag==true){
        // this.getmeetingperson(this.ting)
          this.getmeetingperson({name:this.ting,ind:this.selindex})

        this.$notify({
                    title: '提交成功',
                    message: res.msg,
                    type: 'success'
                    });
        this.modifydialog = false
        this.empty=false
        store.dispatch('getnum',this.$store.state.meetingid)
        // this.getmeeting()
      }else{
        this.$notify({
                    title: '失败',
                    message: '请联系管理员',
                    type: 'error'
                    });
      }
    },
      async  update(value){
          this.id = value
          // const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getinfo',{
          const {data:res} = await this.$http.get('users/getinfo',{
            params:{
              id:value
            }
          })
          // console.log(res.data)
          if(res.flag==true){
          this.modifydialog = true
            this.name = res.data[0].name
            this.dw = res.data[0].dw
            this.textarea = res.data[0].text
            this.imageUrl = res.data[0].img
          }else{
          }
        },
        async test(){
          console.log('-0')
          const response = await this.$http.post('https://resume.kunbocs.cn/resumeExport.php',{
            id:this.meetingid,
            ting:this.ting,
            color:this.color
          }, { responseType: 'blob' });
          // console.log(response)
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });
    FileSaver.saveAs(blob, 'sample.pptx');
        },
      async getmeetingperson(value){
         this.loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(225, 225, 225, 0.7)'
        });
        this.ting = value.name
        this.selindex = value.ind
        this.radio=0
        const {data:res} = await this.$http.get('users/getmeetingperson',{
                params:{
                    "meetingid":this.meetingid,
                    "ting":value.name,
                    "radio":this.radiovalue
                }
            })
            if(res.flag == true){
            this.list = res.data
            //  console.log('1')
            this.loading.close()
            }else{
            this.loading.close()

            }
            this.pptvalue = res.data
      },
      async  getoption(value){
            const {data:res} = await this.$http.get('users/getlinkperson',{
                params:{
                    "meetingid":value
                }
            })
            if(res.flag==true){
              let arr = res.data
            let map = new Map()
            arr.forEach(item => {
                map.set(item.ting,item)
            });
          this.tingoption= [...map.values()]
            }else{
              this.tingoption=''
              this.ting=''
            }
        },
      async  getmeeting(){
            const {data:res} = await this.$http.get('users/getmeeting')
           if(res.flag==true){
            let arr = res.data
            let map = new Map()
            arr.forEach(item => {
                map.set(item.meeting,item)
            });
          this.option= [...map.values()]
           }
        },
      handleRemove(file, fileList) {
      console.log(file);
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = () => {
        console.log(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    },
      dragStart(index) {
        this.draggingId = this.list[index].id;
        this.draggingIndex = index;
      },
      dragEnd() {
        this.draggingId = null;
        this.draggingIndex = null;
        // 遍历list，更新weight
        for (let i = 0; i < this.list.length; i++) {
            this.list[i].weight = i + 1;
        }
        // 调用后端接口，传递排序后的id数组
        const idList = this.list.map((item) =>
       [item.weight,item.id]
         );
         this.changgweight(idList)
        // console.log(idList,'');
        // this.$http.post("/api/sort", { idList });
      },
      dragOver(index) {
        if (index !== this.draggingIndex) {
          // 交换数组元素
          const temp = this.list[index];
          this.list[index] = this.list[this.draggingIndex];
          this.list[this.draggingIndex] = temp;
          // 更新拖动元素的索引
          this.draggingIndex = index;
        }
      },
      pptdialog(){
        this.leridrawer=true
        this.pptname = this.ting
      },
       async createppt(){
        console.log(this.pptvalue)
            // const {data:res} = await this.$http.post('http://192.168.1.87:3000/pptx',{
            const {data:res} = await this.$http.post('https://seats.kunbocs.cn/pptx',{
                    "pptname":this.pptname,
                    "pptvalue":this.pptvalue,
                    'color':this.color
            },{
              responseType: 'blob',
            })
                const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' });  
                const url = window.URL.createObjectURL(blob);  
                const link = document.createElement('a');  
                link.href = url;  
                link.download = this.pptname+'.pptx';  
                link.click();  
                window.URL.revokeObjectURL(url);  
              this.dialogVisible = false
              this.$message.success('下载中')
        },
       async changgweight(value){
        // console.log(value,'=-')
        this.radiovalue=0
        const {data:res} = await this.$http.put('users/changeweight',{
            "weight":value
        })
        
        if(res.flag==true){
            // this.getmeetingperson(this.ting)
          this.getmeetingperson({name:this.ting,ind:this.selindex})

            this.$notify({
                    title: '成功',
                    message: res.msg,
                    type: 'success'
                    });
        }else{
            this.$notify.error({
                    title: '错误',
                    message: res.msg
                    });
        }
        },
        handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return  isLt2M;
      },
      getFile (file, fileList) {
      // file.raw是文件对象
      let reader = new FileReader ()
      reader.readAsDataURL (file.raw)
      reader.onload = () => {
        // reader.result是base64字符串
        this.imageUrl = reader.result
        // console.log (reader.result,';;')
        // 您可以将reader.result赋值给一个变量，或者传递给后端接口
      }
    },
      async  upload(file){
        let houzui=file.file.type.split('/')
        // const {data:res} = await this.$http.get('http://192.168.1.123:3000/getTempKeys')
        const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getTempKeys')
        // console.log(res)
          this.cos = new COS({
            getAuthorization: (options, callback) => {
              callback({
                TmpSecretId: res.credentials.tmpSecretId,
                TmpSecretKey: res.credentials.tmpSecretKey,
                XCosSecurityToken: res.credentials.sessionToken,
                ExpiredTime: res.expiredTime,
              });
            },
          });
          // let key = "/art/" + file.file.name;
          let timeout = Date.now()
          let key = "/speakerImage/" +this.$store.state.meeting+'/'+ this.name+timeout+'.'+houzui[1];

          // console.log(key,'key')
          this.cos.putObject({
            Bucket: "file-1257652773", // 存储桶名称
              Region: "ap-guangzhou", // 存储桶所在地域，必须字段
              Key: key, // 图片名称
              StorageClass: "STANDARD",
              Body: file.file, // 上传文件对象
              onHashProgress: (progressData) => {
                console.log("校验中", JSON.stringify(progressData));
              },
              onProgress: (progressData) => {
                const percent = parseInt(progressData.percent * 10000) / 100;
                const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
              },
          },(err,data)=>{
            // console.log(data,'=-=')
            if (data && data.statusCode == 200) {
                // 如果上传成功，拼接图片链接地址，并赋值给 imageUrl
                let fileUrl ='https://'+data.Location+'?imageMogr2/scrop/420x540'
                this.imageUrl = fileUrl;
                // console.log(fileUrl);
              } else {
                console.log(err,'1');
                // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户上传失败
              }
          }).catch((err) => {
          console.log(err,'2');
          // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户获取密钥失败
        });
       
        },
        closeDialog(value){
          this.replacedialog=value
          this.getmeetingperson({name:this.ting,ind:this.selindex})

        }
    },
  };
  </script>
  
  <style scoped>
  .addpptcontainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .flex2{
    flex: 3;
    height: 100%;
    overflow: auto;
  }
  .flex1{
    padding: 10px 0;
    margin-bottom: 5px;
    border-bottom: 1px solid #f3f4f6;
  }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .list li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height:50px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    cursor: move;
  }
  .dragging {
    opacity: 0.5;
  }
  .listflex1{
    flex: 1;
  }
  .listflex2{
    flex: 2;
  }
  .border{
    border: 1px solid red;
  }
  .danger{
    border-color: red;
    color: red;
  }
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed #409EFF;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .toselect{
    display: flex;
    align-items: center;
  }
  .toselect .el-tag{
    cursor: pointer;
    margin-right: 10px;
  }
  .title{
    box-sizing: border-box;
    padding-right:10px ;
    height: 40px;
    width: 100%;
  }
  .titlelist{
    padding-right: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .titlelist li{
  padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    background: #fff;
}
.container{
height: calc(100% - 50px);
width: 100%;
overflow: auto;
}
.noshuju{
  height: 100%; 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tingdiv{
  cursor: pointer;
  padding: 3px 5px;
  border: 1px solid #bec3cc;
  margin-left: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center
}
.issel{
  background-color: #4389f9;
  color: #fff;
}
.replaceDialogDiv{
  height: 400px;
}
  </style>