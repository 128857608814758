<!-- 筛选没交串场的名单 -->
<template>
  <div class="screen">
    <div class="top">
        <div v-for="(item,index) in tingoption" :key="index" :class="['forname', selindex==index ? 'sel':'nosel']" @click="selting(index)">
                {{ item.ting }}
        </div>
    </div>
    <div class="bottom">
        <div class="echart" ref="pieChart"></div>
        <div class="namelist">
            <div class="weishouji">
             <el-tag type="danger">{{ myselting }} 未收集名单：</el-tag>   <el-tag type="danger" effect="plain" v-for="(item,index) in isnotshouji" :key="index+'2'" >{{ item.name }}</el-tag>
                <el-button type="primary" size="small" plain @click="exportlink('未收集')">导出</el-button>
            </div>
            <div class="shouji">
             <el-tag type="success">{{ myselting }} 已收集名单：</el-tag>   <el-tag type="success" effect="plain" v-for="(item1,index2) in isshouji" :key="index2+'1'" >{{ item1.name }}</el-tag>
             <el-button type="primary" size="small" plain @click="exportlink('收集')">导出</el-button>

            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import excel from '@/libs/excel'
export default {
    data(){
        return{
            meetingid:'',
            tingoption:[],
            selindex:0,
            num1:0,
            num2:0,
            isshouji:[],
            isnotshouji:[],
            myselting:''
        }
    },
    mounted(){
        let meetingid = this.$store.state.meetingid
        this.meetingid = meetingid
        this.getoption(meetingid)
    },
    methods:{
        async  exportlink(value){
            const confirm = await this.$confirm('是否批量导出'+value+'名单?',{
              title:"提示",
              type:'warning',
              confirmButtonText:'确定',
              cancelButtonText:'取消'
            }).catch(err=>{

            })
            if(confirm=='confirm'){
                let arr = value == '收集' ? this.isshouji:this.isnotshouji
                let exportvalue = []
                // for(let i=0;i<arr.length;i++){
                //     let obj = {name:''}
                //     obj.name = arr[i]
                //     exportvalue.push(obj)
                // }
              let params = {
                title: ["姓名","单位","电话号码"],
                key: ["name","dw","phone"],
                data: arr, // 数据源
                autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
                filename:this.myselting.toString() + value+"名单",
              };
              excel.exportArrayToExcel(params);
            }

            },
        async selting(value){
            this.selindex=value
            this.getscreem(this.tingoption[value].ting)
        },
        async  getoption(value){
            const {data:res} = await this.$http.get('users/getlinkperson',{
                params:{
                    "meetingid":value
                }
            })
            if(res.flag==true){
              let arr = res.data
            let map = new Map()
            arr.forEach(item => {
                map.set(item.ting,item)
            });
          this.tingoption= [...map.values()]
          this.getscreem(this.tingoption[0].ting)
            }else{
              this.tingopti
            }
        },
      async  getscreem(value){
        const {data:res} = await this.$http.get('users/getsreem',{
                params:{
                    "meetingid":this.meetingid,
                    'ting':value
                }
            })
            // console.log(res,'=-')  
            if(res.flag==true){
                this.num1 = res.data[0].c1
                this.num2 = res.data[0].c2
                this.echarts1()
            }
            this.getisshouji(value)
        },
        echarts1(){

    let chart = {
  xAxis: {
    type: 'category',
    data: ['未收集', '已收集']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [
        {
          value: this.num1,
          itemStyle: { color: 'red' },
          // 在这里添加label属性来显示数字
          label: {
            show: true,
            position: 'top',
                       // 使用formatter来添加单位
                       formatter: function (params) {
              return params.value + '个';
            }
          }
        },
        {
          value: this.num2,
          itemStyle: { color: '#93c23a' },
          // 在这里添加label属性来显示数字
          label: {
            show: true,
            position: 'top',
                       // 使用formatter来添加单位
                       formatter: function (params) {
              return params.value + '个';
            }       
          }
        },
      ],
      type: 'bar',
      // 设置柱子的宽度
      barWidth: '50%' // 可以根据需要调整宽度的百分比
    }
  ]
};

    let chartDom = this.$refs.pieChart;
    let myChart =this.$echarts.init(chartDom);
    myChart.setOption(chart);
   },
   async getisshouji(value){
    this.myselting = value

    this.isshouji=[]
    this.isnotshouji=[]
    const {data:res} = await this.$http.get('users/getisnotshouji',{
                params:{
                    "meetingid":this.meetingid,
                    'ting':value
                }
            })
            console.log(res)
            if(res.flag==true){
                let resvalue = res.data
                for(let i=0;i<resvalue.length;i++){
                    let ivalue = resvalue[i]
                    if(ivalue.text_status=='已收集'){
                        this.isshouji.push(resvalue[i])
                    }else{
                        this.isnotshouji.push(resvalue[i])
                    }
                }
            }
   }
    }
}
</script>
<style scoped>
    .screen{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .top{
        height: 60px;
        width: 100%;
        border: 1px solid #f3f4f6;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .forname{
        cursor: pointer;
        border: 1px solid gray;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: bold;
    }
    .sel{
        background-color: #4389f9;
        color: #ffffff;
    }
    .nosel{

    }
    .bottom{
        height: calc(100% - 60px);
        display: flex;
        overflow: auto;
    }
    .echart{
        border-right: 1px dashed black;
        width: 500px;
    }
    .weishouji{
        display: flex;
        flex-wrap: wrap;

    }
    .weishouji .el-tag{
        margin-right: 5px;
        margin-bottom: 5px;
    }
    .shouji{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

    }
    .shouji .el-tag{
        margin-right: 5px;
        margin-bottom: 5px;
    }
</style>