import { Message } from 'element-ui'
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import checkseat from '../components/checkseat.vue'
import jump from '../components/jump.vue'
import qrcodejs2 from '../components/qrcodejs2.vue'
import meetingface from '../components/MeetingFace.vue'
import usermanger from '../components/Usermanagement.vue'
import pptx from '../components/pptx/pptx.vue'
import addppt from '../components/pptx/addPpt.vue'
import checkppt from '../components/pptx/checkppt.vue'
import modifyppt from '../components/pptx/modifyppt.vue'
import meetingperson from '../components/pptx/meetingperson.vue'
import link from '../components/pptx/link.vue'
import inppt from '../components/pptx/fillInppt.vue'
import PPThomepage from '../components/pptx/PPThomepage.vue'
import suc from '../components/pptx/success.vue'
import read from '../components/pptx/read.vue'
import encapsulation from '../components/pptx/encapsulation.vue'
import Originalimage from '../components/pptx/Originalimage.vue'
import screen from '../components/pptx/screen.vue'
import Completelydelete from "../components/pptx/Completelydelete.vue"
import replace from "../components/pptx/replace.vue"
import seat from "../views/seat.vue"

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  }, 
  {
    path: '/encapsulation',
    name: 'encapsulation',
    component: encapsulation
  }, 
  {
    path: '/Originalimage',
    name: 'Originalimage',
    component: Originalimage
  }, 
  {
    path: '/suc',
    name: 'suc',
    component: suc
  }, 
  {
    path: '/inppt',
    name: 'inppt',
    component: inppt
  },
  {
    path:'/PPThomepage',
    name:'PPThomepage',
    component:PPThomepage
  },
  {path:'/isdelete',name:'Completelydelete',component:Completelydelete},
  {
    path:'/replace',
    name:'replace',
    component:replace
  },
  {path:'/replace',name:'replace',component:replace},

  {
    path: '/pptx',
    name: 'pptx',
    component: pptx,
    redirect:'/link',
    children:[
      {path:'/link',name:'link',component:link},
      {path:'/checkppt',name:'checkppt',component:checkppt},
      {path:'/addppt',name:'addppt',component:addppt},
      {path:'/modifyppt',name:'modifyppt',component:modifyppt},
      {path:'/meetingperson', name:'meetingperson',component:meetingperson},

      {path:'/read',name:'read',component:read},
      {path:'/screen',name:'screen',component:screen},
    ]
  }, 
  {
    path: '/jump',
    name: 'jump',
    component: jump
  },
  {
    path: '/usermanger',
    name: 'usermanger',
    component: usermanger
  },
  {
    path: '/qrcodejs2',
    name: 'qrcodejs2',
    meta: {
      charset: 'utf-8',
      name: 'viewport', 
      content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
  },
    component: qrcodejs2
  },
  {
    path: '/meetingface',
    name: 'meetingface',
    component: meetingface
  },
  {
    path: '/checkseat',
    name: 'checkseat',
    component: checkseat
  },
  {
    path: '/seat',
    name: 'seat',
    component: seat
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
const openPaths = ['/', '/inppt', '/suc', '/public', '/Originalimage','/checkseat','/seat']; // 允许访问的路径列表

router.beforeEach((to, from, next) => {
  const tokenStr = window.sessionStorage.getItem('token');
  if (tokenStr || openPaths.includes(to.path)) {
    next();
  } else {
    Message({
      message: '还未登陆，请先登录',
      type: 'warning',
      iconClass: 'el-icon-close'
    });
    next('/');
  }
});

// router.beforeEach((to,from,next)=>{
//   let tokenStr=window.sessionStorage.getItem('token')
//   if (tokenStr){
//     next()
//   }else {
//     if (to.path==='/' || to.path==='/inppt' || to.path ==='/suc' || to.path==='/public' || to.path==='/Originalimage'){
//       next()
//     }else {
//       Message({
//         message: '还未登陆，请先登录',
//         type: 'warning',
//         iconClass: 'el-icon-close'
//       })
//       next('/')
//     }
//   }
// })
// history
// process.env.BASE_URL,

export default router
