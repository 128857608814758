<template>
    <div class="addpptcontainer">
        <div class="importdiv">
          <div class="exoprttemplate">
            <el-button type="primary" size="small" @click="exoprttemplate">串场模版</el-button>
          </div>
            <el-upload
                ref="upload" 
                class="upload-demo"
                drag
                action
                :limit="1"
                :show-file-list="false"
                :http-request="getExcel"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
            &nbsp;
        </div>
        <div class="flex2">
          <div class="tip2">预览界面,确认无误后点击右边<i style="color: #f56c6c;">红色按钮</i>上传到数据库 &nbsp;
            <el-button @click="tosubmit" type="danger" size="small">提交到数据库</el-button>
            &nbsp;
            &nbsp;
            <el-button @click="mygoback" size="small" type="danger">返回</el-button>


        </div>
          <div class="title">
            <ul class="titlelist">
                <li>
                    <div class="listflex1 ">
                       姓名
                    </div>
                    <div class=" listflex2 ">
                       单位
                    </div>
                    <div  class="listflex1 ">
                       任务
                    </div>
                    <div  class=" listflex2 ">
                        会议
                    </div>
                    <div  class="listflex1 ">
                       厅
                    </div>
                </li>
            </ul>
          </div>
          <el-result v-show="isupload" icon="success" title="上传成功" subTitle="请根据提示进行操作"> 
            <!-- <template slot="extra">
                <el-button type="primary" size="medium" @click="test">返回</el-button>
            </template> -->
        </el-result>
        <el-empty v-show="isNull && !isupload" description="暂无数据"></el-empty>

          <div class="container" v-show="!isupload">
            <ul class="list">
                <li
                    v-for="(item, index) in list"
                    :key="item.id"
                    :class="{ dragging: item.id === draggingId }"
                    draggable="true"
                    @dragstart="dragStart(index)"
                    @dragend="dragEnd"
                    @dragover="dragOver(index)"
                    @click="btnpptname(index)"
                    >
                    <div class="listflex1 ">
                       {{ item.name }}
                    </div>
                    <div class=" listflex2 ">
                       {{ item.dw }}
                    </div>
                    <div  class="listflex1 ">
                      {{ item.task }}
                    </div>
                    <div  class=" listflex2 ">
                        {{ item.meeting }}
                    </div>
                    <div  class="listflex1 ">
                       {{ item.ting }}
                    </div>
                </li>
            </ul>
          </div>
            
        </div>
    </div>
  </template>
  <script>
    import XLSX from 'xlsx';
    import excel from '@/libs/excel'
    import {toarr} from '../../libs/utlis'
    import {changetoarr} from '../../libs/utlis'
    import goback from '../goback.vue'
    import {idarr} from '../../libs/utlis'
    import store from '@/store';
    import gobackVue from '../goback.vue';
  export default {
    data() {
      return {
        meetingid:'',
        list: [
          // { id: 1, name: "Apple",dw:'单位1',task:'讲者',meeting:'肿瘤内科',ting:'松林厅',weight :''},
          // { id: 2, name: "Banana" ,dw:'单位2',task:'讲者',meeting:'肿瘤内科',ting:'松林厅',weight :''},
          // { id: 3, name: "Cherry" ,dw:'单位3',task:'讲者',meeting:'肿瘤内科',ting:'松林厅',weight :''},
          // { id: 4, name: "Durian" ,dw:'单位4',task:'讲者',meeting:'肿瘤内科',ting:'松林厅',weight :''},
          // { id: 5, name: "Eggplant" ,dw:'单位5',task:'讲者',meeting:'肿瘤内科',ting:'松林厅',weight :''},
        ],
        idlist:[],
        draggingId: null,
        draggingIndex: null,
        pptxvalue:[],
        pptvalue:[],
        exportList:[{name:'张三',dw:'KBCS',task:'讲者',ting:'富力1'}],
        isupload:false,
        isNull:true,
      };
    },
    created(){
      
    },
    components:{
      goback
    },
    mounted(){
      let meetingid = this.$store.state.meetingid
      this.meetingid = meetingid
      this.$store.dispatch('Obtainmeetingmaterials',meetingid)
    },
    watch:{
        list:{
            handler(newvalue,oldvalue){
                this.pptxvalue = changetoarr(newvalue)
                // console.log(this.pptxvalue)
            },deep:true
        }
    },
    methods: {
      mygoback(){
        this.$router.go('-1')
      },
      exoprttemplate(){
        const params = {
        title: ["姓名", "工作单位","任务","厅"],
        key: ["name", "dw","task","ting"],
        data: this.exportList, // 数据源
        autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
        filename: "新增串场模版",
      };
      excel.exportArrayToExcel(params);
      },
      dragStart(index) {
        this.draggingId = this.list[index].id;
        this.draggingIndex = index;
      },
      dragEnd() {
        this.draggingId = null;
        this.draggingIndex = null;
        // 遍历list，更新weight
        for (let i = 0; i < this.list.length; i++) {
            this.list[i].weight = i + 1;
        }
        // 调用后端接口，传递排序后的id数组
        const idList = this.list.map((item) => item.id);
        // console.log(idList,';;');
        // this.$http.post("/api/sort", { idList });
      },
      dragOver(index) {
        if (index !== this.draggingIndex) {
          // 交换数组元素
          const temp = this.list[index];
          this.list[index] = this.list[this.draggingIndex];
          this.list[this.draggingIndex] = temp;
          // 更新拖动元素的索引
          this.draggingIndex = index;
        }
      },
      btnpptname(value){
        console.log(this.list[value])
      },
           // 导入文件
        getExcel(file) {
        this.dialogVisible=false
        const types = file.file.name.split(".")[1]; //获取文件后缀
        const fileType = ["xlsx","xls"].some(
        item => item === types
        );
        if (!fileType) {
        this.$message("格式错误！请重新选择");
        return;
        }
        this.analysis(file).then(tableJson => {
        if (tableJson && tableJson.length > 0) {
            //成功解析出数据
            this.isNull=false
            let dataExcel = tableJson[0];
            let getvalue=JSON.stringify(dataExcel.sheet)
            // this.pptxvalue =toarr(JSON.parse(getvalue)) 
            this.list = toarr(JSON.parse(getvalue)) 
            console.log(this.list,'--0-0')
            this.$refs.upload.clearFiles();
        }
        });
        },
        //解析excel   
        analysis(file) {
        return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            let datajson = XLSX.read(data, {
            type: "binary"
            });
            const result = [];
            datajson.SheetNames.forEach(sheetName => {
            result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(datajson.Sheets[sheetName])
            });
            });
            resolve(result);
        };
        reader.readAsBinaryString(file.file);
        });
        },
        async tosubmit(){
          const confirm = await this.$confirm('是否确认提交？',{
                title:'提示',
                type:'warning',
                confirmButtonClass:"确定",
                cancelButtonClass:"取消"
            }).catch(e=>{

            })
            if(confirm == 'confirm'){
             const {data:res} = await this.$http.post('users/Batchinsertion',{
                "batchinsertionvalue":this.pptxvalue
            })
            if(res.flag == true){
              this.isupload = true
                this.$notify({
                    title: '成功',
                    message: res.msg,
                    type: 'success'
                    });
                    this.pptxvalue=[]
            }else{
                this.$notify.error({
                    title: '错误',
                    message: res.msg
                    });
            }
            }
        },
        goback(){
            this.$router.push('/PPThomepage')
        },
    },
  };
  </script>
  <style scoped>
  .addpptcontainer{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .flex2{
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 100%;
  }
  .title{
    box-sizing: border-box;
    padding-right:10px ;
    height: 60px;
    width: 100%;

  }
  .exoprttemplate{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    width: 100%;
  }
  .importdiv{
    padding-top:10px ;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
  }
  .flex1{
    padding-top: 10px;
  position: relative;
    flex: 1;
    border-right: 1px dashed black;
  }
  .goback{
    position: absolute;
    right: 5px;
  }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border-radius: 10px;

  }
  .titlelist{
    padding-right: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
.titlelist li{
  padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #ccc;
    background: #fff;
}
.container{
height: calc(100% - 60px);
width: 100%;
overflow: auto;
}
  .list li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    cursor: move;
  }
  .dragging {
    opacity: 0.5;
  }
  .listflex1{
    flex: 1;
  }
  .listflex2{
    flex: 2;
  }
  .border{
    border: 1px solid red;
  }
  .tip2{
    display: flex;
    align-items: center;
    justify-content: center;
    size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    height: 50px;
}
  </style>