<!-- 修改会议厅的名字、使用情况是当更换会议厅的时候 -->
<template>
  <div class="hall">
    <el-input v-model="prename" disabled>
        <template slot="prepend">
            原先厅名字
        </template>
    </el-input>
    <el-input v-model="name">
        <template slot="prepend">
            更换厅名字
        </template>
    </el-input>

  </div>
</template>

<script>
export default {
    data(){
        return{
            name:''
        }
    },
    props:['prename'],
    methods:{
       async modify(){
        const {data:res} = await this.$http.put('users/modifytinghall',{
            meetingid:this.$store.state.meetingid,
            name:this.name,
            name2:this.prename
        })
        if(res.flag==true){
            // console.log('aaasdasdasdasdasd')
            this.$emit('checkhall',false)
            this.name=''
        }
        }
    }
}
</script>

<style scoped>
    .hall{
        height: 100%;
        width: 100%;
    }
</style>