<template>
  <div class="replace">
    <div class="replacediv">
         <el-input placeholder="请输入姓名或电话号码" v-model="replacename" class="input-with-select">    <el-button slot="append" @click="getvalue" icon="el-icon-search"></el-button>
  </el-input>
  <el-table
    :data="restaurants"
    style="width: 100%"
     height="90%"   
    :header-cell-style="{ textAlign: 'center' }"
    :cell-style="{ textAlign: 'center' }"
    >
    <el-table-column
      prop="name"
      label="姓名"
     >
    </el-table-column>
    <el-table-column
      prop="dw"
      label="地址"
      >
    </el-table-column>
    <el-table-column
      label="操作"
      width="90"
      >
      <template slot-scope="scope">
        <el-button type="primary" size="small" @click="selperson(scope.row)">选择</el-button>
      </template>
      
    </el-table-column>
  </el-table>
    </div>
    <div class="value">
    </div>
    <div class="replacediv">
        <div class="daiqueren">
            <div class="daiqueren2">
                <el-input v-model="Beconfirm[1]" disabled style="width: 170px;"></el-input>
                <el-input v-model="Beconfirm[2]" disabled></el-input>
            </div>
            替换成
            <div class="daiqueren2">
                <el-input v-model="name" disabled style="width: 170px;"></el-input>
                <el-input v-model="dw" disabled></el-input>
            </div>
           <div class="btndiv">
            <el-button type="danger" @click="confirmReplace()"> 替换</el-button>
           </div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        restaurants: [],
        replacename:'',
        id:'',
        name:'',
        dw:''
      };
    },
    props:['Beconfirm'],
    methods: {
     async getvalue() {
        const {data:res} = await this.$http.get('users/getreplacevalue',{
                params:{
                    "meetingid":this.$store.state.meetingid,
                    "name":this.replacename,
                    "phone":this.replacename
                }
            })
            
            if(res.flag==true){
                this.restaurants = res.data
            }else{
                this.$message.error(res.msg)
            }   
      },
      selperson(value){
        this.id=value.id
        this.name=value.name
        this.dw=value.dw
      },
     async confirmReplace(){
        if(this.id!=''){
            const confirm =await this.$confirm('是否确认《'+this.name+'》替换《'+this.Beconfirm[1]+'》？',
                {
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                    type:'danger',
                    title:'提示'
                }
            ).catch(e=>{

            })
            
            if(confirm=='confirm'){
                const {data:res} = await this.$http.put('users/updateReplace',{
                    "userid":this.id,
                    "name":this.name,
                    "dw":this.dw,
                    "id":this.Beconfirm[0]
            })
            console.log(res)
            if(res.flag==true){
                this.$message.success(res.msg)
                this.$emit('closeReplaceDialog',false)
            }else{
                this.$message.error(res.msg)
            }
            }

        }else{
            this.$message.error('请先选择替换人')
        }

      }
    }
  };
</script>

<style>
.replace{
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
}
.replacediv{
    box-sizing: border-box;
    height: 100%;
    flex: 1;
    border: 1px solid #f3f4f6;
}
.value{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.daiqueren{

}
.daiqueren2{
display: flex;

}
.el-input.is-disabled .el-input__inner{
    background-color: #ffffff !important;
}
.btndiv{
    margin-top: 10px;
}
</style>