import store from "@/store";
import axios from "axios";
import COS from "cos-js-sdk-v5";
export function test(){
  // return store.state.meeting
}
export function debounce(fn, wait) {
    let timeout = null;
    wait = wait || 600;
    return function () {
      let that = this;
      if(timeout !== null)   clearTimeout(timeout);  
      timeout = setTimeout(() => {
        fn.apply(that);
      }, wait);
    }    
}
export function dispose(value){
  let arr = []
  for(let i=0;i<value.length;i++){
    let obj = value[i]
    obj.id=i,obj.分配状态='未分配'
    arr.push(obj)
  }
  return arr
}
export function toarr(value){
  let meeting = store.state.meeting,meetingid=store.state.meetingid
  let valen = value.length,arr=[]
  for(let i=0;i<valen;i++){
    let obj={ id: 1,uniqueid:'', name: "Apple",dw:'单位1',task:'讲者',meeting:meeting,ting:'松林厅',weight :'',meetingid:meetingid}
    obj.id=i
    obj.name=value[i].姓名
    obj.dw=value[i].工作单位
    obj.task=value[i].任务
    // obj.meeting=meeting
    obj.ting=value[i].厅
    obj.weight=i
    arr.push(obj)
  }
  let idlist = idarr(arr)
  return idlist
}
export function idarr(value){
  let list = store.state.list
  let C = [];
// 遍历 A 数组，对于每个元素，找到 B 数组中对应的元素，将 A 的 id 赋值给 B 的元素，然后将 B 的元素放入 C 数组
for (let i = 0; i < value.length; i++) {
  // 获取 A 数组中第 i 个元素的 id, name 和 unit
  let id = '';
  let name = value[i].name;
  let dw = value[i].dw;

  // 在 B 数组中查找 name 和 unit 相同的元素，返回其索引
  let index = list.findIndex(function (element) {
    id = element.id
    return element.name === name && element.dw === dw;
  });

  // 如果找到了匹配的元素，将 A 的 id 赋值给 B 的元素，然后将 B 的元素放入 C 数组
  if (index !== -1) {
    value[i].uniqueid = id;
    C.push(value[i]);
  }
}
return C 
}
export function changetoarr(value){
  let valen = value.length,arr=[]
  for(let i=0;i<valen;i++){
    let arr2=[]
    arr2.push(value[i].uniqueid)
    arr2.push(value[i].name)
    arr2.push(value[i].dw)
    arr2.push(value[i].task)
    arr2.push(value[i].meeting)
    arr2.push(value[i].ting)
    arr2.push(value[i].weight)
    arr2.push(value[i].meetingid)
    arr.push(arr2)
  }
return arr
}
export function pptfun(value){
  let valen = value.length,arr=[]
  for(let i=0;i<valen;i++){
    let obj={  name: "",text:''}
    obj.name=value[i].name
    obj.text=value[i].text
    arr.push(obj)
  }
return arr
}
export function changetoarr3(value){
  let valen = value.length,arr=[]
  for(let i=0;i<valen;i++){
    let arr2=[]
    // console.log(makeid(6))
    arr2.push(value[i].id)
    arr2.push(value[i].name)
    arr2.push(value[i].dw)
    arr2.push(value[i].meeting)
    arr2.push(value[i].meetingid)
    arr2.push(value[i].phone)
    arr2.push(value[i].globalid)
    arr2.push(value[i].modification1)
    
    arr.push(arr2)
  }
return arr
}

export function changetoarr2(value){
  let meeting = store.state.meeting,meetingid = store.state.meetingid
  let timeInMs = Date.now();
  // console.log(timeInMs,'时间戳')
  let valen = value.length,arr=[]
  for (let i = 0; i < valen; i++) {
    makeid(6) // 不使用 await，而是直接调用 makeid，然后使用 .then() 处理结果
      .then(id => {
        let obj = { id: id, name: "Apple", dw: '单位1',meeting:meeting,meetingid:meetingid,phone:'',globalid:'',modification1:timeInMs };
        obj.name = value[i].姓名;
        obj.dw = value[i].工作单位;
        obj.phone = value[i].手机号码;;
        arr.push(obj);
      })
      .catch(error => {
        console.error('Error:', error); // 处理错误
      });
  }
  return arr
}

export async function tocreateglobalid(value){
        let arr = value
          for(let i=0;i<arr.length;i++){
                let phone = arr[i].phone
                const {data:res} = await axios.get('/users/checkglobalid', {
                    params: { phone: phone }
                    });
                    // console.log(res)
                    if(res.flag==true){
                    // console.log(res)
                      arr[i].globalid = res.data[0].globalid
                    }else{
                     makeglobalid(4).then(globalid=>{
                      arr[i].globalid = globalid
                     })
                    }
            }
            return arr
}

 async function makeglobalid (length) {
  // let timestamp = Date.now().toString();
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  let exists = false;
  do {
    // 检查ID是否存在
    const {data:res} = await axios.get('/users/createglobalid', {
      params: { id: result }
    });
    exists = res.flag; // 假设返回的数据中的flag字段表示ID是否存在
    if (exists) {
      // 如果存在，重新生成ID
      result = '';
      counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
    }
  } while (exists); // 只要存在，就继续生成新的ID

  return result; // 最终返回生成的ID，这个ID在数据库中是唯一的
}

 async function makeid (length) {
  // let timestamp = Date.now().toString();
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  let exists = false;
  do {
    // 检查ID是否存在
    const {data:res} = await axios.get('/users/checkididexist', {
      params: { id: result }
    });
    exists = res.flag; // 假设返回的数据中的flag字段表示ID是否存在
    if (exists) {
      // 如果存在，重新生成ID
      result = '';
      counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
    }
  } while (exists); // 只要存在，就继续生成新的ID

  return result; // 最终返回生成的ID，这个ID在数据库中是唯一的
}
// 转化链接
// http://192.168.1.91:8080/#/inppt?id=
// https://seats.kunbocs.cn/#/inppt?id=
export function changetoarr4(value){
  let valen = value.length,arr=[],status=''
  for(let i=0;i<valen;i++){
    let link='https://resume.kunbocs.cn/fillinput.html?id='
    let obj={id:'', name: "Apple",status:''}
    status = value[i].text == null ? '未收集':'已收集'
   obj.id = link + value[i].id
   obj.name = value[i].name
   obj.status = status
   arr.push(obj)
  }
return arr
}
export function jsfilter(value1, value2) {
  let result = value2.filter(item =>
    (item.name && item.name.includes(value1)) ||
    (item.phone && item.phone.toString().includes(value1))
  )
  .map(item => ({
    name: item.name,
    dw: item.dw,
    id: item.id,
    meeting: item.meeting,
    phone:item.phone,
    text: item.text === '' ? null : item.text,
    globalid: item.globalid,
    num: item.num,
    t1: item.modification1,
    t2: item.modification2
  }));
  return result;
}
export async function  globalid(value){
  const {data:res} = await this.$http.get('/users/checkglobalid', {
              params: { phone: value }
              });
              if(res.flag==true){
              // console.log(res)
               this.person.globalid = res.data[0].globalid
              }else{
               this.makeglobalid(4).then(globalid=>{
               this.person.globalid =  globalid
               })
              }
      // console.log(value)
  }
  function arabicToChinese(number) {
    const chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
    const chineseUnits = ['', '十', '百'];
    let result = '';

    if (number === 0) {
        return chineseNums[0];
    }

    // 转换数字为中文数字
    const convertDigit = (digit) => {
        return chineseNums[digit];
    };

    // 转换整数部分
    const convertIntegerPart = (integerPart) => {
        const digits = integerPart.toString().split('').map(Number);
        let chinese = '';
        for (let i = 0; i < digits.length; i++) {
            const digit = digits[i];
            if (digit !== 0) {
                if (digit === 1 && digits.length === 2 && i === 0) {
                    chinese += chineseUnits[digits.length - i - 1];
                } else {
                    chinese += convertDigit(digit) + chineseUnits[digits.length - i - 1];
                }
            }
        }
        return chinese;
    };

    result += convertIntegerPart(number);

    return '第'+result+'排';
}

  export function ExchangeExport(value){
    let arr = []
    for(let i=0;i<value.length;i++){
      let obj = {name:value[i].name,hang:'',dw:value[i].dw}
      obj.hang = arabicToChinese(value[i].hang)+'第'+value[i].lie+'列',
      arr.push(obj)
    }
    return arr;
  }
// export function jsfilter(value1,value2){
// // return value1,value2
// console.log(value2)
// let result = value2.filter(
//   item=>item.name.includes(value1)||item.dw.includes(value1)
//   )
//   .map(
//     item=>({
//       name:item.name,
//       dw:item.dw,
//       id:item.id,
//       meeting:item.meeting,
//       text:item.text=='' ? null:item.text,
//       globalid:item.globalid,
//       t1:item.modification1,
//       t2:item.modification2}))
// return result
// }
// export async function getpicImage(file){
//   let cos=null
//   const {data:res} = await axios.get('https://seats.kunbocs.cn/getTempKeys')
//   // console.log(res)
//   cos = new COS({
//       getAuthorization: (options, callback) => {
//         callback({
//           TmpSecretId: res.credentials.tmpSecretId,
//           TmpSecretKey: res.credentials.tmpSecretKey,
//           XCosSecurityToken: res.credentials.sessionToken,
//           ExpiredTime: res.expiredTime,
//         });
//       },
//     });
//     let key = "/art/" + file.file.name;
//     // console.log(key,'key')
//     cos.putObject({
//       Bucket: "file-1257652773", // 存储桶名称
//         Region: "ap-guangzhou", // 存储桶所在地域，必须字段
//         Key: key, // 图片名称
//         StorageClass: "STANDARD",
//         Body: file.file, // 上传文件对象
//         onHashProgress: (progressData) => {
//           console.log("校验中", JSON.stringify(progressData));
//         },
//         onProgress: (progressData) => {
//           const percent = parseInt(progressData.percent * 10000) / 100;
//           const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
//           console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
//         },
//     },(err,data)=>{
//       console.log(data,'=-=')
//       if (data && data.statusCode == 200) {
//           // 如果上传成功，拼接图片链接地址，并赋值给 imageUrl
//           let fileUrl =data.Location
//          return fileUrl;
//           // console.log(fileUrl);
//         } else {
//           console.log(err,'1');
//           // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户上传失败
//         }
//     }).catch((err) => {
//     console.log(err,'2');
//     // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户获取密钥失败
//   });
// }