<!-- 彻底删除界面 -->\
<template>
  <div class="Completelydelete">
    <el-radio-group v-model="radio">
    <el-radio v-for="(item,index) in list" :label="item.id" :key="index">{{item.meeting}}</el-radio>
  </el-radio-group>
  &nbsp;
  &nbsp;
  &nbsp;
  &nbsp;
  <el-button type="danger" size="small" @click="del">
    删除
  </el-button>
  <!-- {{ radio }} -->
  </div>
</template>

<script>
export default {
    data(){
        return{
            list:[],
            radio:''
        }
    },
    mounted(){
        this.getlist()
    },
    methods:{
       async del(){
            const {data:res} = await this.$http.delete('/users/delmeetinguser',{
               params:{
                id:this.radio
               } 
            })
            if(res.flag==true){
                this.$message.success(res.msg)
            }
            this.del2()

        },
        async del2(){
            const {data:res} = await this.$http.delete('/users/delbrieuser',{
               params:{
                id:this.radio
               } 
            })
            if(res.flag==true){
                this.$message.success(res.msg)
            }
            this.del3()

        },
        async del3(){
            const {data:res} = await this.$http.delete('/users/delpptmeetinglist',{
               params:{
                id:this.radio
                // id:value
               } 
            })
            if(res.flag==true){
                this.$message.success(res.msg)
            }
            this.getlist()
        },
       async getlist(){
        const {data:res} = await this.$http.get('users/getpptmeetinglist')
            // console.log(res,'][[]]')
            if(res.flag==true){
                this.list = res.data
                this.radio = res.data[0].id
            }
        }
    }
}
</script>

<style>

</style>