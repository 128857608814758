import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    startpai2:1,
    meeting:sessionStorage.getItem('selmeeting'),
    ting:sessionStorage.getItem('selting'),
    meetingid:sessionStorage.getItem("selid"),
    list:[],
    num:sessionStorage.getItem("setnum"),

  },
  getters: {
  },
  mutations: {
    setlist(state,value){
      state.list = value
    },
    changestartpai(state,value){
      state.startpai2 = value
    },
    changemeeting(state,value){
      state.meeting = value
      // console.log(value) 
      sessionStorage.setItem('selmeeting',value)
    },
    changeting(state,value){
      state.ting = value
      sessionStorage.setItem('selting',value)
    },
    changemeetingid(state,value){
      state.meetingid = value
      sessionStorage.setItem('selid',value)
    },
    changenum(state,value){
      state.num = value
      sessionStorage.setItem('setnum',value)

    },
  },
  actions: {
    async Obtainmeetingmaterials({ commit }, meetingid){
      const {data:res} = await axios.get('users/getlinkname',{
        params:{
          'meetingid':meetingid
        }
      })
      if(res.flag==true){
        commit('setlist',res.data)
        // console.log('成功',res.data)
      }else{
        console.log('失败')
      }
    },
    async getnum({ commit }, meetingid){
      // console.log(meetingid)
      const {data:res} = await axios.get('users/Checkforupdates',{
        params:{
          meetingid:meetingid
        }
      })
      // console.log(res)
      if(res.flag==true){
       commit('changenum', res.data[0].length)
      }
    },
  },
  modules: {
  }
})
