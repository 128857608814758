<template>
  <div class="home" :style="stylevar">
    <!-- {{ autocreateseat }} -->
    <el-dialog
      title="设定座位"
      :visible.sync="dialogVisible"
      width="30%"
     >
     <div class="diaclass">
      <el-input :disabled="state==''" style="width:220px;" v-model="allgroup">
        <template slot="prepend">共有</template>
        <template slot="append">组</template>
        </el-input>
      <el-input style="width:220px;" v-for="(item ,index) in grouvalue" :key="'zu'+index"  v-model="item.value">
          <template slot="prepend">{{index+1}}组</template>
        <template slot="append">列</template>
      </el-input>
      <div class="setseat">
      排座方式：
         <el-radio v-model="radio" label="1" :disabled="radio!=null">从左到右排序</el-radio>
        <el-radio v-model="radio" label="2"  :disabled="radio!=null">Z字形排序</el-radio>
      </div>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="dialogVisible=false">确定</el-button>
        <el-button type="danger" @click="Determineseat">重置</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="设定座位"
      :visible.sync="rowseat"
      width="30%"
     >
     <div class="diaclass">
      <div style="display: flex">
      <el-input v-model="startpai">
        <template slot="prepend">第</template>
        <!-- <template slot="append">排</template> -->
      </el-input>
      <el-input v-model="positio">
        <template slot="prepend">排第</template>
        <template slot="append">列开始</template>
      </el-input>
      </div>
      <div class="setseat">
      排座顺序：
         <el-radio v-model="radio" label="1">从左到右排序</el-radio>
        <el-radio v-model="radio" label="2">Z字形排序</el-radio>
      </div>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rowseat = false">取消</el-button>
        <el-button type="success" @click="modifyseat">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="填充名字"
      :visible.sync="fillnamedialog"
      width="30%"
     >
     <div class="diaclass">
      <div style="display: flex">
      <el-input v-model="startpai">
        <template slot="prepend">第</template>
        <!-- <template slot="append">排</template> -->
      </el-input>
      <el-input v-model="positio">
        <template slot="prepend">排第</template>
        <template slot="append">列开始</template>
      </el-input>
      </div>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="fillnamedialog = false">取消</el-button>
        <el-button type="success" @click="FillInName">确定</el-button>
      </span>
    </el-dialog>
     <div class="tan">
      <div class="showSearch" v-if="showSearch">
      <el-input v-model="modifyname">
        <template slot="prepend">姓名</template>
      </el-input>
     <div class="searchdiv">
      <div  v-if="filteredStatusValue.length > 0">
        <span v-for="(item, index) in filteredStatusValue" :key="index" @click="togglespan(item)">
         {{ item.姓名 }}
        </span>
      </div>
      <p v-else> 暂无人员</p>
     </div>
      </div>
     <div v-if="!showSearch">
      <el-button type="danger" @click="removePerson">移除</el-button>
      <el-button type="primary" @click="toggleSearch">换人</el-button>
     </div>
    </div>
  <el-container>
    <el-aside width="10%">
  <div v-for="(item,index) in statusvalue" :key="'mingdanvalue'+index" class="mdanclass">
    <el-tag type="success" v-show="item.分配状态=='未分配'">{{ item.姓名 }}</el-tag>
    <el-tag type="danger" v-show="item.分配状态=='未分配'">{{ item.分配状态 }}</el-tag>
  </div>
    </el-aside>
    <el-main>
    <div class="myseat">
      <div class="goback">
          <goback></goback>
      </div>
      <div>
        <el-button  v-hasrole="{action:'admin',effect:'is-disabled'}"   type="primary" @click="Seatsettings" :disabled="filteredStatusValue.length ==0 && exportList.length !==0 ">座位设定</el-button>
        <el-button  v-hasrole="{action:'admin',effect:'is-disabled'}"   type="success" @click="fillnamedialog=true" :disabled="filteredStatusValue.length ==0">填充姓名</el-button>
        <el-button  v-hasrole="{action:'admin',effect:'is-disabled'}"   type="warning" @click="rowseat=true">重新排序</el-button>
        <el-button  v-hasrole="{action:'admin',effect:'disabled'}"   type="primary" :disabled="state==''" @click="erweima" >生成座位查询二维码</el-button>
        <el-button type="danger"  :disabled="exportList==''" @click="exportExcel">导出名单</el-button>
        <el-button type="danger"  :disabled="exportList==''" @click="exportpic">导出座位图</el-button>
      <el-button type="danger" icon="el-icon-upload2" @click="dtemplate">下载模板</el-button>
      <el-button v-hasrole="{action:'admin',effect:'disabled'}"   type="primary" @click="namemanger">名单管理</el-button>
      <el-button v-hasrole="{action:'admin',effect:'disabled'}"  type="danger" @click="initialization">初始化</el-button>
      </div>
      <!-- v-hasrole="{action:'admin',effect:'is-disabled'}" -->
    <div>
    </div>
   </div>
   <div class="bigseat">
    <div class="seat-chart" ref="imageDom">
      <div class="jiangtai">
      舞台
      </div>
      <div class="seat-chart2">
      <div class="seat-area"  v-for="(area, index) in list" :key="index">
      <div class="seat-row" v-for="(row, i) in area" :key="i" :ref="i">
        <div v-if="index==0" class="hang"> 第 {{ i+1 }}排</div> &nbsp;
        <div class="smallseat" 
        v-for="(seat, j) in row" 
        :key="j" 
        @click="selectSeat($event,{qu:index,hang:i,lie:j})"
        @contextmenu.prevent="handleRightClick($event,{qu:index,hang:i,lie:j})"
        >
          <div  class="smallseatson"  v-if="i==0"> 
            第 {{ index | fil(j,list) }}列 
            </div>
            <!--  :title="'第'+seat.hang+'排,第'+seat.lie+'列'" -->
            <el-tooltip class="item" :content="'第'+seat.hang+'排,第'+seat.lie+'列'" placement="top" :open-delay="opendelay" effect="light">
          <div  :class="['seat',flickerqu == index ? flickerlie  == j ? flickerhang == i ? 'flash':'':'':'']" >
              {{seat.name}}
          </div>
          </el-tooltip>
        </div>
      </div>
    </div>
    </div>
  </div>
  </div>
    <div class="gnclass">
      <el-upload ref="upload" class="upload-demo" 
        action 
        :limit="1"
        :show-file-list="false"
        :http-request="getExcel"
        style="margin-right: 10px;"
        >
          <el-button type="primary" >导入人员</el-button>
      </el-upload>
      <!-- <el-button :disabled="mingdanvalue==''" type="success" @click="test">提交</el-button> -->
      <!-- <el-button :disabled="state==''" type="danger"  @click="modify" >提交</el-button> -->
      
      <el-button  @click="jumptest">座位查询</el-button>
      <el-button type="primary" @click="addseat"  >增加一排</el-button>
      <el-link href="https://ra1pgvsvat.feishu.cn/docx/EnWwd8e03o4GgxxbazccMv9Fnv4"  target="_blank" icon="el-icon-thumb" type="primary">使用方法</el-link>
    </div>
    </el-main>
</el-container>
</div>
</template>

<script>
import XLSX from 'xlsx';
import excel from '@/libs/excel'
import html2canvas from 'html2canvas';
import goback from '../components/goback.vue'
import  {debounce, exchange} from '../libs/utlis'
import  {dispose} from '../libs/utlis'
import { ExchangeExport } from '../libs/utlis';
export default {
  name: 'HomeView',
  data(){
    return{
      opendelay:500,
      fillnamedialog:false,
      startpai:1,
      rowseat:false,
      switchvalue:false,
      options: [],
      currentmeeting:'',
      restaurants:[],
      timeout:null,
      flickerhang:-1,
      flickerqu:-1,
      flickerlie:-1,
      gzdanwei:[],//暂存工作单位
      dizhi:[],//暂存地址
      Staging:[], // 暂存座位姓名
      idgroup:[],// 暂存id编号
      alllie:1,
      allgroup:0,
      grouvalue:[],
      mingdanvalue:[],
      list:[],
      list2:[],
      seatnum:0,
      posleft:0,
      posright:0,
      none:'none',
      selhang:0,
      sellie:0,
      state:'',
      exportList:[],
// 控制是否自动生成座位
      autocreateseat:true,
      hang:'',//右键输入名字单位后的赋值的关键标
      qu:'',//右键输入名字单位后的赋值的关键标
      lie:'',//右键输入名字单位后的赋值的关键标
      modifyname:'',
      modifydw:'',
      modifyadd:'',
      dialogVisible:false,
      next:0,
      diatitle:['选择会议','输入新建厅或查看已建好的厅','设置区域数量','设置每组区域的列数','导入文件'],
      creatmeeting:'',
      addres:'',
      seldate:'',
      meetingdialog:false,
      mbexportList: [{ name: "张三", dw: "广州市坤博会务服务",status:'未分配' }],
      radio:'',
      statusvalue:[],
      showSearch:false,
      listIndex:'',
      seatmingdan:[],
      positio:1
      
    }
  },
  components:{
    goback
  },
  // 自动计算当前的列数，并且显示在列的上方
  filters:{
    fil(value1,value2,value3){
      let mylie=0
      for(let i=0;i<value1;i++){
        mylie = mylie + value3[i][0].length
      }
      mylie = mylie + value2 +1
      return mylie
    }
  },
  watch:{
   
    alllie(){
      let grouplength=this.grouvalue.length,sum=0
        for(let i=0;i<grouplength;i++){
          sum = sum + this.grouvalue[i].value*this.alllie
        }
        this.seatnum=sum
    },
    allgroup(newvalue,oldvalue){
        // this.list=[],
        // this.autocreateseat = true
        // this.alllie = 1
         this.grouvalue=[]
         for(let i=0;i<newvalue;i++){
        this.grouvalue.push({value:''})
      }
    },
    // 监听数组mingdanvalue是否发生变化，如果发生变化的话那么将其值从上到下，在数组list中从左到右赋值，当其值改变的时候，会在起对应的座位中进行改变
    seatmingdan:{
     async handler(newvalue,old){
      // console.log(newvalue,'watch')
      // 判断当前是否有位置
        if(this.list !=''){
        // 判断位置是否足够，位置不足的会回进入while循环
          while(newvalue.length>this.seatnum){
            // 计算当前的位置数量是否大于或等于人数
            let buzu= newvalue.length-this.seatnum
            // 如果位置多于或等于人数，那么就跳出循环
            if(buzu<=0) break;
            // this.$message.error("位置不够，请重新生成位置,还差"+buzu+'个位置')
            // 当位置少于人数的时候，执行添加一排的操作
            await this.addseat()
          }
          this.modify()
        }
         },deep:true
    },
    // 监听每组排数的变化，计算当前位置的总数
    grouvalue:{
      handler(newvalue,old){
        let grouplength=newvalue.length,sum=0
        for(let i=0;i<grouplength;i++){
          sum = sum + newvalue[i].value*this.alllie
        }
        if(this.autocreateseat){
          this.generate()
        }
        // 汇总当前所有的位置数量
        // console.log(newvalue,this.seatnum,this.list,'loijng')
        this.seatnum=sum
      },deep:true
    },
    list:{
      handler(newvalue,old){
        this.fillinexportlist()
      }
    },
    statusvalue:{
      handler(newvalue,old){
        this.tosubmitmingdan()
        // console.log(newvalue)
      }
    }
  },
  mounted() {
    // sessionStorage.setItem('role',"admin,alt")
    // 获取当前的会议姓名,提供输入框查询
      // this.loadAll();
      let meeting = this.$route.query.meeting
      let name = this.$route.query.name
      this.state = name
      this.currentmeeting = meeting
      this.handleSelect(meeting)
      document.addEventListener('keydown', this.handleEscKey);
    },
    beforeDestroy() {
    // 移除键盘事件监听
    document.removeEventListener('keydown', this.handleEscKey);
  },
  computed:{
    stylevar(){
      return{
        '--posleft':this.posleft+'px',
        '--posright':this.posright+'px',
        '--none':this.none,
        '--alllie':this.alllie,
      }
    },
    filteredStatusValue() {
      const term = this.modifyname.trim();
      // 当搜索词为空时返回空数组，避免显示所有结果
      if(this.statusvalue!=null && this.statusvalue !=''){
        if (!term) {
       // 筛选分配状态为 "未分配"的对象
      return this.statusvalue
        .map((item, index) => ({ ...item, index })) // 添加索引
        .filter(item => item.分配状态 === '未分配');
      }
      // 筛选分配状态为 "未分配" 且姓名包含搜索词的对象
      return this.statusvalue
        .map((item, index) => ({ ...item, index })) // 添加索引
        .filter(item => item.分配状态 === '未分配' && item.姓名.includes(term));
      }else{
        return []
      }
    }
  },
  methods:{
 // 初始化功能
    async  initialization(){
      const confirm = await this.$confirm("是否确定初始化？",{
      type:'warning',
      title:'提示',
      confirmButtonText:'确定',
      cancelButtonText:"取消",
      confirmButtonDirective: ['hasrole', '123','effect','is-disabled'] // 表示只有admin角色才能看到或点击确定按钮
    }).catch(err=>{})
    if(confirm == 'confirm'){
      const {data:res} = await this.$http.put('users/initialization',{
        meeting:this.currentmeeting,
        ting:this.state
      })
      // this.radio = null
      if(res.flag==true){
        // this.autocreateseat = true
        this.$notify({
        title: '初始化成功',
          message: '',
          type: 'success'
      })
        this.handleSelect()
      }
    }
      },
   async Determineseat(){
      this.dialogVisible = false
     await this.generate()
     await this.modify()
    },
    Seatsettings(){
      this.dialogVisible = true
      this.autocreateseat=true
    },
    //判断行数
    judgerow(){
      let  listlength =this.list2 ==null ? 0:this.list2.length, hang=this.list2 ==null ? 0:this.list2[0].length
      for(let h=0;h<hang;h++){
                for(let j=0;j<listlength;j++){
                  let arr = this.list2[j][h]
                  for(let k=0;k<arr.length;k++){
                    let obj = this.list2[j][h][k]
                    if(obj.name !=''){
                       return h
                    }
                  }
                  }
                }
    },
    //将数据填充到导出数组中
    fillinexportlist(){
      this.exportList=[] 
      let startpai=this.judgerow(),LoopLine=0
      // console.log(startpai)
        let  listlength =this.list2 ==null ? 0:this.list2.length, hang=this.list2 ==null ? 0:this.list2[0].length
        // 判断当前的座位有多少行,优先执行行,
        // 先执行第一行,再执行第二行,再到第三行,依次往下
        if(this.radio == '1'){
           for(let h=0;h<hang;h++){
                for(let j=0;j<listlength;j++){
                  let arr = this.list2[j][h]
                  for(let k=0;k<arr.length;k++){
                    let obj = this.list2[j][h][k]
                    if(obj.name !=''){
                       this.exportList.push(obj)
                    }
                  }
                  }
                }
        }else if(this.radio == '2'){
          for(let h=startpai;h<hang;h++){
                   //forh实现提供判断当前行数是奇数还是偶数
                   LoopLine = LoopLine+1
            // zushu提供当前的组数
            let forh= h+1,zushu = listlength
              for(let j=0;j<listlength;j++){
                // 每循环一次,组数往前减一
                 zushu = zushu - 1
                //  判断当前行数是奇数还是偶数,决定组数的便利顺序是从左到右还是从右到左
                let jshu =LoopLine %2 == 0 ? zushu:j
                // 获取当前组的数据
                let arr = this.list2[jshu][h]
                // 获取当前组的列数
                let sum = arr.length
                for(let k=0;k<arr.length;k++){
                    // 没循环一次，列数往前减一
                    sum = sum - 1
                    // 判断当前是奇数行还是偶数行，决定姓名的遍历顺序是从左到右还是从右到左
                    let xiabiao =LoopLine % 2==0 ? sum:k
                    // replace的作用是去除左右空格，只保留汉字
                   let obj = this.list2[jshu][h][xiabiao]
                   if(obj.name!=''){
                    this.exportList.push(obj)
                      }
                    }
                  }
                }
        }
        this.Assignmentstatus()
      //  console.log(this.exportList,'[p[p]]', this.statusvalue)
        // console.log('fillinexportlist被调用')
    },
  // 判断分配的状态
  Assignmentstatus() {
  // 遍历 statusvalue 并更新分配状态
  if(this.statusvalue!=null){
    this.statusvalue.forEach(item1 => {
    // 检查 exportList 中是否有匹配的姓名和单位
    const isAssigned = this.exportList.some(item2 => item2.name === item1.姓名 && item2.dw === item1.工作单位 && item2.id===item1.id);
    // 如果有匹配的，更新分配状态
    if (isAssigned) {
      this.$set(item1, '分配状态', '已分配'); // 使用 Vue 的 $set 方法确保响应性
    }else{
      this.$set(item1, '分配状态', '未分配'); // 使用 Vue 的 $set 方法确保响应性
    }
  });
  }
  // 清空开始排座前的座位
 
  // 打印更新后的 statusvalue
  // console.log(this.statusvalue);
},
    clearThefrontseat(){
      let startpai = this.startpai-1
      for (let h = 0; h < startpai; h++) {
      for (let j = 0; j < this.list.length; j++) {
        for (let k = 0; k < this.list[j][h].length; k++) {
          this.list[j][h][k].name = '';
          this.list[j][h][k].dw = '';
          this.list[j][h][k].id = '';
          this.list[j][h][k].listIndex=''
        }
      }
    }
    },
    // 手动填充姓名
   async FillInName(){
    this.switchvalue = false
      if(this.radio == '1'){
      //  await this.clearname()
       await this.convention()
      }else if(this.radio=='2'){
        // await this.clearname()
       await this.SortZ()
      }else{
        this.$message.error('请选择排序顺序')
      }
      this.fillnamedialog=false
      this.modify()
    },
    // 清除姓名
    clearname(){
      let listlength = this.list.length
        for(let i=0;i<listlength;i++){
          let arr = this.list[i]
          for(let j=0;j<arr.length;j++){
            let arr2=arr[j]
            for(let k=0;k<arr2.length;k++){
              this.list[i][j][k].name=''
              this.list[i][j][k].dw=''
            }
          }
      }
    },
// 常规填充姓名。从左到右
convention() {
  let startpai = this.startpai-1,positionnum=0
  let mingdanIndex = 0; // 使用mingdanIndex替代mingdanl更符合命名习惯
  let overname = this.switchvalue;
  let newvalue = this.mingdanvalue.length === 0 ? this.statusvalue : this.mingdanvalue;
  // console.log(newvalue)
  let isNameAssigned = false; // 新增一个变量用于标识名字是否已被分配
  // 循环遍历座位
  for (let h = startpai; h < this.list[0].length; h++) {
    for (let j = 0; j < this.list.length; j++) {
      for (let k = 0; k < this.list[j][h].length; k++) {
        positionnum = positionnum + 1
        // 如果名单上还有名字，且允许覆盖或者座位上名字为空，则填充名字
        // overname || this.list[j][h][k].name === ""
        // mingdanIndex < newvalue.length
        while(mingdanIndex < newvalue.length && newvalue[mingdanIndex].分配状态 == '已分配') {
            mingdanIndex++;
            // console.log(mingdanIndex)
          }
        if (mingdanIndex < newvalue.length) {
          let currentPerson = newvalue[mingdanIndex];
          // console.log(currentPerson.id)
          let nameKey = currentPerson.姓名 ? '姓名' : 'name';
          let dwKey = currentPerson.工作单位 ? '工作单位' : 'dw'
            if(this.list[j][h][k].name === "" && positionnum >=this.positio){
              this.list[j][h][k].name = currentPerson[nameKey].trim();
              this.list[j][h][k].dw = currentPerson[dwKey];
              this.list[j][h][k].id = currentPerson.id;
              this.list[j][h][k].listIndex = mingdanIndex;
              mingdanIndex++; // 移动到名单的下一个人
              isNameAssigned = true; // 标记为已分配名字
            // }
          }
        } 
      }
    }
  }

},
// 常规填充姓名。从左到右
modifyconvention() {
  let startpai = this.startpai-1,positionnum=0
  let mingdanIndex = 0; // 使用mingdanIndex替代mingdanl更符合命名习惯
  let overname = this.switchvalue;
  let newvalue = this.exportList
  let isNameAssigned = false; // 新增一个变量用于标识名字是否已被分配
  // console.log(newvalue)
  // 循环遍历座位
  for (let h = startpai; h < this.list[0].length; h++) {
    for (let j = 0; j < this.list.length; j++) {
      for (let k = 0; k < this.list[j][h].length; k++) {
        positionnum = positionnum + 1
        // 如果名单上还有名字，且允许覆盖或者座位上名字为空，则填充名字
        // overname || this.list[j][h][k].name === ""
        // mingdanIndex < newvalue.length
        if (mingdanIndex < newvalue.length) {
          let currentPerson = newvalue[mingdanIndex];
          let nameKey = currentPerson.姓名 ? '姓名' : 'name';
          let dwKey = currentPerson.工作单位 ? '工作单位' : 'dw'
          // console.log(positionnum,this.positio,'我的测试')

          if(positionnum >=this.positio){
            if(overname){
            this.list[j][h][k].name = currentPerson[nameKey].trim();
            this.list[j][h][k].dw = currentPerson[dwKey];
            this.list[j][h][k].listIndex = mingdanIndex;
            this.list[j][h][k].id =  currentPerson.id;
            
            mingdanIndex++; // 移动到名单的下一个人
            isNameAssigned = true; // 标记为已分配名字
          }else{
            if(this.list[j][h][k].name === ""){
              this.list[j][h][k].name = currentPerson[nameKey].trim();
              this.list[j][h][k].dw = currentPerson[dwKey];
              this.list[j][h][k].listIndex = mingdanIndex;
              this.list[j][h][k].id = currentPerson.id;
              mingdanIndex++; // 移动到名单的下一个人
              isNameAssigned = true; // 标记为已分配名字
            }
          }
          }else{
            this.list[j][h][k].name = '';
          this.list[j][h][k].dw = '';
          this.list[j][h][k].listIndex=''
          }
          
        } 
        else if (!isNameAssigned) {
          // 如果当前座位未分配名字，则清空
          this.list[j][h][k].name = '';
          this.list[j][h][k].dw = '';
          this.list[j][h][k].listIndex=''
        }
        // 重置标记为下一个座位准备
        isNameAssigned = false;
      }
    }
  }

  // 重置剩余的座位
  // 如果名单已经全部分配完但座位还有剩余，则将剩余的座位清空
  if (mingdanIndex >= newvalue.length) {
    for (let h = 0; h < this.list[0].length; h++) {
      for (let j = mingdanIndex; j < this.list.length; j++) {
        for (let k = 0; k < this.list[j][h].length; k++) {
          this.list[j][h][k].name = '';
          this.list[j][h][k].dw = '';
          this.list[j][h][k].listIndex=''
        }
      }
    }
  }
  this.clearThefrontseat()
},
    // 常规填充姓名。从左到右
    // convention(){
    //   let listlength = this.list.length,mingdanl=0,hang=this.list[0].length
    //   let overname = this.switchvalue
    //   // let newvalue = this.mingdanvalue
    //   let newvalue = this.mingdanvalue.length == 0 ? this.exportList:this.mingdanvalue
    //    //循环填充姓名到位置里
    //          for(let h=0;h<hang;h++){
    //             for(let j=0;j<listlength;j++){
    //               let arr = this.list[j][h]
    //               for(let k=0;k<arr.length;k++){
    //                 if(newvalue[mingdanl] &&newvalue[mingdanl].姓名){
    //                   let endname = newvalue[mingdanl].姓名
    //                   // replace的作用是去除左右空格，只保留汉字
    //                   if(overname){
    //                      this.list[j][h][k].name=endname.replace(/^\s*|\s*$/g,"")
    //                   this.list[j][h][k].dw = newvalue[mingdanl].工作单位
    //                 // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                   mingdanl = mingdanl+1
    //                   }else{
    //                     if(this.list[j][h][k].name ==""){
    //                     this.list[j][h][k].name=endname.replace(/^\s*|\s*$/g,"")
    //                   this.list[j][h][k].dw = newvalue[mingdanl].工作单位
    //                   // this.list[j][h][k].add = newvalue[mingdanl].市
    //                   // 填充行数
    //                 // this.list[j][h][k].hang=h + 1
    //                 // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                   mingdanl = mingdanl+1
    //                   }
                       
    //                   }
                      
    //                 }else if(newvalue[mingdanl] &&newvalue[mingdanl].name){
    //                   let endname = newvalue[mingdanl].name
    //                   if(overname){
    //                      this.list[j][h][k].name=endname.replace(/^\s*|\s*$/g,"")
    //                   this.list[j][h][k].dw = newvalue[mingdanl].工作单位
    //                 // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                   mingdanl = mingdanl+1
    //                   }else{
    //                     if(this.list[j][h][k].name ==""){
    //                     this.list[j][h][k].name=endname.replace(/^\s*|\s*$/g,"")
    //                   this.list[j][h][k].dw = newvalue[mingdanl].工作单位
    //                   // this.list[j][h][k].add = newvalue[mingdanl].市
    //                   // 填充行数
    //                 // this.list[j][h][k].hang=h + 1
    //                 // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                   mingdanl = mingdanl+1
    //                   }
                       
    //                   }
    //                   // replace的作用是去除左右空格，只保留汉字
    //                 }else{
    //                   this.list[j][h][k].name=''
    //                   this.list[j][h][k].dw = ''
    //                 }
    //               }
    //               }
    //             }
    // },
    // Z字形排序
    SortZ() {
  let startpai = this.startpai-1,LoopLine=0,positionnum=0
  let mingdanIndex = 0;
  let newvalue = this.mingdanvalue.length === 0 ? this.statusvalue : this.mingdanvalue;
  let overname = this.switchvalue;
  // 遍历每一行
  for (let h = startpai; h < this.list[0].length; h++) {
    LoopLine = LoopLine+1
    // let isEvenRow = (h + 1) % 2 === 0; // 判断行的奇偶=
    let isEvenRow = LoopLine % 2 === 0; // 判断行的奇偶=
    // 遍历每一组
    for (let j = 0; j < this.list.length; j++) {
      let groupIndex = isEvenRow ? this.list.length - 1 - j : j; // 确定组索引
      let arr = this.list[groupIndex][h]; // 当前行的当前组
      // 遍历每一列
      for (let k = 0; k < arr.length; k++) {
        positionnum=positionnum+1
        let seatIndex = isEvenRow ? arr.length - 1 - k : k; // 确定列索引
        while(mingdanIndex < newvalue.length && newvalue[mingdanIndex].分配状态 == '已分配') {
            mingdanIndex++;
          }
        if (mingdanIndex < newvalue.length) {
          let currentName = newvalue[mingdanIndex].姓名 || newvalue[mingdanIndex].name;
          let currentDw = newvalue[mingdanIndex].工作单位 || newvalue[mingdanIndex].dw
          let id = newvalue[mingdanIndex].id
          if(positionnum>=this.positio){
             if (overname || this.list[groupIndex][h][seatIndex].name === '') {
            this.list[groupIndex][h][seatIndex].name = currentName.trim();
            this.list[groupIndex][h][seatIndex].dw = currentDw;
            this.list[groupIndex][h][seatIndex].id = id;
            this.list[groupIndex][h][seatIndex].listIndex = mingdanIndex;
            mingdanIndex++;
          }else{
            if(this.list[groupIndex][h][seatIndex].name === ''){
              this.list[groupIndex][h][seatIndex].name = currentName.trim();
              this.list[groupIndex][h][seatIndex].dw = currentDw;
              this.list[groupIndex][h][seatIndex].id = id;
              this.list[groupIndex][h][seatIndex].listIndex = mingdanIndex;
              mingdanIndex++;
            }
          }
          }else{
            this.list[groupIndex][h][seatIndex].name = '';
            this.list[groupIndex][h][seatIndex].dw = '';
            this.list[groupIndex][h][seatIndex].listIndex = '';
          }
         
        } 
      }
    }
  }
},
modifySortZ() {
  let startpai = this.startpai-1,LoopLine=0,positionnum=0
  let mingdanIndex = 0;
  let newvalue = this.exportList
  let overname = this.switchvalue;
  // 遍历每一行
  for (let h = startpai; h < this.list[0].length; h++) {
    LoopLine=LoopLine+1
    // let isEvenRow = (h + 1) % 2 === 0; // 判断行的奇偶=
    let isEvenRow = LoopLine % 2 === 0; // 判断行的奇偶=
    // 遍历每一组
    for (let j = 0; j < this.list.length; j++) {
      let groupIndex = isEvenRow ? this.list.length - 1 - j : j; // 确定组索引
      let arr = this.list[groupIndex][h]; // 当前行的当前组
      // 遍历每一列
      for (let k = 0; k < arr.length; k++) {
        positionnum=positionnum+1
        let seatIndex = isEvenRow ? arr.length - 1 - k : k; // 确定列索引
        if (mingdanIndex < newvalue.length) {
          let currentName = newvalue[mingdanIndex].姓名 || newvalue[mingdanIndex].name;
          let currentDw = newvalue[mingdanIndex].工作单位 || newvalue[mingdanIndex].dw
          let id = newvalue[mingdanIndex].id
          if(positionnum >= this.positio){
            if (overname || this.list[groupIndex][h][seatIndex].name === '') {
            this.list[groupIndex][h][seatIndex].name = currentName.trim();
            this.list[groupIndex][h][seatIndex].dw = currentDw;
            this.list[groupIndex][h][seatIndex].listIndex = mingdanIndex;
            this.list[groupIndex][h][seatIndex].id = id;
            mingdanIndex++;
          }else{
            if(this.list[groupIndex][h][seatIndex].name === ''){
              this.list[groupIndex][h][seatIndex].name = currentName.trim();
              this.list[groupIndex][h][seatIndex].dw = currentDw;
              this.list[groupIndex][h][seatIndex].listIndex = mingdanIndex;
              this.list[groupIndex][h][seatIndex].id = id;
              mingdanIndex++;
            }
          }
          }else{
            this.list[groupIndex][h][seatIndex].name = '';
            this.list[groupIndex][h][seatIndex].dw = '';
            this.list[groupIndex][h][seatIndex].listIndex = '';
          }
          
        } else {
          // 如果名单用尽，清空剩余的座位
          this.list[groupIndex][h][seatIndex].name = '';
          this.list[groupIndex][h][seatIndex].dw = '';
          this.list[groupIndex][h][seatIndex].listIndex = '';
        }
      }
    }
  }
  this.clearThefrontseat()
},
    // SortZ(){
    //   let listlength = this.list.length,mingdanl=0,hang=this.list[0].length
    //   let newvalue = this.mingdanvalue.length == 0 ? this.exportList:this.mingdanvalue
    //   let overname = this.switchvalue
    //   for(let h=0;h<hang;h++){
    //         let forh= h+1,//forh实现提供判断当前行数是奇数还是偶数
    //         zushu = listlength // zushu提供当前的组数
    //           for(let j=0;j<listlength;j++){
    //              zushu = zushu - 1 // 每循环一次,组数往前减一
    //             let jshu = forh %2 == 0 ? zushu:j//  判断当前行数是奇数还是偶数,决定组数的便利顺序是从左到右还是从右到左
    //             let arr = this.list[jshu][h]// 获取当前组的数据
    //             let sum = arr.length// 获取当前组的列数
    //             for(let k=0;k<arr.length;k++){
    //               if(newvalue[mingdanl] &&newvalue[mingdanl].姓名){
    //                 let endname = newvalue[mingdanl].姓名
    //                 sum = sum - 1// 每循环一次，列数往前减一
    //                 let xiabiao =forh % 2==0 ? sum:k // 判断当前是奇数行还是偶数行，决定姓名的遍历顺序是从左到右还是从右到左
    //                 if(overname){
    //                   this.list[jshu][h][xiabiao].name=endname.replace(/^\s*|\s*$/g,"")
    //                 this.list[jshu][h][xiabiao].dw = newvalue[mingdanl].工作单位
    //               // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                 mingdanl = mingdanl+1
    //                 }else{
    //                   if(this.list[jshu][h][xiabiao].name==''){
    //                 this.list[jshu][h][xiabiao].name=endname.replace(/^\s*|\s*$/g,"")
    //                 this.list[jshu][h][xiabiao].dw = newvalue[mingdanl].工作单位
    //               // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                 mingdanl = mingdanl+1
    //                 }
    //                 }
                    
    //                 // replace的作用是去除左右空格，只保留汉字
                    
    //               }else if(newvalue[mingdanl] &&newvalue[mingdanl].name){
                  
    //                 let endname = newvalue[mingdanl].name
    //                 // 没循环一次，列数往前减一
    //                 sum = sum - 1
    //                 // 判断当前是奇数行还是偶数行，决定姓名的遍历顺序是从左到右还是从右到左
    //                 let xiabiao =forh % 2==0 ? sum:k
    //                 if(overname){
    //                   this.list[jshu][h][xiabiao].name=endname.replace(/^\s*|\s*$/g,"")
    //                 this.list[jshu][h][xiabiao].dw = newvalue[mingdanl].工作单位
    //               // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                 mingdanl = mingdanl+1
    //                 }else{
    //                   if(this.list[jshu][h][xiabiao].name==''){
    //                 this.list[jshu][h][xiabiao].name=endname.replace(/^\s*|\s*$/g,"")
    //                 this.list[jshu][h][xiabiao].dw = newvalue[mingdanl].工作单位
    //               // 用来记住当前循环到的位置,将newvalue的值一一赋值给list
    //                 mingdanl = mingdanl+1
    //                 }
    //                 }
    //                 // replace的作用是去除左右空格，只保留汉字
                    
    //                 }else{
    //                   sum = sum - 1
    //                 // 判断当前是奇数行还是偶数行，决定姓名的遍历顺序是从左到右还是从右到左
    //                 let xiabiao =forh % 2==0 ? sum:k
    //                   this.list[jshu][h][xiabiao].name=''
    //                   this.list[jshu][h][xiabiao].dw = ''
    //                 }
    //             }
    //             }
    //           }
    // },  
    // 往下增加一排座位
    addseat(){
      // 获取组数
      let zulength = this.list.length,paishu = this.list[0].length
      // 循环遍历，在每组的最后面加上一排座位
      for(let i=0;i<zulength;i++){
      // 定义一个数组暂存列数
      let lie = []
      // 获取当前组的列数，并且将座位的格式填充进去
      let lieshu = this.list[i][0].length
      // 循环遍历填充位置
      for(let j=0;j<lieshu;j++){
        let obj={name:'',hang:paishu+1,lie:''}
        // 填充位置
        lie.push(obj)
      }
      // 将排数填充到每组的最后
      this.list[i].push(lie)
      }
      //更新上方的排数显示
      this.alllie =parseInt(this.alllie)+1
      // 将新增的列数填充进去
      this.fillinlie()

      // // 当位置不够的时候，每次点击新增都会触发watch的时间，若位置足够，将名单填充进去位置那里
      // if(this.mingdanvalue[0]){
      //     let tmingdan = this.mingdanvalue[0].姓名
      // this.mingdanvalue[0].姓名=tmingdan
      // }
    },
  //  前往生成二维码的界面
    erweima(){
      this.$router.push({
      name: 'qrcodejs2',
      query: { 
        meeting:this.currentmeeting,
        meetingname: this.state
       }
    })
    },
    jumptest(){
      this.$router.push({
      name: 'checkseat',
      query: { 
        meeting:this.currentmeeting,
        meetingname: this.state
      }
    })
    },
    // 修改
   async modify(){
       const {data:res} = await this.$http.put('users/putseat',{
        "meeting":this.currentmeeting,
        "hang":this.alllie,
        "lie":JSON.stringify(this.grouvalue),
        "zu":this.allgroup,
        "seat": JSON.stringify(this.list),
        "namelist":this.mingdanvalue.length == 0 ? JSON.stringify(this.statusvalue):JSON.stringify(this.mingdanvalue),
        "mettingname":this.state,
        "sort":this.radio
    })
    if(res.flag==true){
      this.$notify({
        title: '修改成功',
          message: '',
          type: 'success'
      })
      this.mingdanvalue=[]
       this.handleSelect()
      }
    
    },
    // 提交名单
    tosubmitmingdan:debounce(async function(){
      try {
    const { data: res } = await this.$http.put('users/tosubmitmingdan', {
      "meeting": this.currentmeeting,
      "mettingname": this.state,
      'value': JSON.stringify(this.statusvalue)
    });

    if (res.flag == true) { // 使用 === 来进行比较
      // this.handleSelect();
    } else {
      // 处理错误的响应
      console.error('An error occurred:', res.msg);
    }
  } catch (error) {
    // 处理请求错误
    console.error('Error making PUT request:', error);
  }
    },2000),
    async tocheckmeet() {
      const { data: res } = await this.$http.get("users/checkmeet", {
        params: {
          name: this.creatmeeting,
        },
      });
      return res.flag;
    },
    // 检查会议是否存在
   async checkmeeting(){
      const {data:res} = await this.$http.get('users/checkmeetingname',{
        params:{
          "meeting":this.currentmeeting,
          "name":this.state
        }
      })
      return res.flag
    },
    // 生成座位的方法
    generate(){
      this.list=[]
      for(let i=0;i<this.grouvalue.length;i++){
        this.list.push([])
        for(let j=0;j<this.alllie;j++){
          this.list[i].push([])
          for(let k=0;k<this.grouvalue[i].value;k++){
            let obj={name:'',hang:j+1,lie:'',dw:'',listIndex:'',id:''}
            this.list[i][j].push(obj)
          }
        }
      }
      this.fillinlie()
    },
  //  填充列数
      fillinlie(){
        for(let i=0;i<this.list.length;i++){
          let arr=this.list[i]
          for(let j=0;j<arr.length;j++){
            let arr2 = arr[j]
            for(let k=0;k<arr2.length;k++){
              let sum = 0
              for(let l=0;l<i;l++){
                sum = sum +this.list[l][j].length
              }
              this.list[i][j][k].lie = sum + k + 1
            }
          }
        }
      },
   async selectSeat(event,value){
      //控制是否交换选中的值
      this.none='none'
      let qu = value.qu,hang = value.hang,lie=value.lie,mylie=0,myhang=0
      // if(this.list)
      this.Staging.push(this.list[qu][hang][lie].name)
      this.gzdanwei.push(this.list[qu][hang][lie].dw)
      this.idgroup.push(this.list[qu][hang][lie].id)
      if(this.Staging.length!=0){
        if(this.Staging.length==1){
          this.flickerqu = value.qu,this.flickerhang = value.hang,this.flickerlie = value.lie
        }else if(this.Staging.length==2){
          const confirm = await this.$confirm('是否确认['+this.Staging[0]+']与['+this.Staging[1]+']互换？',
          {
            title:'提示',
            confirmButtonText:'确定',
            cancelButtonText:'取消',
            type:'warning'
          }
          ).catch(error=>{

          })
          if(confirm=='confirm'){
            let qu1=this.flickerqu,hang1 = this.flickerhang,lie1=this.flickerlie
            this.list[qu][hang][lie].name=this.Staging[0]
            this.list[qu][hang][lie].dw=this.gzdanwei[0]
            this.list[qu][hang][lie].id=this.idgroup[0]
            this.list[qu1][hang1][lie1].name=this.Staging[1]
            this.list[qu1][hang1][lie1].dw=this.gzdanwei[1]
            this.list[qu1][hang1][lie1].id=this.idgroup[1]
            this.flickerqu =-1,this.flickerhang = -1,this.flickerlie = -1
            this.Staging=[]
            this.dizhi=[]
            this.idgroup=[]
            this.gzdanwei=[]
            this.modify()
          }else{
            this.flickerqu =-1,this.flickerhang = -1,this.flickerlie = -1
            // this.flickerqu =-1,this.flickerhang = -1,this.flickerlie = -1
            this.Staging=[]
            this.dizhi=[]
            this.idgroup=[]
            this.gzdanwei=[]
            this.$notify({
        title: '已取消',
          message: '',
          type: 'warning'
      })
          }
        
      }
      }
     
    },
   // 导入文件
getExcel(file) {
  this.dialogVisible=false
 const types = file.file.name.split(".")[1]; //获取文件后缀
 const fileType = ["xlsx","xls"].some(
   item => item === types
 );
 if (!fileType) {
   this.$message("格式错误！请重新选择");
   return;
 }
 this.analysis(file).then(tableJson => {
   if (tableJson && tableJson.length > 0) {
     //成功解析出数据
     let dataExcel = tableJson[0];
     let getvalue=JSON.stringify(dataExcel.sheet)
     this.seatmingdan= JSON.parse(getvalue)
     this.mingdanvalue =dispose(JSON.parse(getvalue))
     this.$refs.upload.clearFiles();
   }
 });
},
//解析excel   
analysis(file) {
 return new Promise(function (resolve, reject) {
   const reader = new FileReader();
   reader.onload = function (e) {
     const data = e.target.result;
     let datajson = XLSX.read(data, {
       type: "binary"
     });
     const result = [];
     datajson.SheetNames.forEach(sheetName => {
       result.push({
         sheetName: sheetName,
         sheet: XLSX.utils.sheet_to_json(datajson.Sheets[sheetName])
       });
     });
     resolve(result);
   };
   reader.readAsBinaryString(file.file);
 });
},
async loadAll(value) {
      const {data:res} = await this.$http.get('users/getmeetingname',{
        params:{
          "name":value
        }
      })
      if(res.flag==true){
        for(let i=0;i<res.data.length;i++){
          let obj={"value":''}
          obj.value=res.data[i].meetingname
          this.restaurants.push(obj)
        }
      }
      },
      querySearchAsync(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },
      createStateFilter(queryString) {
        return (state) => {
          return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
     async handleSelect() {
      this.dialogVisible=false
        const {data:res} = await this.$http.get('users/getseat',{
          params:{
            "meeting":this.currentmeeting,
            "name":this.state
          }
        })
        if(res.flag==true){
          // console.log('看看家家户户广告费')
          // console.log(JSON.parse(res.data[0].namelist))
          //  console.log(res.data[0].seat)
          this.autocreateseat = false
        
          this.allgroup = res.data[0.].zu == null ? 0 : res.data[0.].zu
          this.alllie = res.data[0].hang == null ? 1:res.data[0.].hang
          this.radio = res.data[0].sortOrder
          this.statusvalue = JSON.parse(res.data[0].namelist)
          // this.grouvalue = JSON.parse(res.data[0].lie)
          this.list = JSON.parse(res.data[0].seat)
          this.list2 = JSON.parse(res.data[0].seat)
          let resgrouvalue = JSON.parse(res.data[0].lie)
          setTimeout(() => {
            let grouvaluelength = this.grouvalue.length
            for(let i=0;i<grouvaluelength;i++){
                let obj =resgrouvalue[i]
                for(let key in obj){
                  this.grouvalue[i][key] = obj[key]
                }
            }
            this.fillinexportlist()
            // 这里是你想要延迟执行的操作
            // console.log('延迟0.5秒后执行的操作');
          }, 200); // 延迟时间为0.5秒（500毫秒）
          
        }else{
          this.$message.error(res.msg)
        }
      },
    async checkmeet(){
      const {data:res} = await this.$http.get('users/getseat',{
          params:{
            name:this.state
          }
        })
        if(res.flag==true){
          this.allgroup = res.data[0.].zu
          this.alllie = res.data[0.].hang
          this.grouvalue = JSON.parse(res.data[0].lie)
          this.list = JSON.parse(res.data[0].seat)
          this.$message.success(res.msg)

        }else{
          this.$message.error(res.msg)
        }
     },
      // 页面上有个按钮 点击调用exportExcel
      exportExcel () {
       let arr= ExchangeExport(this.exportList)
            const params = {
              title: ['姓名', '位置','工作单位'], 
              key: ['name', 'hang','dw'],
              data: arr, // 数据源
              autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
              filename: this.state.concat('的座位编排')           
            }
            excel.exportArrayToExcel(params)
        },
        // 导出图片
       async exportpic(){
          const element = this.$refs.imageDom;
          // 将节点内容转换为 canvas
         await html2canvas(element,{
            scale:2,
            useCORS: true, // 支持图片跨域
            // backgroundColor: null, // 设置背景为透明色
            height: element.scrollHeight, // 设置 canvas 的高度为元素的实际高度
            windowHeight: element.scrollHeight, // 设置窗口的高度为元素的实际高度
            width:element.scrollWidth,
            windowWidth:element.scrollWidth,
          }).then((canvas) => {
            // 将 canvas 转换为图片
            const imgUrl = canvas.toDataURL('image/png');
            // 创建一个可下载链接并触发下载
            const link = document.createElement('a');
            link.href = imgUrl;
            link.download = this.state.concat('.png');
            link.click();
          });
        },
        handleRightClick(event,value){
          let qu = value.qu,hang=value.hang,lie=value.lie,listIndex=value.listIndex,mylie=0,myhang=0
          this.hang=value.hang ,this.lie=value.lie,this.qu=value.qu,this.listIndex = value.listIndex
           this.posleft=event.clientX
            this.posright=event.clientY 
       // 获取到对应的列数
       for(let i = 0;i<qu;i++){
        mylie = mylie + this.list[i][0].length
      }
      mylie = mylie + lie + 1, myhang = hang+1
      this.selhang = myhang,this.sellie=mylie
      this.modifyname=this.list[qu][hang][lie].name
      this.modifydw=this.list[qu][hang][lie].dw
       this.none=''
       },
       submitmodify(){
        let qu = this.qu,hang=this.hang,lie=this.lie
        this.list[qu][hang][lie].name=this.modifyname
        this.list[qu][hang][lie].dw=this.modifydw
        this.none='none'
       },
       tonext(){
        this.next = this.next >4 ? 4 : this.next+1
       },
       tolast(){
        this.next = this.next <=0 ? 0 : this.next-1
       },
       addmeeting(){
        this.meetingdialog = true
       },
       async createConference() {
      let aa = await this.tocheckmeet();
      if (this.addres != "" && this.seldate != "" && this.creatmeeting != "") {
        if (!aa) {
          const { data: res } = await this.$http.post(
            "users/createConference",
            {
              name: this.creatmeeting,
              addres: this.addres,
              starttime: this.seldate[0],
              finishtime: this.seldate[1],
              state: "正常",
            }
          );
          if (res.flag == true) {
            this.meetingdialog = false;
            this.addres = this.creatmeeting = "";
            this.seldate = [];

            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        } else {
          this.$message.error("会议已存在");
        }
      } else {
        switch (true) {
          case this.creatmeeting == "":
            this.$message.error("会议名不允许为空");
            break;
          case this.addres == "":
            this.$message.error("地点不允许为空");
            break;
          default:
            this.$message.error("时间不允许为空");
            break;
        }
      }
    },
    dtemplate(){
      const params = {
        title: ["姓名", "工作单位"],
        key: ["name", "dw"],
        data: this.mbexportList, // 数据源
        autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
        filename: "导入名单模版",
      };
      excel.exportArrayToExcel(params);
    },
    toggleSearch() {
      this.showSearch = true;
    },
    // 实现移除人员的逻辑
   async removePerson() {
    let hang = this.hang,qu = this.qu,lie=this.lie
      const confirm = await this.$confirm('是否移除['+this.list[qu][hang][lie].name+']?',{
        type:'warning',
      title:'提示',
      confirmButtonText:'确定',
      cancelButtonText:"取消"
      }).catch(err=>{
          this.$notify({
        title: '提示',
          message: '已取消',
          type: 'warning'
        })
        this.none='none'
      })
      if(confirm=='confirm'){
        this.list[qu][hang][lie].name = ''
        this.list[qu][hang][lie].dw = ''
        this.list[qu][hang][lie].id = ''
        this.none='none'
        this.modify()
      }
    },
 async togglespan(value){
      let hang = this.hang,qu = this.qu,lie=this.lie
      const confirm = await this.$confirm('是否将【'+value.姓名+'】填充到此位置？',{
        title:'提示',
        type:'warning',
        confirmButtonText:'确定',
        cancelButtonText:'取消'
      }).catch(err=>{
        this.$notify({
            title: '已取消',
              message: '',
              type: 'warning'
          })
          this.showSearch = false;
        this.none='none'
      })
      if(confirm=='confirm'){
        this.list[qu][hang][lie].name = value.姓名
        this.list[qu][hang][lie].dw = value.工作单位
        this.list[qu][hang][lie].id = value.id
        this.showSearch = false;
        this.none='none'
        this.modify()
      }
     
    },
    handleEscKey(event) {
      // 检查是否按下了 Esc 键
      if (event.keyCode === 27) {
        this.showSearch = false;
      this.none='none'
        // console.log('Esc key pressed!');
        // 在这里执行你想要的操作
      }
    },
   async modifyseat(){
    const confirm = await this.$confirm("是否确定更改排座顺序",{
      type:'warning',
      title:'提示',
      confirmButtonText:'确定',
      cancelButtonText:"取消"
    }).catch(err=>{})
    if(confirm == 'confirm'){
      this.switchvalue = true
      this.rowseat=false
      if(this.radio=='1'){
       await this.modifyconvention()
      }else if(this.radio=='2'){
       await this.modifySortZ()
      }
      await this.modify()
      this.switchvalue = false
    }
    },
    namemanger(){
      this.$router.push({name:'usermanger',query:{
            meeting:this.currentmeeting,
            name:this.state
           }})
    },
  }
}
</script>
<style scoped>
.el-container{
  width: 100%;
  height: 100%;
}
.el-aside, .el-header{
  border-right: 1px solid #f3f4f6;
  height: 100%;
}
.el-aside{
 
}
.mdanclass{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  
}
.mdanclass .el-tag{
  flex:1
}
.el-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home{
  position: relative;
  height: 100%;
}
.seat{
  width: fit-content;
}
.colorseat{
  border: 1px solid red;
  height: 20px;
  width: 20px;
}
.bigseat{
  height: 100%;
  width: 100%;
  overflow: auto;
}
.seat-chart {
  display:flex;
  width: fit-content;
  height:fit-content;
  flex-direction: column;
  margin-top:25px;
  margin-bottom: 45px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.seat-chart2{
  display:flex;
  width: fit-content;
  height:fit-content;
  margin-top:15px;
  flex-wrap: nowrap;
}
.jiangtai{
  height: 40px;
  width:95%;
  border:1px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0 5px;
}
.seat-area{
  /* height: fit-content; */
  display:flex;
  flex-direction : column;
  margin-top : 20px;
  margin-right: 20px;
}

.seat-row{
  display:flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}
.seat{
  font-size: 0.8rem;
  font-weight: bold;
  width : 60px;
  height :30px;
  border :1px solid #ccc;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
}
.seat:hover{
  border: 1px solid red;
}
.tan{
  position: absolute;
  left: var(--posleft);
  top: var(--posright);
  display: var(--none);
  height:fit-content;
  width: fit-content;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  background-color: white;
  z-index: 999;
}
.myseat{
  position: relative;
  border: 1px solid #f3f4f6;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}
.myseatdiv{
  display: grid;
  grid-template-columns: repeat(var(--alllie), 65px);
  /* grid-template-rows:repeat(var(--alllie), 35px); */
  overflow: auto;
  width: 100%;
  grid-row-gap: 5px;
}
.ismyseat{
  border: 1px solid gray;
  height: 30px;
  width: 60px;
}
.hang{
  min-width: 100px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.seatnum{
  box-sizing: border-box;
  padding: 5px;
  color: red;
  border-radius: 4px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.gnclass{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
 
}
.container{
  position: absolute;
  right:25%;
  top: -55px;
  height: fit-content;
}
.btncontainer{
  padding: 10px;
  box-sizing: border-box;
  width: fit-content;

}
.smallseat{
  position: relative;
}
.smallseatson{
  position: absolute;
  top: -25px;
}
.goback{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* top: 5px; */
  right: 15px;
}
.tinginput{
  display: flex;
  flex-direction: column;
}
@keyframes flash {
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}
.flash{
  animation: flash 1s infinite;
}
.meetingforalaside{
  margin-top: 3px;
  border-radius: 5px;
  border: 1px solid #ffffff;
  padding: 2px;
  cursor: pointer;
}
.meetingforalaside:hover{
  border-color: red;
}
.meetingvalue{
  width: 100%;
  overflow: auto;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.meetingselect{
  box-sizing: border-box;
  padding: 5px;
 
  border-radius: 4px;
  cursor: pointer;
}
.el-tag {
  font-size: 18px;
}
.createmeeting .el-input {
  margin-bottom: 8px;
}
.setseat{
  margin-top: 15px;
}
.diaclass{
  display: flex;
  flex-direction:column;
  align-items: center;
  /* background-color: antiquewhite; */
}
.diaclass .el-input{
  margin-bottom:8px;
}
.searchdiv{
  height: calc(100% - 40px);
  overflow: auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.searchdiv div{
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
}
.searchdiv div span{
  display: block;
  padding: 2.5px;
  margin-top: 5px;
  cursor: pointer;
}
.showSearch{
  height: 150px;
  width: 200px;
}
.el-tooltip__popper{
  background: rgba(0,0,0,0.65) !important;
}
</style>