<template>
  <div class="meetingface">
    <el-dialog
        title="添加会议&会场"
        :visible.sync="dialogVisible"
        width="30%"
       >
        <el-form ref="meetref" :model="sumbitvalue" label-width="80px" :rules="meetrules">
            <el-form-item label="会议名称" prop="meetname">
                <el-input v-model="sumbitvalue.meetname"></el-input>
            </el-form-item>
            <el-form-item label="会场名称" prop="huiname">
                <el-input v-model="sumbitvalue.huiname"></el-input>
            </el-form-item>
            <el-form-item label="会议时间" prop="timevalue">
                <el-date-picker
                    v-model="sumbitvalue.timevalue"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="会议地点" prop="addmeet">
                <el-input v-model="sumbitvalue.addmeet"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        </el-dialog>
        <div class="topinput">
            <el-input v-model="meetingvalue" @input="changeValue">
                <template slot="prepend">会议名称</template>
            </el-input>
            &nbsp;
            <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addmh">添加</el-button>
        </div>
        <div class="meeting">
            <div class="forname"  v-for="(item , index) in list" :key="index">
                <div  class="name">
                <div class="title">  {{ item.meeting }}-{{ item.meetingname }}</div>
                <div class="tima"> 会议时间:{{ item.stime }} -{{ item.etime }} </div>
                <div class="addres">会议地点：{{ item.location }}</div>
                </div>
                
                <div class="operation">
                    <li>预览座位图</li>
                    <li  @click="toclick({meeting:item.meeting,ting:item.meetingname,routerIndex:1})">用户管理</li>
                    <li  @click="toclick({meeting:item.meeting,ting:item.meetingname,routerIndex:2})">座位管理</li>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import {debounce} from '../libs/utlis'
export default {
    data(){
        return{
            meetingvalue:'',
            list:[],
            dialogVisible:false,
            sumbitvalue:{
                meetname:'',
                huiname:'',
                timevalue:'',
                addmeet:''
            },
            meetrules:{
                meetname:[{required:true,message:'会议名称不允许为空',trigger:'blur'}],
                huiname:[{required:true,message:'会场名称不允许为空',trigger:'blur'}],
                timevalue:[{required:true,message:'会议时间不允许为空',trigger:'blur'}],
                addmeet:[{required:true,message:'会议地点不允许为空',trigger:'blur'}]
            }
        }
    },
    mounted(){
        this.getcurrentmeetingname()
    },
    methods:{
        confirm(){
            this.$refs.meetref.validate(async v=>{
                if(!v) return
                const {data:res} = await this.$http.post("users/addseat",{
                    seat:this.sumbitvalue
                })
                // console.log(res)
                if(res.flag==true){
                    this.dialogVisible =false
                    this.getcurrentmeetingname()
                }else{
                    console.log(res)
                }
            })
        },
        changeValue:debounce(function(){
            this.getcurrentmeetingname()
        },1000),
        toclick(value){
            let routerarr = ['','usermanger','home']
           this.$router.push({name:routerarr[value.routerIndex],query:{
            meeting:value.meeting,
            name:value.ting
           }})
        },
            // 查询当前的会议名称
      async  getcurrentmeetingname(){
        const {data:res} = await this.$http.get('users/allmeet',{
          params:{
            "name":this.meetingvalue
          }
        })
        console.log(res)
        sessionStorage.setItem("role",res.auth.role)
        this.list = res.data
        },
        addmh(){
            this.dialogVisible = true
        }
    }
}
</script>

<style scoped>
.meetingface{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    padding-top: 10px;
}
.topinput{
    display: flex;

}
.meeting{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
   max-height: calc(100% - 70px);
    width: 100%;
    /* border: 1px solid black; */
    overflow: auto;
    flex-wrap: wrap;
    padding:0px 10px;
    margin-top: 15px;
}
.forname{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #f3f4f6;
    box-sizing: border-box;
    width:360px;
    height: fit-content;
     border-radius: 4px;
     margin-left: 12px;
     font-size: 13px;
     margin-bottom: 15px;
}
.name{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid #f3f4f6;
    box-sizing: border-box;
    width:100%;
    height: fit-content;
    padding: 20px 10px 0px 10px;
     border-radius: 4px;
     font-size: 13px;
}
.title{
    font-size: 25px;
    color: #77787c;
}
.tima{
    margin-top: 10px;
    margin-bottom: 5px;
}
.addres{
    margin-bottom: 15px;
}
.el-tag{
    margin-right: 5px;
    margin-top: 10px;
    font-size:18px;
}
.operation{
    border: 1px solid #f3f4f6;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #4389f9;
    box-sizing: border-box;
    padding: 10px 5px;
}
li{
    cursor: pointer !important;
}
</style>