<template>
  <div class="meetingperson" >
    <div class="importdiv" >
        <div class="tip">
            <el-button size="small" type="primary" @click="exportTemplate">新增人员模版</el-button>
        </div>
        <el-upload
                ref="upload" 
                class="upload-demo"
                drag
                action
                :limit="1"
                :show-file-list="false"
                :http-request="getExcel"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
            </el-upload>
            <!-- <el-button type="success"  @click="tosava">提交到数据库</el-button> -->
    </div>
    <div class="flex2">
        <div class="tip2">预览界面,确认无误后点击右边<i style="color: #67c23a;">绿色按钮</i>上传到数据库 &nbsp;
            <el-button type="success"  @click="tosava" size="small" :disabled="!isover">提交到数据库</el-button>
            &nbsp;
            &nbsp;
            <el-button @click="mygoback" size="small" type="danger">返回</el-button>
        </div>
        <div class="title">
            <ul class="titlelist">
                <li>
                    <div class="listflex1 ">
                       序号
                    </div>
                    <div class="listflex1 ">
                       姓名
                    </div>
                    <div class="listflex1 ">
                       电话号码
                    </div>
                    <div class=" listflex2 ">
                       单位
                    </div>
                    <!-- <div  class=" listflex1 ">
                        会议
                    </div> -->
                </li>
            </ul>
          </div>
          <el-result v-show="isupload" icon="success" title="上传成功" subTitle="请根据提示进行操作"> 
            <!-- <template slot="extra">
                <el-button type="primary" size="medium" @click="test">返回</el-button>
            </template> -->
        </el-result>
          <el-statistic
                    ref="statistic"
                    @finish="hilarity"
                    format="HH:mm:ss"
                    :value="deadline"
                    title="距离跳转还剩："
                    time-indices
                    v-show="isupload"
                >
                </el-statistic>
          <el-empty v-show="isNull && !isupload" description="暂无数据"></el-empty>
       <div class="container" v-show="!isNull && !isupload">
        <ul class="list">
                <li
                    v-for="(item, index) in list"
                    :key="index"
                    >
                    <div class="listflex1 ">
                      {{ index+1 }}
                    </div>
                    <div class="listflex1 ">
                      {{ item.name }}
                    </div>
                    <div class="listflex1 ">
                      {{ item.phone }}
                    </div>
                    <div class=" listflex2 ">
                       {{ item.dw }}
                    </div>
                    <!-- <div class="listflex1 ">
                       {{ item.meeting }}
                    </div> -->
                </li>
            </ul>
       </div>
       
    </div>
  </div>
</template>
<script>
    import XLSX from 'xlsx';
    import excel from '@/libs/excel'
    import {changetoarr2} from '../../libs/utlis'
    import {changetoarr3} from '../../libs/utlis'
    import goback from '../goback.vue'
    import {debounce} from '../../libs/utlis'
    import {tocreateglobalid} from '../../libs/utlis'
export default {
    data(){
        return{
            meetingname:'',
            list:"",
            exportList:[{name:'张三',dw:'南方医科大学',phone:'12345678912'}],
            isshow:false,
            count:0,
            fullscreenLoading:false,
            handlelist:[],
            excelList:0,
            schedule:0,//解析进度
            isupload:false,
            isNull:true,
            deadline: 0, // 倒计时的初始值，单位为秒,
            isover:false
        }
    },
    watch:{
        list:{
            handler(newvalue,oldvalue){
                this.count++
            this.isover=false
                this.schedule = Number((this.count/this.excelList).toFixed(1))*100+'%'
                this.fullscreenLoading.setText('已解析'+this.count+'/'+this.excelList+'进度'+this.schedule)
            },deep:true
        },
        count:debounce(function(){
            this.fullscreenLoading.close()
            this.isover=true
            this.checkGlobalid()
        },500)
            },
    mounted(){
        let meeting = this.$store.state.meeting

    },
    components:{
        goback
    },
    methods:{
        mygoback(){
            this.$router.go('-1')
        },
        countdown(){
            // console.log('1')
                        // 创建一个新的Date对象，表示今天的零点半
              let now = new Date();
          // 创建一个新的Date对象，表示三秒后的时间
             let deadline = new Date(now.getTime() + 4000);
            this.deadline =deadline

        },
        hilarity() {
        // this.$notify({
        //   title: "提示",
        //   message: "时间已到",
        //   duration: 0,
        // });
        if(this.isupload){
             this.$router.push('/link')
        }
       
      },
    //    async getvalue(value){
    //         const {data:res} = await this.$http.get('users/getthemeetingvalue',{
    //             params:{
    //                 meeting:value
    //             }
    //         })
    //     },
        exportTemplate(){
            const params = {
        title: ["姓名", "工作单位","手机号码"],
        key: ["name", "dw",'phone'],
        data: this.exportList, // 数据源
        autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
        filename: "新加会议人员导入模版",
      };
      excel.exportArrayToExcel(params);
        },
         // 导入文件
         getExcel(file) {
        this.dialogVisible=false
        const types = file.file.name.split(".")[1]; //获取文件后缀
        const fileType = ["xlsx","xls"].some(
        item => item === types
        );
        if (!fileType) {
        this.$message("格式错误！请重新选择");
        return;
        }
        this.analysis(file).then(tableJson => {
            
        if (tableJson && tableJson.length > 0) {
            //成功解析出数据
            this.isNull = false
            let dataExcel = tableJson[0];
            let getvalue=JSON.stringify(dataExcel.sheet)
            let arr = JSON.parse(getvalue)
            this.excelList = arr.length
            this.list =changetoarr2(JSON.parse(getvalue)) 
            this.$refs.upload.clearFiles();
        }
        })
        },
        //解析excel   
        analysis(file) {
            this.fullscreenLoading = this.$loading({
          lock: true,
          text: '解析中'+ this.schedule,
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            let datajson = XLSX.read(data, {
            type: "binary"
            });
            const result = [];
            datajson.SheetNames.forEach(sheetName => {
            result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(datajson.Sheets[sheetName])
            });
            });
            resolve(result);
        };
        reader.readAsBinaryString(file.file);
        });
        },
       async checkGlobalid(){
        this.fullscreenLoading = this.$loading({
          lock: true,
          text: '处理中',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        let arr = JSON.parse(JSON.stringify(this.list))
        // console.log(tocreateglobalid(arr).then)
        tocreateglobalid(arr).then(res=>{
            this.handlelist = res
            this.fullscreenLoading.close()
        })
        },
      async  tosava(){
            let arr = await changetoarr3(this.handlelist)
            // console.log(arr)
            const confirm = await this.$confirm('是否确认提交？',{
                title:'提示',
                type:'warning',
                confirmButtonClass:"确定",
                cancelButtonClass:"取消"
            }).catch(e=>{

            })
            if(confirm == 'confirm'){
                const {data:res} = await this.$http.put('users/meetperson',{
                personvalue:arr
            })
            if(res.flag==true){
                this.countdown()
                this.$notify({
                    title: '成功',
                    message: res.msg,
                    type: 'success'
                    });
                    this.list=[]
                    this.isupload = true
            }else{
                this.$message.error(res.msg)
            }
            }
        },
        goback(){
            this.$router.push('/PPThomepage')
        },
    }
}
</script>

<style scoped>
.tip{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:fit-content;
    width:  100%;
    display: flex;
}
.meetingperson{
    display: flex;
    box-sizing: border-box;
    height: 100%;
}
.importdiv{
    padding-top:10px ;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
  }
.flex1{
    position: relative;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    border-right: 1px dashed gray;
}
.upload-demo{
   margin-bottom: 15px;
   margin-top: 15px;
}
.flex2{
    padding:0 100px ;
    box-sizing: border-box;
    /* padding-top: 10px; */
    flex: 2;
    height: 100%;
    overflow: auto;
}
.back{
    position: absolute;
    right: 0px;
}
.list {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .list li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #ccc;
    background: #fff;
    /* cursor: move; */
  }
  .listflex1{
    flex: 1;
  }
  .listflex2{
    flex: 2;
  }
  .title{
    box-sizing: border-box;
    padding-right:10px ;
    height: 60px;
    width: 100%;
  }
  .titlelist{
    padding-right: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .titlelist li{
  padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #ccc;
    background: #fff;
}
.container{
    /* border: 1px solid red; */
height: calc(100% - 110px);
width: 100%;
overflow: auto;
}
.border{
    border: 1px solid red;
}
.tip2{
    display: flex;
    align-items: center;
    justify-content: center;
    size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    height: 50px;
}
/* @media screen and (max-width: 355px) {

} */
</style>