<template>
    <div class="addpptcontainer">
        <div class="search">
          <el-input v-model="searchvalue" placeholder="请输入姓名或者单位"></el-input>
        </div>
        <div class="flex2">
            <ul class="list">
                <li
                    v-for="(item, index) in list"
                    :key="index"
                    :class="{ dragging: item.id === draggingId,danger: item.text == null || item.text ==''}"
                    >
                    <div class="listflex1">
                        <strong>姓名:</strong>{{ item.name }}{{ item.id }}
                    </div>
                    <div class=" listflex2">
                        <strong>单位:</strong>{{ item.dw }}
                    </div>
                    <div  class="listflex1">
                        <strong>任务:</strong>{{ item.task }}
                    </div>
                    <div  class=" listflex2">
                        <strong>会议:</strong>{{ item.meeting }}&nbsp;
                        <strong>厅:</strong>{{ item.ting }}
                    </div>
                    <div  class="listflex1">
                        <strong>简介状态:</strong>{{ item.text == null ? '未收集':item.text =='' ? '未收集':'已收集' }}
                    </div>
                    <div class="listflex2">
                        <el-button type="warning">修改</el-button>
                        <el-button type="danger">删除</el-button>
                    </div>
                </li>
            </ul>
        </div>
    </div>
  </template>
  <script>
    import {pptfun} from '../../libs/utlis'
    import {debounce} from '../../libs/utlis'
  export default {
    data() {
      return {
        list: [],
        draggingId: null,
        draggingIndex: null,
        pptxvalue:[],
        pptvalue:[],
        imageUrl:'',
        dialogVisible: false,
        searchvalue:''
      };
    },
    mounted(){
        this.getpptvalue()
    },
    watch:{
      searchvalue: debounce(function (newVal, oldVal) {
    this.search()
    this.getpptvalue()
  }, 600)
    },
    methods: {
      search(){
        
      },
      btnpptname(value){
        console.log(this.list[value])
      },
      createpptx(){
        this.dialogVisible = true
      },
        async getpptvalue(){
        const {data:res} = await this.$http.get('users/getpptvalue',{
          params:{
            value:this.searchvalue
          }
        })
        if(res.flag==true){
            this.list = res.data
            this.pptvalue = pptfun(res.data)
        }else{
            this.$notify.error({
                    title: '错误',
                    message: res.msg
                    });
        }
        },
       async changgweight(value){
        const {data:res} = await this.$http.put('users/changeweight',{
            "weight":value
        })
        if(res.flag==true){
            this.getpptvalue()
            this.$notify({
                    title: '成功',
                    message: res.msg,
                    type: 'success'
                    });
        }else{
            this.$notify.error({
                    title: '错误',
                    message: res.msg
                    });
        }
        },
        handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      getFile (file, fileList) {
  // file.raw是文件对象
  let reader = new FileReader ()
  reader.readAsDataURL (file.raw)
  reader.onload = () => {
    // reader.result是base64字符串
    this.imageUrl = reader.result
    console.log (reader.result,';;')
    // 您可以将reader.result赋值给一个变量，或者传递给后端接口
  }
}
    },
  };
  </script>
  
  <style scoped>
  .addpptcontainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* flex-direction: c; */
  }
  .flex2{
    flex: 3;
    height: 100%;
    overflow: auto;
  }
  .flex1{
    flex: 1;
    /* border-right: 1px solid gray; */
  }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .list li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    box-sizing: border-box;
    height: 60px;
    border-radius: 10px;

    line-height: 40px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    background: #fff;
 
  }
  .dragging {
    opacity: 0.5;
  }
  .listflex1{
    flex: 1;
  }
  .listflex2{
    flex: 2;
  }
  .border{
    border: 1px solid red;
  }
  .danger{
    border-color: red;
    color: red;
  }
  .avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed red;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .search{
    height: 60px;
    width: 100%;
  }
  </style>