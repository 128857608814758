<template>
  <div class="checkseat">
    <div class="meetingname">
        <span  v-show="meetingname!=''"> 会议:</span> &nbsp;
        <span  v-show="meetingname!=''">{{meeting}}</span>
    </div>
    
    <div class="search">
        <el-input placeholder="输入姓名查看座位" v-model="checkname"  @keyup.enter.native="check" >
            <el-button slot="append" icon="el-icon-search" @click="check"></el-button>
        </el-input>
    </div>
    <div class="resdiv" v-show="showresdiv">
        <div class="resdiv2" >
            <div :class="['namediv',item.name==dingweiname ? item.dw==dingweidw ?  'flash':'':'']"  
            v-for="(item,index) in resvalue" :key="index" 
            @click="dingwei({name:item.name,dw:item.dw,id:item.id,lie:item.lie,hang:item.hang })"
            >
                <div  class="myseat"><span style="color: #409eff;" >{{ item.name }}</span></div>
                <div class="myseat"><span  style="color: #72c749;"   class="addtag"  >{{ item.dw }}</span> </div>
                <div class="myseat"><span >第</span><span style="color: #f57272;" class="pai">{{ item.hang }}</span> <span >排</span> </div>
                <div class="myseat"><span >第</span><span style="color: #f57272;" class="pai">{{ item.lie }}</span><span >列</span> </div>  
            </div>
        </div>
    </div>
    <div class="showdiv">

        <div class="show1">
            <pinch-zoom>
            <el-image
                :src="url"
                
            >
            <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
            </div>
            </el-image>
        </pinch-zoom>
        </div>
        <div class="show">
            <div class="bigseat">
            <div class="seat-chart" ref="imageDom">
            <div class="jiangtai">
                {{meetingname}}
            </div>
            <div class="seat-chart2">
            <div class="seat-area"  v-for="(area, index) in list" :key="index">
            <div class="seat-row" v-for="(row, i) in area" :key="i" :ref="i">
                <div v-if="index==0" :class="['hang',currenthang-1 == i ? 'isselect':'']"> 第 {{ i+1 }}排</div> &nbsp;
                <div class="smallseat" 
                v-for="(seat, j) in row" 
                :key="j" 
                >
                <!-- v-if="i==0" -->
                <div :class="{'smallseatson':true,'isselect':sellie[0].i==index ? sellie[0].k==j ? true:'':''}"  v-if="i==0"> 
                  第 {{ index | fil(j,list) }}列 
                    </div>
                <div class="seat" >
                    {{seat.name == dingweiname ? seat.dw==dingweidw ? seat.id == dingweiid ? seat.name:'':'':''}}
                </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import PinchZoom from 'vue-pinch-zoom';
import html2canvas from 'html2canvas';

export default {
    data(){
        return{
            list:[],
            checkname:'',
            hang:'',
            lie:'',
            meetingname:'',
            meeting:'',
            resvalue:[],
            dingweiname:'',
            dingweidw:'',
            dingweiid:'',
            showresdiv:false,
            url:'',
            currentlie:'',
            currenthang:'',
            sellie:[{i:0,k:0}],
            sellienum:0
        }
    },
    components:{
        'pinch-zoom': PinchZoom
    },
    created() {
    let meetingname = this.$route.query.meetingname;
    let meeting  = this.$route.query.meeting
    this.meeting = meeting
    this.meetingname = meetingname
    // console.log(meetingname); // 输出: 123
  },
    // 自动计算当前的列数，并且显示在列的上方
    filters:{
    fil(value1,value2,value3){
      let mylie=0
      for(let i=0;i<value1;i++){
        mylie = mylie + value3[i][0].length
      }
      mylie = mylie + value2 +1
      return mylie
    }
  },
    mounted(){
        this.gettest()
    },
    computed:{
    },
    watch:{
        checkname(newvalue,oldvalue){
            if(newvalue==''){
                this.lie=''
                this.hang=''
            }
        },
        sellienum(newvalue,oldvalue){
            for(let i=0;i<this.list.length;i++){
                let arr1=this.list[i]
                for(let j=0;j<arr1.length;j++){
                    let obj=arr1[j]
                    for(let k=0;k<obj.length;k++){
                        if(obj[k].name==this.dingweiname&&obj[k].dw==this.dingweidw&&obj[k].id==this.dingweiid){
                            this.sellie[0].i=i
                            this.sellie[0].k=k
                        }
                    }
                }
            }
        }
    },
    methods:{
      async  dingwei(value){
        // console.log(value,'--==--')
        const loading = this.$loading({
          lock: true,
          text: '查找中',
          spinner: 'el-icon-loading',
          background: 'rgba(245, 245, 245,0.7)'
        });
            
            this.dingweiname= value.name == '' ? this.dingweiname:value.name
            this.dingweiid= value.id == '' ? this.dingweiid:value.id
            this.dingweidw= value.dw == '' ? this.dingweidw:value.dw
            this.currentlie = value.lie
            this.currenthang=value.hang
            this.sellienum = this.sellienum+1
            setTimeout(async ()=>{
            const that = this
            const element = that.$refs.imageDom;
          // 将节点内容转换为 canvas
         await html2canvas(element,{
            scale:2,
            useCORS: true, // 支持图片跨域
            // backgroundColor: null, // 设置背景为透明色
            height: element.scrollHeight, // 设置 canvas 的高度为元素的实际高度
            windowHeight: element.scrollHeight, // 设置窗口的高度为元素的实际高度
            width:element.scrollWidth,
            windowWidth:element.scrollWidth,
          }).then((canvas) => {
            // 将 canvas 转换为图片
            const imgUrl = canvas.toDataURL('image/png');
            that.url=imgUrl
          });
          loading.close();
            },200)
      
        },
     async gettest(){
        const {data:res} = await this.$http.get('users/getseat',{
            params:{
                "meeting":this.meeting,
                "name":this.meetingname
            }
        })
        if(res.flag==true){
          this.list = JSON.parse(res.data[0].seat)
        }else{
          console.log(res)
        }
        },
        check(){
            this.resvalue=[]
            this.sellienum=0
            this.hang='',this.lie
            let targname = this.checkname
            // console.log(this.list)
            ,forlength=this.list==null ? 0:this.list.length
            if(targname !==''&&forlength !=0){
                let result = this.list.flat(2).filter(item => item.name.includes(targname)).map(item => ({name: item.name, hang: item.hang, lie: item.lie,dw:item.dw,id:item.id}));
                if(result.length==0){
                    this.$message.error("暂时没有你的位置哦，请确认姓名输入正确")
                    this.showresdiv = false
                    this.url=''
                }else{
                     this.resvalue = result
                     this.showresdiv = true
                     if(result.length==1){
                        this.dingweiname = this.resvalue[0].name
                        this.dingweidw = this.resvalue[0].dw
                        this.dingweiid = this.resvalue[0].id
                        this.currenthang = this.resvalue[0].hang
                        this.currentlie = this.resvalue[0].lie
                        this.dingwei({name:this.dingweiname,dw:this.dingweidw,id:this.dingweiid,lie:this.currentlie,hang:this.currenthang})
                        this.sellienum = this.sellienum+1
                     }
                }
        }else{
            this.showresdiv = false
            this.url=''
            this.$message.error("暂无此人的信息")
        }
        }
    }
}
</script>

<style scoped>
.pai{
  display: block;
  padding: 5px;
}
.span{
    font-size: 18px;
    margin-top: 10px;
}
.meetingname{
    display: flex;
    /* border: 1px solid red; */
    padding: 5px;
    /* flex-direction: column; */
}
.search{
    width: 80%;
    margin-top: 15px;
    margin-bottom: 10px;
}
.checkseat{
    height: 100%;
    width: 100%;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.seat-chart {
  display:flex;
  width: fit-content;
  height:fit-content;
  flex-direction: column;
  margin-top:25px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
 
}
.seat-area{
  /* height: fit-content; */
  display:flex;
  flex-direction : column;
  margin-top : 20px;
  margin-right: 20px;
}
.seat-row{
  display:flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
}
.seat{
  font-size: 14px;
  font-weight: bold;
  width : 60px;
  height :30px;
  border :1px solid #ccc;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
}
.hang{
  min-width: 100px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.resdiv{
    max-width:100%;
    overflow: auto;
    box-sizing: border-box;
    min-height:140px;
    padding: 0px 10px;
    box-sizing: border-box;
}
.resdiv2{
    width: fit-content;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}
.namediv{
border-radius: 5px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;
box-sizing: border-box;
height: 100%;
box-sizing: border-box;
border: 1px solid #f3f4f6;
}
.myseat{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    /* font-weight: bold; */
    font-family: "微软雅黑";
}
.pinch-zoom-wrapper{
    background-color:#ffffff !important;
}
.bigseat{
  height: 100%;
  width: 100%;
  overflow: auto;
}
.jiangtai{
  height: 40px;
  width:95%;
  border:1px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

}
.seat-chart2{
  display:flex;
  width: fit-content;
  height:fit-content;
  margin-top:15px;
  flex-wrap: nowrap;
}
.smallseat{
  position: relative;
}
.smallseatson{
  position: absolute;
  top: -25px;
}
.showdiv{
    box-sizing: border-box;
    padding-top: 15px;
    max-height: 100%;
    width: 100%;
    overflow: hidden;
}
.show1{
    width: 100%;
    height: 100%;
    overflow: auto;
}
.show2{
    height: 100%;
    width: 100%;
    overflow: auto;
}
@keyframes flash {
  0%, 50%, 100% { border-color: rgb(252, 157, 154); }
  25%, 75% { border-color: rgb(131, 175, 155); }
}
.flash{
  animation: flash 1.5s infinite;
}
.isselect{
    color: red;
    
}
.addtag{
    overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16em; /* 根据你的具体需求调整这个值 */
}
/* .zoomdiv{
    width: 100%;
  height: 100%;
} */
</style>