<template>
  <div class="ppthome">
    <el-dialog
        title="新增会议"
        :visible.sync="dialogVisible"
        width="30%" >
        <div class="diainput">
            <el-input v-model="meeting">
                <template slot="prepend">会议</template>
            </el-input>
            <el-input v-model="addres">
                <template slot="prepend">地点</template>
            </el-input>
            <div>
                <label>会议时间:</label>&nbsp;
                <el-date-picker
                    v-model="datetime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="tosubmit">确 定</el-button>
        </span>
        </el-dialog>
        <div class="list">
            <div class="usemethods">
                <el-button size="small" type="danger" plain @click="exit">退出</el-button> &nbsp;&nbsp;&nbsp;
                <el-link type="success" href="https://ra1pgvsvat.feishu.cn/docx/Zf0NdgUDgoPefXxwV4scFFzsn8f" target="_blank">使用方法</el-link>
               </div>
            <el-input v-model="filterate">
                <template slot="prepend">输入会议名称</template>
            </el-input>
            &nbsp;
            <el-button type="primary" @click="addmeeting">添加</el-button>
        </div>
        <div class="contain">
           <div class="meeting">
            <el-table
                :data="matevalue"
                height="100%"
                style="width: 100%"
                :header-cell-style="{ textAlign: 'center',fontWeight:'bold',color:'black'}"
               :cell-style="{ textAlign: 'center' }"
                >
                <el-table-column type="index" label="序号"  width="80">
                </el-table-column>
                <el-table-column
                sortable
                prop="meeting"
                label="会议">
                </el-table-column>
                <el-table-column
                prop="addres"
                label="地点">
                </el-table-column>
                <el-table-column sortable label="开始时间" prop="starttime">
                </el-table-column>
                <el-table-column sortable label="结束时间" prop="endtime">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button icon="iconfont icon-guanli " @click="jump({meeting:scope.row.meeting,id:scope.row.id})" type="primary"  size="small" plain>&nbsp;管理</el-button>
                        <el-button icon="iconfont icon-shanchu" @click="del(scope.row.id)" type="primary"  size="small" plain>&nbsp;删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
           </div>
        </div>
    </div>

</template>
<script>
import store from '@/store'
import { test } from '@/libs/utlis'
import {debounce} from '../../libs/utlis'
export default {
    data(){
        return{
            list:[],
            dialogVisible:false,
            meeting:'',
            addres:'',
            filterate:'',
            matevalue:[],
            datetime:[]
        }
    },
    mounted(){
        this.getinfo()
        // let wid = window.innerWidth
        // console.log(wid)
    },
    watch:{
        datetime(value){
            console.log(value)
        },
        filterate:debounce(function(){
            // console.log(';;pp[[]]')
            this.mate()
        },700)
    },
    methods:{
        exit(){
            this.$router.push('/')
            sessionStorage.clear()
        },
        async del(value){
            const confirmText = ['1.此操作将会删除该会议',  
                '2.系统将删除该会议所有数据',
                '请确认需要执行吗？'
                ] 
                const newDatas = []
                const h = this.$createElement
                for (const i in confirmText) {
                newDatas.push(h('p', null, confirmText[i]))
                }
            const confirm = await this.$confirm('提示',{
                type:'warning',
                title:'提示',
                message:h('div',null,newDatas),
                confirmButtonClass:'确定',
                cancelButtonClass:'取消'
            }).catch(e=>{})
            if(confirm=='confirm'){
                const {data:res} = await this.$http.delete('users/delrecordid',{
                    params:{
                        listid:value
                    }
                })
                if(res.flag==true){
                    this.$message.success(res.msg)
                  await  this.getinfo()
                //   await  this.delother(value)
                }else{
                }
            }
        },
      async delother(value){
            const {data:res} = await this.$http.delete('users/delallvaluebyid',{
                    params:{
                        meetingid:value
                    }
                })
                if(res.flag==true){
                    this.$message.success(res.msg)
                }else{
                    
                }
                // console.log(value)
        },
        mate(){
        let result = this.list.filter(item=>item.meeting.includes(this.filterate)).map(item=>({meeting:item.meeting,addres:item.addres,id:item.id,starttime:item.starttime,endtime:item.endtime}))
          this.matevalue = result
        },
        addmeeting(){
            this.dialogVisible = true
        },
        tonewperson(value){
            store.commit('changemeeting',value)
            this.$router.push('/meetingperson')
        },
        jump(value){
            store.commit('changemeeting',value.meeting)
            store.commit('changemeetingid',value.id)
            // console.log(value)
            // changemeetingid
            this.$router.push('/link')
        },
        async getinfo(){
            const {data:res} = await this.$http.get('users/getpptmeetinglist')
            // console.log(res,'][[]]')
            if(res.flag==true){
                this.list = res.data
                this.mate()
            }
            
        },
        addbriefintroduction(value){
            store.commit('changemeeting',value)
            this.$router.push('/addppt')
            // addppt
            // let testvalue = test()
            // console.log(testvalue)
        },
       async tosubmit(){
        console.log('tosubmit')
        const confirm = await this.$confirm('是否添加会议'+this.meeting,
        {   title:'提示',
            type:'warning',
            confirmButtonText:'确定',
            cancelButtonText:'取消'
        }
            ).catch(e=>{
                
            })
            if(confirm == 'confirm'){
                const {data:res} = await this.$http.post('users/toaddmeeting',{
                'meeting':this.meeting,
                'addres':this.addres,
                'stime':this.datetime[0],
                'etime':this.datetime[1]
            })
            console.log(res)
            if(res.flag==true){
                this.$message.success(res.msg)
                this.getinfo()
                this.dialogVisible=false
            }else{
                this.$message.error(res.msg)
            }
            }
        }
    }
}
</script>

<style scoped>
.ppthome{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.list{
    height: 60px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contain{
    border-top: 1px solid #ccc;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.meeting{
    box-sizing: border-box;
    width: 60%;
    height: 100%;

}
.linkdiv{
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #4389f9;
}
.linkdiv li{
    cursor: pointer;
}
.diainput .el-input{
margin-bottom: 15px;
}

.usemethods{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
}
@media screen and (max-width:1536px) {
    .meeting{
        width:65%;
    }
}
</style>