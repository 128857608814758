<template>
  <div class="read">
      <div class="sleect">
        <el-row style="width: 100%;">
       <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"><div class="grid-content bg-purple">解析pptx或docx文件</div></el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12"><div class="grid-content ">
        <el-upload
            ref="upload"
					  action="http://resume.kunbocs.cn/readfile.php"
					  :on-success="handleSuccess"
					  :before-upload="beforeUploadAndSubmit">
					  <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
					</el-upload>
      </div>
    </el-col>

        </el-row>
      </div>
      <div class="flex contain">
    <div class="row" style="width: 100%;">
      <el-input 
      type="textarea"
      rows="15"
      v-model="value">
    </el-input>
    <!-- <div class="pic">
      <el-image :src="prepath+src">
      <div slot="placeholder" class="image-slot">
        加载中<span class="dot">...</span>
      </div>
    </el-image>
    <el-input v-model="src" placeholder="请复制图片地址到此处">
    <template slot="append">
      <el-button size="small" type="primary" @click="uploadImage">上传图片</el-button>
    </template>
    </el-input>
    
    </div>
{{ imageUrl }} -->
    </div>
      </div>
    </div>
</template>

<script>
 import COS from 'cos-js-sdk-v5' // 引入 cos-js-sdk-v5 包
export default {
  props:["name"],
    data(){
        return{
          value:'',
          src: '',
          prepath:'https://resume.kunbocs.cn/',
          imageUrl:'',
          cos:''
        }
    },
    components:{

    },
    mounted(){
    // 加载 mammoth.js
    var script = document.createElement('script');
    script.src = "https://cdn.bootcss.com/mammoth/1.4.8/mammoth.browser.js";
    document.head.appendChild(script);

    },
    methods:{
    readFileInputEventAsArrayBuffer(event) {
      var file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (loadEvent) => {
        var arrayBuffer = loadEvent.target.result;
        mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
          .then(this.displayResult)
          .done();
      };
      reader.readAsArrayBuffer(file);
    },
    clearBoth(){
      this.$refs.upload.clearFiles();
    this.value=''
    },
      handleSuccess(response, file, fileList) {
									// this.value=JSON.parse(response)
                  // this.$refs.upload.clearFiles();
									this.value =  response
							      // console.log(response);
							    },
							    beforeUploadAndSubmit(file) {
                    this.value=''
									// console.log(file.name)
									this.filename = file.name
                  const isdocx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
							    const isPPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
							      if (file.type == isdocx || file.type == isPPTX) {
							      return true
							      }else{
                      this.$message.error('上传文件只能是 PPTX 或者 DOCX格式!');
                    }
							    
							    },

    }
}
</script>

<style  scoped>
    .read{
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 0;
    }
    .flex{
        flex: 1;
    }
    .contain{
        display: flex;
        overflow: auto;
    }
    .sleect{
      display: flex;
        width: 100%;
    }
    .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    line-height: 35px;
  }
  .el-image{
    height: 150px;
    width: 150px;
  }
  .pic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
</style>

