<template>
  <div>
    <el-button @click="goback" type="warning" icon="el-icon-d-arrow-left" plain circle></el-button>
  </div>
</template>

<script>
export default {
data(){
    return{

    }
},
methods:{
    goback(){
        this.$router.go(-1)
    }
}
}
</script>

<style>

</style>