<template>
  <div class="fillinppt">
        <div class="cinputdiv">
            <el-input v-model="meeting" disabled>
              <template slot="prepend">会议</template>
            </el-input>
            <el-input v-model="name" disabled>
              <template slot="prepend">姓名</template>
            </el-input>
            <el-input v-model="dw" >
              <template slot="prepend">单位</template>
            </el-input>
        </div>
        <div class="editordiv">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 10, maxRows: 10}"
                  placeholder="请输您的介绍"
                  v-model="content">
                </el-input>

        </div>
        <div>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :http-request="upload"
          >
          
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon" ></i>
          </el-upload>
          上传个人照
        </div>
        <div style="margin-top: 10px;">
            <el-button type="primary" @click="tosubmit">确认</el-button>
        </div>
  </div>
</template>

<script>
import COS from 'cos-js-sdk-v5' // 引入 cos-js-sdk-v5 包

export default {
data(){
    return{
        loading:false,
        globalid:'',
          id:'',
          imageUrl:'',
          content:'',
          name:'',
          dw:'',
          meeting:'',
          cos: null, // 用于存储 COS 实例
    }
},
mounted(){
    this.id = this.$route.query.id
    this.getinfo(this.id)
},
components: {
    
  },
methods:{
     async getglobalinfo(value){
      if(value == null){
        const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getinfobyGlobalid',{
          params:{
            globalid:this.globalid
          }
        })
        if(res.flag==true){
          this.name = res.data[0].name
          this.dw = res.data[0].dw
          this.content = res.data[0].text
          this.imageUrl = res.data[0].img
        }
      }else{
      }
     },
  async  getinfo(value){
    const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getinfo',{
      params:{
        id:value
      }
    })
    // console.log(res.data)
    if(res.flag==true){
      this.globalid = res.data[0].globalid
      this.name = res.data[0].name
      this.dw = res.data[0].dw
      this.meeting = res.data[0].meeting
      this.content = res.data[0].text
      this.imageUrl = res.data[0].img
      this.getglobalinfo(res.data[0].text)
    }else{

    }
  },
    handleAvatarSuccess(res, file) {
      // console.log(res)
        this.imageUrl = URL.createObjectURL(file.raw);
        this.$notify({
                    title: '获取图片成功',
                    message: res.msg,
                    type: 'success'
                    });
      },
      beforeAvatarUpload(file) {
        const isImage = /\.(jpeg|jpg|png|gif)$/i.test(file.name);
        const isLt2M = file.size / 1024 / 1024 < 8;//  这一行的作用是判断上传的图片文件的大小是否小于 2MB。file.size 是文件的字节数，除以 1024 得到 KB，再除以 1024 得到 MB。

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M || !isImage) {
          this.$message.error('上传头像图片大小不能超过 8MB!且上传类型必须为个人头像!');
        }
        // if(!isImage){
        //   this.$message.error('上传类型必须为个人头像!');

        // }
        return  isLt2M && isImage;
      },
   async tosubmit(){
    const confirm = await this.$confirm('是否确认提交？',{
      type:'warning',
      title:'提示',
      confirmButtonClass:'确定',
      cancelButtonClass:'取消'
    }).catch(e=>{
      // console.log(e)
    })
    if(confirm == 'confirm'){
      
    try {
        const {data:res} = await this.$http.put('https://seats.kunbocs.cn/updateoneselfinformation',{
          id:this.id,
          dw:this.dw,
          content:this.content,
          img:this.imageUrl
        })
        console.log(res)
      if(res.flag==true){
        this.$notify({
                    title: '提交成功',
                    message: res.msg,
                    type: 'success'
                    });
      setTimeout(() => {
      // 在这里执行你想要延迟两秒执行的操作
      this.$router.push('/suc')
      console.log("延迟两秒执行的操作");
    }, 1000); // 2000毫秒等于2秒
       
      }else{
        this.$notify({
                    title: '失败',
                    message: '请联系管理员',
                    type: 'error'
                    });
          }
    } catch (error) {
      console.log(error)
    }
    }
    },
    async  upload(file){
      let houzui=file.file.type.split('/')

        const {data:res} = await this.$http.get('https://seats.kunbocs.cn/getTempKeys')
          this.cos = new COS({
            getAuthorization: (options, callback) => {
              callback({
                TmpSecretId: res.credentials.tmpSecretId,
                TmpSecretKey: res.credentials.tmpSecretKey,
                XCosSecurityToken: res.credentials.sessionToken,
                ExpiredTime: res.expiredTime,
              });
            },
          });
          // let key = "/art/" + file.file.name;
          let key = "/speakerImage/" +this.$store.state.meeting+'/'+ this.name+'.'+houzui[1];
          this.cos.putObject({
            Bucket: "file-1257652773", // 存储桶名称
              Region: "ap-guangzhou", // 存储桶所在地域，必须字段
              Key: key, // 图片名称
              StorageClass: "STANDARD",
              Body: file.file, // 上传文件对象
              onHashProgress: (progressData) => {
                console.log("校验中", JSON.stringify(progressData));
              },
              onProgress: (progressData) => {
                this.loading = this.$loading({
                lock: true,
                text: '上传中请稍等...',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
              });
                const percent = parseInt(progressData.percent * 10000) / 100;
                const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                console.log("进度：" + percent + "%; 速度：" + speed + "Mb/s;");
              },
          },(err,data)=>{
            if (data && data.statusCode == 200) {
                // 如果上传成功，拼接图片链接地址，并赋值给 imageUrl
                let fileUrl ='https://'+data.Location+'?imageMogr2/scrop/420x540'
                this.imageUrl = fileUrl;
                this.loading.close();
              } else {
                console.log(err,'1');
              }
          }).catch((err) => {
          console.log(err,'2');
          // 这里可以使用 element-ui 的 Message 组件或者其他方式提示用户获取密钥失败
        });
       
        },
}
}
</script>

<style scoped>
.fillinppt{
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    height: 100%;
    /* background-color: red; */
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around; */
}
.cinputdiv{
    height: fit-content;
    width: 100%;
    /* height:90px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* border: 1px solid red; */
}
.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    border: 1px dashed gray;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .editordiv{
      /* background-color: #409EFF; */
      width: 100%;
      height: fit-content;
      margin-bottom: 10px;
  }
    .cinputdiv .el-input{
      margin-bottom: 10px;
    }
  @media screen and (min-width: 750px) {
  .fillinppt{
    margin: auto;
    width: 20%;
    height:fit-content;
   /* padding:100px 800px; */
   border: 1px solid #ccc;
   border-radius: 10px;
  }
}
</style>