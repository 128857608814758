export function findIndexIn3DArray(array, field1, value1, field2, value2) {
    for (let i = 0; i < array.length; i++) { // 第一层
      for (let j = 0; j < array[i].length; j++) { // 第二层
        for (let k = 0; k < array[i][j].length; k++) { // 第三层
          const item = array[i][j][k];
          if (item[field1] === value1 && item[field2] === value2) {
            return { i, j, k }; // 返回包含匹配对象索引的对象
          }
        }
      }
    }
    return null; // 如果未找到匹配项，则返回null
  }