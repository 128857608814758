import Vue from "vue"
export const hasRole =  Vue.directive('hasrole', {
    inserted(el, binding) {
         // 当前按钮传递的值( v-permission="'add'" 中的值)x
                // console.log(binding,el,'asdfghjasdfgh')
                const currentTag = binding.value.action

                const effect = binding.value.effect
                // 获取存放在sessionStorage的权限
                const rightval =  window.sessionStorage.getItem('role')
                // console.log(rightval)
                const currentRight = rightval.split(',')
                // 判断是否存在对应的按钮权限
                let item = currentRight.filter((item) => {
                    return item === currentTag
                })
                //不具备权限则删除（隐藏）或者禁用该按钮 (el.parentNode 获取当前节点的父节点),根据在芫荽绑定的值去决定
                if (item.length === 0) {
                    // console.log(el)
                    if (effect === 'disabled') {
                        el.disabled = true
                        el.classList.add('is-disabled')
                    }
                    // else if(effect ==='is-prevent'){
                    //     // el.parentNode.stopPropagation()
                    //     el.parentNode.removeEventListener('click',selectSeat)

                    // } 
                    else {
                        el.parentNode.removeChild(el)
                    }
                }
                // // 当前按钮传递的值( v-permission="'add'" 中的值)
                // // console.log(binding,el,'asdfghjasdfgh')
                // const currentTag = binding.value.action

                // const effect = binding.value.effect
                // // 获取存放在sessionStorage的权限
                // const role =  window.sessionStorage.getItem('role')
                // const currentRight = currentTag.split(',')
                // console.log(currentTag)
                // // 判断是否存在对应的按钮权限
                // let item = currentRight.filter((item) => {
                //     console.log(currentRight,item,role,item === role)
                //     return item === role
                    
                // })
                // console.log(item,'我是权限的控制者')
                // //不具备权限则删除（隐藏）或者禁用该按钮 (el.parentNode 获取当前节点的父节点),根据在芫荽绑定的值去决定
                // if (item.length === 0) {
                //     if (effect === 'disabled') {
                //         el.disabled = true
                //         el.classList.add('is-disabled')
                //     } else {
                //         el.parentNode.removeChild(el)
                //     }
                // }
            }
        })

export default hasRole;