<template>
  <div class="usermanger">
    <el-dialog
    title="添加名单"
    width="30%"
    :visible.sync="dialogVisible"
    >
    <div class="adduser">
        <el-form :model="addusers" label-width="80px" :rules="addusersrules" ref="addusersref">
            <el-form-item prop="name" label="姓名">
                <el-input v-model="addusers.name"></el-input>
            </el-form-item>
            <el-form-item prop="dw" label="工作单位">
                <el-input v-model="addusers.dw"></el-input>
            </el-form-item>
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="sureadduser">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
    title="修改信息"
    width="30%"
    :visible.sync="editdialog"
    >
    <div class="adduser">
        <el-form :model="edituser" label-width="80px" :rules="addusersrules" ref="addusersrules">
            <el-form-item prop="name" label="姓名">
                <el-input v-model="edituser.name"></el-input>
            </el-form-item>
            <el-form-item prop="dw" label="工作单位">
                <el-input v-model="edituser.dw"></el-input>
            </el-form-item>
            
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="editdialog = false">取 消</el-button>
    <el-button type="primary" @click="editsure">确 定</el-button>
  </span>
    </el-dialog>
    <div class="condition">
        筛选条件:&nbsp;&nbsp;&nbsp;
        <el-select v-model="status" @change="sel" placeholder="分配状态">
            <el-option label="已分座" value="已分配"></el-option>
            <el-option label="未分座" value="未分配"></el-option>
        </el-select>
        &nbsp;&nbsp;
        <el-input placeholder="请输入名字" style="width:20rem" v-model="name">
            <template slot="prepend">请输入名字</template>
        </el-input>  
        <el-input placeholder="请输入单位" style="width:20rem" v-model="filterUnit">
            <template slot="prepend">请输入单位</template>
        </el-input>
        <div class="goback"><goback></goback></div>
    </div>
    <div class="manger">
        <el-button type="success" size="small" icon="el-icon-plus" @click="adduser">添加用户</el-button>
        <el-button type="primary" size="small" icon="el-icon-s-management" @click="tojump">座位管理</el-button>
    </div>
    <div class="list">
        <el-table
        ref="multipleTable"
        :data="filterslist"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{'text-align':'center', 'color':'black', 'background-color':'#f3f4f6'}"
        :cell-style="{textAlign:'center'}"
        height="100%"
        @selection-change="handleSelectionChange">
        <el-table-column
        prop="姓名"
        label="姓名"
        width="110">
        </el-table-column>
        <el-table-column
        prop="工作单位"
        label="工作单位"
       >
        </el-table-column>
        <el-table-column
        label="分配状态"
        >
        <template  slot-scope="scope">
            <el-tag :type="scope.row.分配状态=='已分配' ? 'success':'danger'">{{ scope.row.分配状态 }}</el-tag>
        </template>
        </el-table-column>
        <el-table-column
        label="操作"
       >
       <template  slot-scope="scope">
           <el-button @click="edit(scope.row)" type="warning"  icon="el-icon-edit" circle></el-button>
           <el-button @click="del(scope.row)" type="danger"  icon="el-icon-delete" :disabled="scope.row.分配状态=='已分配'" circle></el-button>
        </template>
        </el-table-column>
    </el-table>
    </div>
  </div>
</template>
<script>
import goback from './goback.vue'
import {findIndexIn3DArray} from "../libs/Functionsets"
export default {
    data(){
        return{
            huiyi:'',
            status:'',
            ting:'',
            list:[],
            name:'',
            filterUnit:'',
            dialogVisible:false,
            addusers:{
                name:'',
                dw:'',
                status:'未分配'
            },
            edituser:{
                name:'',
                dw:'',
            },
            users:{
                姓名:'',
                工作单位:'',
                分配状态:'',
                id:''
            },
            editdialog:false,
            editIndex:-1,
            addusersrules:{
                name:[{required:true,message:'姓名不能为空',trigger:'blur'}],
                dw:[{required:true,message:'工作单位不能为空',trigger:'blur'}]
            },
            seat:[],
            dingwei:''
        }
    },
    created(){
        this.huiyi = this.$route.query.meeting
        this.ting = this.$route.query.name
    },
    components:{
        goback
    },
    watch:{
        addusers:{
            handler(newvalue,oldvalue){
                let listIndex = this.list.length-1
                this.users.姓名=newvalue.name
                this.users.工作单位=newvalue.dw
                this.users.分配状态=newvalue.status
                this.users.id=this.list[listIndex].id+1
            },deep:true
        },
        edituser:{
            handler(newvalue,oldvalue){
                const dingwei = this.dingwei[0]
                    if (dingwei) {
                        const { i,j,k } = dingwei
                        this.seat[i][j][k].name = newvalue.name
                        this.seat[i][j][k].dw = newvalue.dw
                    }
                let index = this.editIndex
                this.list[index].姓名 = newvalue.name
                this.list[index].工作单位 = newvalue.dw
                // console.log( this.list[index].姓名)
            },deep:true
        }
    },
    computed:{
        filterslist(){
            return this.list.filter((p) => {
        // 检查姓名条件
        const matchesName = this.name ? p.姓名.includes(this.name) : true;
        // 检查单位条件
        const matchesUnit = this.filterUnit ? p.工作单位.includes(this.filterUnit) : true;
        // 检查状态条件
        const matchesStatus = this.status ? p.分配状态 === this.status : true;
        
        return matchesName && matchesUnit && matchesStatus;
      });
        }
    },
    mounted(){
        this.getseatvalue()
    },
    methods:{
        sel(value){
        },
       async getseatvalue(){
        const {data:res} =await this.$http.get('users/getseatvalue',{
            params:{
                meeting:this.huiyi,
                meetingname:this.ting
            }
        })
        // console.log(res)
        if(res.flag == true){
            this.list = JSON.parse(res.data[0].namelist)
            this.seat = JSON.parse(res.data[0].seat)
        }
        },
        handleSelectionChange(value) {
            
      },
      tojump(){
        this.$router.push({name:'home',query:{
            meeting:this.huiyi,
            name:this.ting
           }})
      },
      adduser(){
        this.dialogVisible = true
      },
    async tosubmit(){
        const params = {
            "value":JSON.stringify(this.list),
            "seat":JSON.stringify(this.seat),
            "meeting":this.huiyi,
            "mettingname":this.ting
        }
       try {
         const {data:res} = await this.$http.put('users/edtiseat',params)
         if(res.flag){
             this.$notify({
                 title: '操作成功',
                 message: '',
                 type: 'success'
             })
             this.getseatvalue()
         }
       } catch (error) {
        this.$notify({
      title: '操作失败',
      message: `发生错误: ${error.message}`,
      type: 'error'
    });
       }
      },
      sureadduser(){
        this.$refs.addusersref.validate( async v=>{
            if(!v)return
            this.list.push(this.users)
            this.dialogVisible = false
            this.$refs.addusersref.resetFields()
            await this.tosubmit()
        })
      },
      editsure(){
        this.tosubmit()
        this.editdialog=false
      },
      async del(personToRemove){
            const index = this.list.findIndex(person => 
        person.姓名 === personToRemove.姓名 && 
        person.工作单位 === personToRemove.工作单位 && 
        person.分配状态 === personToRemove.分配状态 &&
        person.id === personToRemove.id
        );
        if (index !== -1) {
        const confirm = await this.$confirm('是否删除'+ personToRemove.姓名,{
            title:'提示',
            type:'warning',
            confirmButtonText:'确定',
            cancelButtonText:'取消'
        }).catch(err=>{})
        if (confirm =='confirm'){
             this.list.splice(index, 1);
             this.tosubmit()
        }
        }
    },
      edit(personToRemove){
        this.dingwei=[]
        this.editdialog = true
        const index = this.list.findIndex(person => 
        person.姓名 === personToRemove.姓名 && 
        person.工作单位 === personToRemove.工作单位 && 
        person.分配状态 === personToRemove.分配状态
        );
        if (index !== -1) {
           this.editIndex = index
           this.edituser.name = personToRemove.姓名
           this.edituser.dw = personToRemove.工作单位
        }
        let arr = Array.isArray(this.seat) ? this.seat:[]
        let value = findIndexIn3DArray(arr,'name',this.edituser.name,'dw',this.edituser.dw)
        if(value != null){
            this.dingwei.push(findIndexIn3DArray(this.seat,'name',this.edituser.name,'dw',this.edituser.dw))
        }
      }
    }
}
</script>
<style scoped>
.usermanger{
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
}
.condition{
    position: relative;
    height: 50px;
    border-bottom: 1px solid gray;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0px 10px;

}
.manger{
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0px 10px;
    height: 60px;
    border-bottom: 1px solid gray;
}
.list{
    height: calc(100% - 120px);
    overflow: auto;
}
.goback{
    position: absolute;
    right: 15px;
}
.adduser{
    height:fit-content;
    width: 100%;
}
.border{
    border: 1px solid red;
}
</style>