<template>
    <div class="login">
      <div class="logindiv">
        <div class="jianjie"><p data-text="排座登录"> 排座登录 </p></div>
          <el-form ref="loginref" :model="loginvalue"  :rules="loginrules" label-width="50px">
              <el-form-item label="账号" prop="account">  
                  <el-input type="text" v-model="loginvalue.account"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="passw">
                  <el-input @keyup.enter.native="handleEnter" type="password" v-model="loginvalue.passw"></el-input>
              </el-form-item>
          </el-form>
          <el-button type="primary" @click="login">登录</el-button>
          <!-- <el-button type="success" @click="pptx">pptx</el-button> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data(){
          return{
              loginvalue:{
                  account:'kbcs',
                  passw:'kunbo6688'
              },
              loginrules:{
                  account: [{required:true,message:'请输入账号',trigger:'blur'}],
                  passw: [{required:true,message:'请输入密码',trigger:'blur'}]
              }
          }
      },
      created() {
  },
      mounted(){
          sessionStorage.clear()
      },
      methods:{
          login(){
          this.$refs.loginref.validate(async v=>{
          if(!v)return
          const {data:res} = await this.$http.post('https://seats.kunbocs.cn/login',{
                      "loginfo":this.loginvalue
                  })
               
                  if(res.flag==true){
                      sessionStorage.setItem('token',res.token)
                          this.$router.push('/meetingface')
                  }else{
                      this.$message.error(res.message )
                  }
                  })
          
          },
          handleEnter(e){
             
                  this.login()
          },
          pptx(){
              
          }
      }
  }
  </script>
  
  <style scoped>.login{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .logindiv{
        padding: 10px;
        width: 400px;
        height: 250px;
        display: flex;
        /* align-items: c; */
        justify-content: center;
        flex-direction: column;
        border: 1px solid #f3f4f6;
    }
    .jianjie{
        width: 100%;
        height: 70px;
        background-image: radial-gradient(ellipse farthest-side at 40% 0%, #455A64 0%, #263238 60%, #1a2327 100%);
        display: flex;
        margin-bottom: 15px;
        border-radius: 8px;
    
    }
    p {
        position: relative;
        margin: auto;
        font-size: 1.5rem;
        word-spacing: 0.2em;
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        color: transparent;
        background-color: #E8A95B;
        background-clip: text;
    }
    
    p::after {
        content: attr(data-text);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        color: white;
        background-image: linear-gradient(120deg, transparent 0%, transparent 6rem, white 11rem, transparent 11.15rem, transparent 15rem, rgba(255, 255, 255, 0.3) 20rem, transparent 25rem, transparent 27rem, rgba(255, 255, 255, 0.6) 32rem, white 33rem, rgba(255, 255, 255, 0.3) 33.15rem, transparent 38rem, transparent 40rem, rgba(255, 255, 255, 0.3) 45rem, transparent 50rem, transparent 100%);
        background-clip: text;
        background-size: 150% 100%;
        background-repeat: no-repeat;
        animation: shine 5s infinite linear;
    }
    
    
    @keyframes shine {
        0% {
            background-position: 50% 0;
        }
        100% {
            background-position: -190% 0;
        }
    }
  </style>