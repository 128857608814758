<template>
  
  <div class="jump">
    <el-dialog title="创建会议" :visible.sync="dialogVisible" width="30%">
      <div class="createmeeting">
        <el-input v-model="meeting">
          <template slot="prepend">会议名称</template>
        </el-input>
        <el-input v-model="addres">
          <template slot="prepend">举办酒店</template>
        </el-input>
        <el-date-picker
          style="width: 100%"
          v-model="seldate"
          type="daterange"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createConference">确 定</el-button>
      </span>
    </el-dialog>
    <el-button type="success" @click="conference">创建会议</el-button>
    <el-button @click="tojump('/home')" type="success">编排座位</el-button>
    <el-button @click="tojump('/qrcodejs2')" type="primary"
      >制作二维码</el-button
    >
    <el-button @click="exportExcel" type="primary">下载导入模版</el-button>
  </div>
</template>

<script>
import excel from "@/libs/excel";
export default {
  data() {
    return {
      dialogVisible: false,
      meeting: "",
      addres: "",
      seldate: [],
      exportList: [{ name: "张三", dw: "广州市坤博会务服务" }],
    };
  },

  methods: {
    tojump(value) {
      this.$router.push(value);
    },
    conference() {
      this.dialogVisible = true;
    },
    async checkmeeting() {
      const { data: res } = await this.$http.get("users/checkmeet", {
        params: {
          name: this.meeting,
        },
      });
      return res.flag;
    },
    async createConference() {
      let aa = await this.checkmeeting();
      if (this.addres != "" && this.seldate != "" && this.meeting != "") {
        if (!aa) {
          const { data: res } = await this.$http.post(
            "users/createConference",
            {
              name: this.meeting,
              addres: this.addres,
              starttime: this.seldate[0],
              finishtime: this.seldate[1],
              state: "正常",
            }
          );
          if (res.flag == true) {
            this.dialogVisible = false;
            this.addres = this.meeting = "";
            this.seldate = [];
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        } else {
          this.$message.error("会议已存在");
        }
      } else {
        switch (true) {
          case this.meeting == "":
            this.$message.error("会议名不允许为空");
            break;
          case this.addres == "":
            this.$message.error("地点不允许为空");
            break;
          default:
            this.$message.error("时间不允许为空");
            break;
        }
      }
    },
    // 页面上有个按钮 点击调用exportExcel
    exportExcel() {
      const params = {
        title: ["姓名", "工作单位"],
        key: ["name", "dw"],
        data: this.exportList, // 数据源
        autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
        filename: "导入名单模版",
      };
      excel.exportArrayToExcel(params);
    },
  },
};
</script>

<style scoped>
.jump {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createmeeting .el-input {
  margin-bottom: 15px;
}
</style>