import { render, staticRenderFns } from "./PPThomepage.vue?vue&type=template&id=cabe0d90&scoped=true&"
import script from "./PPThomepage.vue?vue&type=script&lang=js&"
export * from "./PPThomepage.vue?vue&type=script&lang=js&"
import style0 from "./PPThomepage.vue?vue&type=style&index=0&id=cabe0d90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cabe0d90",
  null
  
)

export default component.exports